import React, { useState } from "react";
import { Form } from "react-bootstrap";
import styles from "../styles/onBoardEmployer.module.css";

const WorkExperience = (props) => {
  console.log(props.experience);
  const handleChange = (e, index) => {
    const { name, value, checked } = e.target;
    const newExperience = [...props.experience];
    if (name === "present") {
      newExperience[index][name] = checked;
      newExperience[index]["endDate"] = "";
    } else {
      newExperience[index][name] = value;
    }
    props.setExperience(newExperience);
  };
  const addExperience = () => {
    if (!validate()) {
      props.setExperience([
        ...props.experience,
        { company: "", startDate: "", endDate: "", present: false },
      ]);
    } else {
      console.log(error);
    }
  };
  const [error, setError] = useState({
    company: "",
    startDate: "",
    endDate: "",
  });

  const validate = () => {
    let index = props.experience.length - 1;
    let status = false;
    console.log(
      `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`
    );
    let err = {};
    if (props.experience[index]?.company.trim() === "") {
      status = true;
      err.company = "Company name can't be empty";
    }
    if (props.experience[index]?.startDate === "") {
      status = true;
      err.startDate = "start date can't be empty";
    }
    if (
      props.experience[index]?.startDate > props.experience[index]?.endDate &&
      !props.experience[index]?.present
    ) {
      status = true;
      err.endDate = "Start date must be before end date";
    }
    if (props.experience[index]?.startDate >= new Date()) {
      status = true;
      err.startDate = "invalid date";
    }
    setError({ ...err });
    return status;
  };

  const removeExperience = (index) => {
    const newExperience = [...props.experience];
    newExperience.splice(index, 1);
    props.setExperience(newExperience);
  };

  return (
    <div>
      {props.experience?.map((exp, index) => (
        <div key={index} className="d-flex flex-wrap justify-content-between">
          <div>
            <Form.Label className={styles["candidate-labels"]}>
              Company name
            </Form.Label>
            <Form.Control
              type="text"
              name="company"
              className={styles["candidate-forms"]}
              placeholder="company name"
              value={exp.company}
              onChange={(e) => handleChange(e, index)}
            />
            {index === props.experience.length - 1 && error.company ? (
              <p style={{ color: "red" }}>{error.company} </p>
            ) : null}
          </div>
          <div>
            <Form.Label className={styles["candidate-labels"]}>
              Start Date
            </Form.Label>
            <Form.Control
              type="date"
              id="datePickerId"
              name="startDate"
              min="1980-01-01"
              max={`${new Date().getFullYear()}-${
                new Date().getMonth() > 9
                  ? new Date().getMonth()
                  : "0" + new Date().getMonth().toString()
              }-${new Date().getDate()}`}
              value={exp.startDate}
              onChange={(e) => handleChange(e, index)}
            />
            {index === props.experience.length - 1 && error.startDate ? (
              <p style={{ color: "red" }}>{error.startDate} </p>
            ) : null}
          </div>
          <div>
            <Form.Label className={styles["candidate-labels"]}>
              End Date
            </Form.Label>
            <Form.Control
              type="date"
              name="endDate"
              value={exp.endDate}
              onChange={(e) => handleChange(e, index)}
              disabled={exp.present}
              min="1980-01-01"
              max={`${new Date().getFullYear()}-${
                new Date().getMonth() > 9
                  ? new Date().getMonth()
                  : "0" + new Date().getMonth().toString()
              }-${new Date().getDate()}`}
            />
            {index === props.experience.length - 1 && error.endDate ? (
              <p style={{ color: "red" }}>{error.endDate} </p>
            ) : null}
          </div>
          <div>
            <label className={`${styles["candidate-labels"]} mx-2`}>
              Current
            </label>
            <input
              type="checkbox"
              name="present"
              checked={exp.present}
              onChange={(e) => handleChange(e, index)}
            />
            {index !== 0 && (
              <button
                className={styles["remove-btn"]}
                onClick={(e) => {
                  e.preventDefault();
                  removeExperience(index);
                }}
              >
                x
              </button>
            )}
          </div>
        </div>
      ))}
      <button
        type="button"
        className={styles["show-btn"]}
        onClick={addExperience}
      >
        Add Experience
      </button>
    </div>
  );
};

export default WorkExperience;
