import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Form, Card } from "react-bootstrap";
import { BiSearch, BiEditAlt } from "react-icons/bi";
import Loader from "../components/loader";
import { Link, useNavigate } from "react-router-dom";
import { FaShareSquare } from "react-icons/fa";
import styles from "../styles/manageEmployers.module.css";
import { BsDownload } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineDelete } from "react-icons/md";
import { useParams } from "react-router-dom";
import JobDescription from "../components/jobDescription";
import { ColorRing, TailSpin } from "react-loader-spinner";
import CandidateRating from "../components/candidateRating";
import { postCandidateComment } from "../api/jobs";
import CommentModal from "../components/comments";
import Image from "../components/User/image";
import Paginate from "../components/Pagination/pagination";

function JobCandidates(props) {
  const { jid } = useParams();
  const [candidates, setCandidates] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [job, setJob] = useState({});
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1);
  const [slicedList, setSlicedList] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [total, setTotal] = useState(1);
  const [sort, setSort] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showLoader, setShowLoader] = useState(true);
  const [loaderArray, setLoaderArray] = useState([]);
  const token = localStorage.getItem("SavedToken");
  const [selectedRows, setSelectedRows] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const recruiter = props.recruiter ? props.recruiter : {};
  const availableStatuses = [
    "Applied",
    "Recruiter Shortlisted",
    "Company Shortlisted",
    "Schedule Interview",
    "Interview Scheduled",
    "Selected for Hiring",
    "Hired",
    "Rejected",
  ];
  const handleRowSelection = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setAllSelected(false);
      setSelectedRows([]);
    } else {
      setAllSelected(true);
      setSelectedRows(candidates.map((row) => row._id));
    }
  };

  const handleEdit = () => {
    navigate(`/editJob/${selectedRows[0]}`);
  };

  const getCandidateScore = async (index, id, resumeText) => {
    console.log(loaderArray);
    let arr = [...loaderArray];
    arr[index] = true;
    setLoaderArray(arr);
    try {
      const res = await axios({
        method: "patch",
        url: `${process.env.REACT_APP_SERVER_URL}/getAIScore`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        data: {
          id,
          resumeText,
          jd: job.description,
        },
      });
      console.log(res.status);
      if (res.status === 200) {
        toast.success("Candidate Reviewed Successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setRefresh(!refresh);
      }
    } catch (error) {
      toast.error("Some Error Occured Pllease Retry!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setRefresh(!refresh);
    }
    arr[index] = false;
    setLoaderArray(arr);
  };

  const getJobDetails = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getJob/${jid}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      setJob(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = (e) => {
    setPage(1);
    const query = e.target.value;
    console.log(query);
    var updatedList = [...candidates];
    updatedList = updatedList.filter(
      (item) =>
        item.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.status?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.profile?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.address?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.recruiter?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.candidateId?.toString().indexOf(query) !== -1 ||
        item.score?.toString().indexOf(query) !== -1
    );
    setFilterList(updatedList);
    setTotal(updatedList.length);
    console.log(updatedList);
  };

  const handleSort = (field) => {
    let updatedList = [...filterList];
    updatedList.sort((a, b) => {
      let fa = a[field]?.toLowerCase(),
        fb = b[field]?.toLowerCase();
      if (fa < fb) {
        return -1 * sort;
      }
      if (fa > fb) {
        return 1 * sort;
      }
      return 0;
    });
    setFilterList(updatedList);
  };

  const handleSlice = (page, limit, total) => {
    setTotalPages(Math.ceil(total / limit));
    setSlicedList(
      filterList.slice((page - 1) * limit, Math.min(page * limit, total))
    );
  };

  useEffect(
    () => handleSlice(page, limit, total),
    [page, limit, total, filterList]
  );

  const getCandidates = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getCandidatesbyJobId/${jid}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      setCandidates(res.data);
      let cities = [];
      res.data.map((candidate) => {
        cities.push(candidate.city);
        candidate.isLoading = false;
      });
      setAvailableCities(Array.from(new Set(cities)));
      console.log(availableCities);
      setTotal(res.data.length);
      setFilterList(res.data);
      setLoaderArray(new Array(res.data.length).fill(false));
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      console.log(err);
    }
  };

  const handleCommentSubmit = async (candidateId, content) => {
    try {
      await postCandidateComment(candidateId, content);
      // Update the selected job's comments array with the new comment
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCandidate = async (selected) => {
    try {
      console.log(selected);
      const res = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/deleteCandidates`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: token,
          },
          data: {
            candidateIds: selected,
          },
        }
      );
      console.log(res);
      toast.success("Candidate Deleted Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSelectedRows([]);
      setRefresh(!refresh);
    } catch (err) {
      console.log(err);
    }
  };

  const handleShare = () => {
    navigator.clipboard
      .writeText(
        `${process.env.REACT_APP_CLIENT_URL}/onBoardCandidate/${jid}/${recruiter._id}`
      )
      .then(() => {
        toast.success("Link Copied!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleStatusChange = async (status, cid) => {
    try {
      const res = await axios({
        method: "patch",
        url: `${process.env.REACT_APP_SERVER_URL}/changeStatus/${cid}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        data: {
          status: status,
        },
        credentials: "include",
      });
      if (res.status === 201) {
        toast.success("Status Updated!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log("Updated successfully");
        setRefresh(!refresh);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this user",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteCandidate(selectedRows);
          },
        },
        {
          label: "No",
          onClick: () => console.log("aborted"),
        },
      ],
    });
  };

  useEffect(() => {
    getCandidates();
    getJobDetails();
  }, [refresh]);

  return (
    <div>
      <h2 className={styles["manage-employer-head"]}>Job Candidates</h2>
      <div className="row me-0">
        <p className={`col-md-3 ${styles["manage-employer-text"]}`}>
          <strong>Job Role:</strong> <JobDescription job={job} />
        </p>
        <p className={`col-md-3 ${styles["manage-employer-text"]}`}>
          <strong>Company:</strong> {job.companyName}
        </p>
        <p className={`col-md-3 ${styles["manage-employer-text"]}`}>
          <strong>Location:</strong> {job.employer?.country}
        </p>
      </div>
      <div className={styles["large-screen"]}>
        <div className="d-flex justify-content-between mx-3 me-4">
          <div className={styles["search-box"]}>
            <BiSearch color="#8692A6" />
            <input
              className={styles["search-text"]}
              type="text"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
          {selectedRows.length === 0 && (
            <div className="d-flex">
              <Form className="d-flex flex-wrap me-2">
                <div className="">
                  <Form.Select
                    aria-label="All Status"
                    className={styles["search-filters"]}
                    onChange={handleSearch}
                  >
                    <option value="">All Status</option>
                    {availableStatuses.map((status) => (
                      <option value={status}>{status}</option>
                    ))}
                  </Form.Select>
                </div>
                <div className="ms-1">
                  <Form.Select
                    aria-label="All Cities"
                    className={styles["search-filters"]}
                    onChange={handleSearch}
                  >
                    <option value="">All cities</option>
                    {availableCities.map((city) => (
                      <option value={city}>{city}</option>
                    ))}
                  </Form.Select>
                </div>
              </Form>
              <button className={styles["share-btn"]} onClick={handleShare}>
                <FaShareSquare />
              </button>
              <Link
                to={
                  Object.keys(recruiter).length === 0
                    ? `/onBoardCandidate/${jid}/${recruiter?._id}`
                    : `/availableCandidates/${jid}`
                }
              >
                <button className={styles["add-btn"]}>+</button>
              </Link>
            </div>
          )}
          {selectedRows.length === 1 && (
            <div className="d-flex me-4 mt-3">
              {/* <BiEditAlt size={30} color="#8692A6" onClick={handleEdit} /> */}
              <MdOutlineDelete
                size={30}
                color="#8692A6"
                onClick={handleDelete}
              />
            </div>
          )}
          {selectedRows.length > 1 && (
            <div className="me-4 mt-3">
              <MdOutlineDelete size={30} color="#8692A6" className="me-2" />
            </div>
          )}
        </div>
        <div className="mx-3 mx-sm-4">
          <div className={`${styles["table-head"]} row mx-0`}>
            <div className={`col-sm-1 ${styles.candidate_image_div}`}>
              <input
                type="checkbox"
                className="me-1"
                checked={allSelected}
                onChange={handleSelectAll}
              />
            </div>
            <div className="col-sm-1">ID</div>
            <div className="col-sm-1">Name</div>
            <div className="col-sm-1">Title</div>
            <div className="col-sm-1">Experience</div>
            <div className="col-sm-1">Recruiter</div>
            <div className="col-sm-2">Job Status</div>
            <div className="col-sm-1">AI Score</div>

            <div className="col-sm-1 justify-content-end">CV</div>
          </div>

          {showLoader ? (
            <Loader />
          ) : slicedList.length === 0 ? (
            <h1 className="text-center mt-5"> No Candidates found</h1>
          ) : (
            <div className={styles["table-body"]}>
              {slicedList.map((candidate, index) => (
                <div
                  className={`${styles["table-row"]} row`}
                  key={index}
                  onClick={() => navigate(`/candidate/${candidate?._id}`)}
                >
                  <div className={`col-sm-1 ${styles.candidate_image_div}`}>
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={selectedRows.includes(candidate?._id)}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRowSelection(candidate?._id);
                      }}
                    />
                    <Image firstName={candidate.name} size={30} />
                  </div>
                  <div
                    className="col-sm-1 flex-column align-items-start"
                    style={{ color: "black" }}
                  >
                    {candidate.candidateId}
                    {candidate.isStable && (
                      <p className={styles["stable"]}>Stable</p>
                    )}
                  </div>
                  <div className="col-sm-1">{candidate?.name}</div>

                  <div className="col-sm-1">{candidate?.profile}</div>

                  <div className="col-sm-1">{candidate.experience}</div>

                  <div className="col-sm-1">{candidate.recruiter}</div>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="col-sm-2"
                  >
                    <select
                      onChange={(e) =>
                        handleStatusChange(e.target.value, candidate._id)
                      }
                    >
                      <option value={candidate.status}>
                        {candidate.status}
                      </option>
                      {availableStatuses.map(
                        (status) =>
                          status !== candidate.status && (
                            <option value={status}> {status} </option>
                          )
                      )}
                    </select>
                  </div>
                  <div className="col-sm-1">
                    {loaderArray[index] ? (
                      <ColorRing
                        visible={true}
                        height="30"
                        width="30"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={[
                          "#2186F2",
                          "#2186F2",
                          "#2186F2",
                          "#2186F2",
                          "#2186F2",
                        ]}
                      />
                    ) : candidate.score ? (
                      <CandidateRating
                        name={candidate.name}
                        score={candidate.score}
                        review={candidate.review}
                      />
                    ) : (
                      <button
                        className={styles.aiScore}
                        onClick={(e) => {
                          e.stopPropagation();
                          console.log("clicked");
                          getCandidateScore(
                            index,
                            candidate._id,
                            candidate.resumeText
                          );
                        }}
                      >
                        Get Score
                      </button>
                    )}
                  </div>
                  <div className="col-sm-1 justify-content-between">
                    <CommentModal
                      name={candidate.name}
                      id={candidate._id}
                      comments={candidate.comments}
                      onCommentSubmit={handleCommentSubmit}
                    />
                    <a
                      href={candidate?.resume}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <BsDownload size={20} />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
          <Paginate
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            total={total}
          />
        </div>
      </div>
      <div className={styles["small-screen"]}>
        <div className="d-flex justify-content-between me-2">
          <input
            className={styles["search-text"]}
            type="text"
            placeholder="Search here"
            onChange={handleSearch}
          />
          {selectedRows.length === 0 && (
            <Link
              to={
                Object.keys(recruiter).length === 0
                  ? `/onBoardCandidate/${jid}/${recruiter?._id}`
                  : `/availableCandidates/${jid}`
              }
            >
              <button className={styles["add-btn"]}>+</button>
            </Link>
          )}
          {selectedRows.length >= 1 && (
            <div className="d-flex">
              {/* <BiEditAlt size={30} color="#8692A6" onClick={handleEdit} /> */}
              <MdOutlineDelete
                size={30}
                color="#8692A6"
                onClick={handleDelete}
              />
            </div>
          )}
          {/* {selectedRows.length >= 1 && (
            <div className=" mt-3">
              <MdOutlineDelete size={30} color="#8692A6" className="me-2" />
            </div>
          )} */}
        </div>

        <div className="mt-2">
          <Form className="d-flex justify-content-between flex-wrap me-2">
            <div className="">
              <Form.Select
                aria-label="All Cities"
                className={styles["search-filters"]}
                onChange={handleSearch}
              >
                <option value="">All cities</option>
                {availableCities.map((city) => (
                  <option value={city}>{city}</option>
                ))}
              </Form.Select>
            </div>
            <div className="">
              <Form.Select
                aria-label="All Status"
                className={styles["search-filters"]}
                onChange={handleSearch}
              >
                <option value="">All Status</option>
                {availableStatuses.map((status) => (
                  <option value={status}>{status}</option>
                ))}
              </Form.Select>
            </div>
          </Form>
        </div>

        {showLoader ? (
          <Loader />
        ) : slicedList.length === 0 ? (
          <h2 className="text-center mt-5"> No Candidates Found </h2>
        ) : (
          <div className="my-3">
            {slicedList.map((candidate, index) => (
              <Card
                key={index}
                className="shadow p-3 my-2"
                onClick={() => navigate(`/candidate/${candidate._id}`)}
              >
                <div className="d-flex justify-content-between mb-1">
                  <div className={styles["job-id"]}>
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={selectedRows.includes(candidate?._id)}
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => handleRowSelection(candidate?._id)}
                    />
                    {candidate?.candidateId}{" "}
                    {candidate.isStable && (
                      <p className={styles["stable"]}>Stable</p>
                    )}
                  </div>
                  <a
                    href={candidate?.resume}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <BsDownload size={20} />
                  </a>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>{candidate.name}</p>
                  <select
                    onClick={(e) => e.stopPropagation()}
                    className={styles["select"]}
                    onChange={(e) =>
                      handleStatusChange(e.target.value, candidate._id)
                    }
                  >
                    <option value={candidate.status}>{candidate.status}</option>
                    {availableStatuses.map(
                      (status) =>
                        status !== candidate.status && (
                          <option value={status}> {status} </option>
                        )
                    )}
                  </select>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Profile</p>
                  <p className={styles["right"]}>{candidate?.profile}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Experience</p>
                  <p className={styles["right"]}>{candidate?.experience}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Comment</p>
                  <CommentModal
                    name={candidate.name}
                    id={candidate._id}
                    comments={candidate.comments}
                    onCommentSubmit={handleCommentSubmit}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>AI Score</p>
                  {loaderArray[index] ? (
                    <ColorRing
                      visible={true}
                      height="30"
                      width="30"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={[
                        "#2186F2",
                        "#2186F2",
                        "#2186F2",
                        "#2186F2",
                        "#2186F2",
                      ]}
                    />
                  ) : candidate.score ? (
                    <CandidateRating
                      name={candidate.name}
                      score={candidate.score}
                      review={candidate.review}
                    />
                  ) : (
                    <button
                      className={styles.aiScore}
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("vlicked");
                        getCandidateScore(
                          index,
                          candidate._id,
                          candidate.resumeText
                        );
                      }}
                    >
                      Get Score
                    </button>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Onboarded By</p>
                  <p className={styles["right"]}>{candidate?.recruiter}</p>
                </div>
              </Card>
            ))}
            <Paginate
              totalPages={totalPages}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              total={total}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default JobCandidates;
