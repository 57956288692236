import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import styles from "../../styles/createUser.module.css";
import { Form, Button } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function DeleteUser() {
  const navigate = useNavigate();
  const token = localStorage.getItem("SavedToken");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const validate = () => {
    let status = false;

    if (password.trim() === "") {
      status = true;
      setError("Password can't be empty");
    }

    return status;
  };

  let name, value;

  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setPassword(value);
    console.log(password, value);
  };
  const deleteAccount = async () => {
    try {
      console.log("coming");
      const res = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/deleteUser`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          data: {
            password,
          },
        }
      );
      toast.success("Deleted Successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      localStorage.clear();
      window.location.reload();
      console.log(res);
    } catch (error) {
      //   console.log(error);
      toast.error("Password did not match", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <div>
      <Popup
        trigger={
          <button className={styles["delete-button"]}>
            Permanently Delete Account
          </button>
        }
        modal
        nested
      >
        {(close) => (
          <div className={styles["modal"]}>
            <button className={styles["close"]} onClick={close}>
              &times;
            </button>
            <div className={styles["header"]}> Permamently Delete Account</div>
            <div className={styles["popup-content"]}>
              <Form className="row justify-content-center">
                <Form.Group className="mb-3">
                  <Form.Label className={styles["label"]}>Password</Form.Label>
                  <Form.Control
                    size="sm"
                    value={password}
                    onChange={handleInputs}
                    placeholder="password"
                    name="password"
                  />
                  {error ? <p style={{ color: "red" }}>{error} </p> : null}
                </Form.Group>

                <div className="mb-0 d-flex justify-content-center">
                  <Button
                    className={styles["login-button"]}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!validate()) {
                        deleteAccount();
                        setTimeout(() => {
                          close();
                        }, 2000);
                      }
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default DeleteUser;
