import axios from "axios";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { Form, Card } from "react-bootstrap";
import Loader from "../loader";
import { BiSearch, BiEditAlt } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import styles from "../../styles/manageEmployers.module.css";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineDelete } from "react-icons/md";
import JobDescription from "../jobDescription";
import { AiOutlineClose } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import { postComment } from "../../api/jobs";
import CommentModal from "../comments";
import RejectJD from "./rejection";
import AddRecruiter from "./addRecruiter";

function ApproveJobs(props) {
  const [jobs, setJobs] = useState([]);
  const [recruiterOptions, setRecruiterOptions] = useState([]);

  const [filterJob, setFilterJob] = useState([]);
  const [rejection, setRejection] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const token = localStorage.getItem("SavedToken");
  const [selectedRows, setSelectedRows] = useState([]);
  const [status, setStatus] = useState("Pending");
  const [allSelected, setAllSelected] = useState(false);
  const options = ["Pending", "Approved", "Rejected"];
  const handleRowSelection = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setAllSelected(false);
      setSelectedRows([]);
    } else {
      setAllSelected(true);
      setSelectedRows(jobs.map((row) => row._id));
    }
  };

  const handleEdit = () => {
    navigate(`/editJob/${selectedRows[0]}`);
  };

  const getJobs = async () => {
    const token = localStorage.getItem("SavedToken");
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/get${status}Jobs`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      setJobs(res.data);
      setFilterJob(res.data);
      setShowLoader(false);
    } catch (err) {
      console.log(err);
      setShowLoader(false);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    console.log(new Date(jobs[4].endDate) > new Date());
    // console.log(query);
    var updatedList = [...jobs];

    updatedList = updatedList.filter(
      (item) =>
        item.title?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.description?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.country?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.companyName?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (item.jobId?.toString().indexOf(query) !== -1 ||
          (new Date(item.endDate) < new Date())
            .toString()
            .toLowerCase()
            .indexOf(query)) !== -1
    );
    setFilterJob(updatedList);
    console.log(updatedList);
  };

  const deleteEmployers = async (selected) => {
    try {
      console.log(selected);
      const res = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_SERVER_URL}/deleteJobs`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        data: {
          jobIds: selected,
        },
        credentials: "include",
      });
      console.log(res);
      toast.success("Jobs Deleted Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSelectedRows([]);
      setRefresh(!refresh);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCommentSubmit = async (jobId, content) => {
    try {
      const newComment = await postComment(jobId, content);
      // Update the selected job's comments array with the new comment
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };

  const getRecruiters = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getRecruitersforjobpost`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res.data, "rec");
      let arr = [];
      res.data.recruiters.map((recruiter) => {
        console.log(recruiter._id);
        if (recruiter.name === "0") {
          arr.push({
            label: `${recruiter.email}`,
            value: recruiter._id,
            disabled: true,
          });
        } else {
          arr.push({
            label: `${recruiter.name}, ${recruiter.email}`,
            value: recruiter._id,
          });
        }
      });
      setRecruiterOptions(arr);
      console.log("arr", arr);
    } catch (err) {
      console.log(err);
    }
  };

  const checkRecruiters = (job) => {
    // console.log(job.recruiters, job.currentApproval, job.maxApproval);
    if (
      job.recruiters.length === 0 &&
      job.currentApproval === job.maxApproval - 1
    ) {
      toast.error("Please add Recruiters for the job first", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    } else return true;
  };

  const handleAprroval = async (jid, job) => {
    // console.log(checkRecruiters(job));
    if (checkRecruiters(job)) {
      try {
        const res = await axios({
          method: "patch",
          url: `${process.env.REACT_APP_SERVER_URL}/approveJob/${jid}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: token,
          },
          credentials: "include",
        });
        if (res.status === 200) {
          console.log("Approved successfully");
          setRefresh(!refresh);
          toast.success("Approved", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setRefresh(!refresh);
      } catch (err) {
        toast.error("You are not authorized!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
        setRefresh(!refresh);
      }
    }
  };
  const handleRejection = async (jid) => {
    try {
      const res = await axios({
        method: "patch",
        url: `${process.env.REACT_APP_SERVER_URL}/rejectJob/${jid}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        data: {
          reason: rejection,
        },
        credentials: "include",
      });
      if (res.status === 200) {
        console.log("Rejected successfully");
        setRefresh(!refresh);
        toast.dismiss();
        toast.success("Rejected", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setRefresh(!refresh);
    } catch (err) {
      toast.dismiss();
      toast.error("You are not authorized!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(err);
      setRefresh(!refresh);
    }
  };

  const handleDelete = async () => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this user",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteEmployers(selectedRows);
          },
        },
        {
          label: "No",
          onClick: () => console.log("aborted"),
        },
      ],
    });
  };
  useEffect(() => {
    getRecruiters();
  }, []);

  useEffect(() => {
    getJobs();
    setSelectedRows([]);
  }, [refresh, status]);

  return (
    <div className={styles.table_main_container}>
      <h2 className={styles["manage-employer-head"]}>Manage Jobs</h2>
      <p className={styles["manage-employer-text"]}>Manage your Job list</p>
      <div className={styles["roles"]}>
        {options.map((option) => (
          <button
            key={option}
            onClick={() => setStatus(option)}
            className={styles["role-btn"]}
          >
            {option}
          </button>
        ))}
      </div>
      <div className={styles["large-screen"]}>
        <div className="d-flex justify-content-between mx-3 me-4">
          <div className={styles["search-box"]}>
            <BiSearch color="#8692A6" />
            <input
              className={styles["search-text"]}
              type="text"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
          {selectedRows.length === 0 && (
            <div className="d-flex">
              <Form className="d-flex flex-wrap me-2">
                <div className="">
                  <Form.Select
                    aria-label="All Status"
                    className={styles["search-filters"]}
                    onChange={handleSearch}
                  >
                    <option value="">All Status</option>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </Form.Select>
                </div>
              </Form>
              <Link to={"/createJob"}>
                <button className={styles["add-btn"]}>+</button>
              </Link>
            </div>
          )}
          {selectedRows.length === 1 && (
            <div className="d-flex me-4 mt-3">
              <BiEditAlt size={30} color="#8692A6" onClick={handleEdit} />
              <MdOutlineDelete
                size={30}
                color="#8692A6"
                onClick={handleDelete}
              />
            </div>
          )}
          {selectedRows.length > 1 && (
            <div className="me-4 mt-3">
              <MdOutlineDelete
                size={30}
                color="#8692A6"
                className="me-2"
                onClick={handleDelete}
              />
            </div>
          )}
        </div>
        <div className="mx-3 mx-sm-4">
          <div className={`${styles["table-head"]} rows`}>
            <div className="col-sm-1">
              <input
                type="checkbox"
                className="me-2"
                checked={allSelected}
                onChange={handleSelectAll}
              />
              ID
            </div>
            <div className="col-sm-2 ">Title</div>
            <div className="col-sm-2 px-4">View</div>
            <div className="col-sm-2">Created By</div>
            <div className="col-sm-2">Comment</div>
            {status === "Pending" && (
              <div className="col-sm-3 justify-content-end">Approve</div>
            )}
          </div>

          {showLoader ? (
            <Loader />
          ) : filterJob.length === 0 ? (
            <h2 className="text-center mt-5"> No Data Available</h2>
          ) : (
            <div className={styles["table-body"]}>
              {filterJob.map((job, index) => (
                <div className={`${styles["table-row"]} row`} key={index}>
                  <div className="col-sm-1">
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={selectedRows.includes(job._id)}
                      onChange={() => handleRowSelection(job._id)}
                    />
                    {job.jobId}
                  </div>
                  <div className="col-sm-2 ">{job.title}</div>
                  <div className="col-sm-2 ">
                    <JobDescription job={job} />
                  </div>
                  <div className="col-sm-2 ">{job.createdBy?.name}</div>
                  <div className="col-sm-2">
                    <CommentModal
                      id={job._id}
                      name={job.title}
                      comments={job.comments}
                      onCommentSubmit={handleCommentSubmit}
                    />
                  </div>
                  {status === "Pending" && (
                    <div className="col-sm-3 d-flex justify-content-end">
                      {job.recruiters.length === 0 &&
                        job.currentApproval === job.maxApproval - 1 && (
                          <AddRecruiter
                            jobId={job._id}
                            recruiterOptions={recruiterOptions}
                            refresh={refresh}
                            setRefresh={setRefresh}
                          />
                        )}
                      <button
                        className={styles["approve-btn"]}
                        onClick={() => {
                          handleAprroval(job._id, job);
                        }}
                      >
                        <FcCheckmark />
                      </button>
                      <RejectJD
                        rejection={rejection}
                        rejectJD={handleRejection}
                        setRejection={setRejection}
                        jobId={job._id}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles["small-screen"]}>
        <div className="d-flex justify-content-between me-2">
          <input
            className={styles["search-text"]}
            type="text"
            onChange={handleSearch}
            placeholder="Search"
          />

          {selectedRows.length === 1 && (
            <div className="d-flex">
              <BiEditAlt size={30} color="#8692A6" onClick={handleEdit} />
              <MdOutlineDelete
                size={30}
                color="#8692A6"
                onClick={handleDelete}
              />
            </div>
          )}
          {selectedRows.length > 1 && (
            <div>
              <MdOutlineDelete size={30} color="#8692A6" className="me-2" />
            </div>
          )}
        </div>
        {/* {selectedRows.length === 0 && (
          <div className="mt-2">
            <Form className="d-flex justify-content-between flex-wrap me-2">
              <div className="">
                <Form.Select
                  aria-label="All Cities"
                  className={styles["search-filters"]}
                >
                  <option>All cities</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
              <div className="">
                <Form.Select
                  aria-label="All Status"
                  className={styles["search-filters"]}
                  onChange={handleSearch}
                >
                  <option value="">All Status</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
              </div>
            </Form>
          </div>
        )} */}

        {showLoader ? (
          <Loader />
        ) : filterJob.length === 0 ? (
          <h2 className="text-center mt-5"> No Job Found</h2>
        ) : (
          <div className="my-3">
            {filterJob.map((job, index) => (
              <Card
                key={index}
                className="shadow p-3 my-2
            "
              >
                <div className="d-flex justify-content-between">
                  <div className={styles["job-id"]}>
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={selectedRows.includes(job._id)}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={() => {
                        handleRowSelection(job._id);
                      }}
                    />
                    {job.jobId}
                  </div>
                  {status === "Pending" && (
                    <div>
                      <button
                        className={styles["approve-btn"]}
                        onClick={() => {
                          handleAprroval(job._id, job);
                        }}
                      >
                        <FcCheckmark />
                      </button>
                      <button
                        className={styles["approve-btn"]}
                        onClick={() => {
                          handleRejection(job._id);
                        }}
                      >
                        <AiOutlineClose color="red" />
                      </button>
                    </div>
                  )}
                </div>
                <p className={styles["left"]}>{job.title}</p>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Account Manager</p>
                  <p className={styles["right"]}>{job.employer?.name}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>View JD</p>
                  <p className={styles["right"]}>
                    <JobDescription job={job} />
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Created By</p>
                  <p className={styles["right"]}> {job.createdBy?.name}</p>
                </div>
                {job.recruiters.length === 0 &&
                  job.currentApproval === job.maxApproval - 1 && (
                    <div className="d-flex justify-content-between">
                      <p className={styles["left"]}>Add Recruiter</p>
                      <AddRecruiter
                        jobId={job._id}
                        recruiterOptions={recruiterOptions}
                        refresh={refresh}
                        setRefresh={setRefresh}
                      />
                    </div>
                  )}
              </Card>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ApproveJobs;
