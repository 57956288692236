import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import styles from "../../styles/createUser.module.css";
import { Form, Button } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ChangePassword(props) {
  const token = localStorage.getItem("SavedToken");
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const validate = () => {
    let status = false;
    let err = {};

    if (password.oldPassword.trim() === "") {
      status = true;
      err.oldPassword = "Password can't be empty";
    }
    if (password.newPassword.trim() === "") {
      status = true;
      err.newPassword = "Password can't be empty";
    } else if (
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(
        password.newPassword
      )
    ) {
      status = true;
      err.newPassword = "Password must be strong between 6 to 16 characters";
    }
    if (password.newPassword !== password.confirmPassword) {
      status = true;
      err.confirmPassword = "Password and Confirm password should match";
    }

    setError({ ...err });
    return status;
  };

  let name, value;

  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setPassword({ ...password, [name]: value });
    console.log(password, value);
  };
  const postData = async () => {
    setDisable(true);
    const { oldPassword, newPassword } = password;
    // console.log(user);
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/changePassword`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          oldPassword,
          newPassword,
        }),
      }
    );
    const data = await res.json();
    if (res.status === 200) {
      console.log("Successfully Changed Password", data);
      setPassword({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      toast.success("Password Changed Successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (res.status === 441) {
      toast.error("Password did not match", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(data.message);
    } else {
      toast.error("Error while changing password", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(data.message);
    }
    setTimeout(() => {
      setDisable(false);
    }, 4000);
  };
  return (
    <div>
      <Popup
        trigger={
          <button className={styles["change-password"]}>Change Password</button>
        }
        modal
        nested
      >
        {(close) => (
          <div className={styles["modal"]}>
            <button className={styles["close"]} onClick={close}>
              &times;
            </button>
            <div className={styles["header"]}> Change Password</div>
            <div className={styles["popup-content"]}>
              <Form className="row justify-content-center">
                <Form.Group className="mb-3">
                  <Form.Label className={styles["label"]}>
                    Old Password
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    value={password.oldPassword}
                    onChange={handleInputs}
                    placeholder="Old Password"
                    name="oldPassword"
                  />
                  {error.oldPassword ? (
                    <p style={{ color: "red" }}>{error.oldPassword} </p>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    size="sm"
                    value={password.newPassword}
                    onChange={handleInputs}
                    placeholder="New Password"
                    name="newPassword"
                    type="password"
                  />
                  {error.newPassword ? (
                    <p style={{ color: "red" }}>{error.newPassword} </p>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label> Confirm Password</Form.Label>
                  <Form.Control
                    size="sm"
                    value={password.confirmPassword}
                    onChange={handleInputs}
                    placeholder="Confirm password"
                    name="confirmPassword"
                  />
                  {error.confirmPassword ? (
                    <p style={{ color: "red" }}>{error.confirmPassword} </p>
                  ) : null}
                </Form.Group>
                <div className="mb-0 d-flex justify-content-center">
                  <Button
                    className={styles["login-button"]}
                    disabled={disable}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!validate()) {
                        postData();
                        setTimeout(() => {
                          close();
                        }, 2000);
                      }
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default ChangePassword;
