import React from "react";
import { useState } from "react";
import styles from "../styles/reports.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MultiSelect } from "react-multi-select-component";
import { SlCalender } from "react-icons/sl";
import { BsDownload } from "react-icons/bs";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import exportExcel from "../utils/exportExcel";

const Reports = (props) => {
  const [selectedRecruiter, setSelectedRecruiter] = useState([]);
  const [selectedEmployer, setSelectedEmployer] = useState([]);
  const [startDate, setStartDate] = useState(new Date("01-01-2023"));
  const [endDate, setEndDate] = useState(new Date());
  const [recruiterOptions, setRecruiterOptions] = useState([]);
  const [report, setReport] = useState([]);
  const [employerOptions, setEmployerOptions] = useState([]);
  const token = localStorage.getItem("SavedToken");

  const getRecruiters = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getRecruitersforjobpost`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res.data, "rec");
      let arr = [];
      res.data.recruiters.map((recruiter) => {
        console.log(recruiter._id);
        if (recruiter.name === "0") {
          arr.push({
            label: `${recruiter.email}`,
            value: recruiter._id,
            disabled: true,
          });
        } else {
          arr.push({
            label: `${recruiter.name}, ${recruiter.email}`,
            value: recruiter._id,
          });
        }
      });
      setRecruiterOptions(arr);
      console.log("arr", arr);
    } catch (err) {
      console.log(err);
    }
  };

  const getEmployers = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getEmployersforReport`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res.data, "rec");
      let arr = [];
      res.data.employers.map((employer) => {
        arr.push({
          label: `${employer.name} ${employer.email}`,
          value: employer._id,
        });
      });
      setEmployerOptions(arr);
      console.log("arr", arr);
    } catch (err) {
      console.log(err);
    }
  };

  const generateReport = async () => {
    let recruiterArr = [];
    let employerArr = [];
    selectedEmployer.map((employer) => employerArr.push(employer.value));
    selectedRecruiter.map((recruiter) => recruiterArr.push(recruiter.value));

    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_URL}/${props.role}Report`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        data: {
          startDate,
          endDate,
          recruiters: recruiterArr,
          employers: employerArr,
        },
        credentials: "include",
      });
      setReport(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
      toast.error("Some Error Occured", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (props.role === "employer") {
      getRecruiters();
    } else if (props.role === "recruiter") {
      getEmployers();
    } else if (props.role === "superAdmin") {
      getRecruiters();
      getEmployers();
    }
  }, [props.role]);

  return (
    <>
      <div className={styles.main_container}>
        <div className={styles.heading}> Billing Report </div>
        <div className={styles.subheading}>
          Select range to generate your report
        </div>

        {/* input fields start */}

        <div className={styles.date_container}>
          <div className={styles.start_date_container}>
            <p> Start Date </p>
            <DatePicker
              className={styles.datepicker}
              selected={startDate}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={10}
              showMonthDropdown
              minDate={new Date("01-01-2023")}
              maxDate={new Date()}
              onChange={(date) => setStartDate(date)}
            />
          </div>

          <div className={styles.end_date_container}>
            <p>End Date</p>
            <DatePicker
              className={styles.datepicker}
              selected={endDate}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={10}
              showMonthDropdown
              minDate={startDate}
              maxDate={new Date()}
              onChange={(date) => setEndDate(date)}
            />
          </div>
        </div>

        {/* input fields ends */}

        {recruiterOptions.length > 0 && (
          <div className={styles.worker}>
            <p>Select recruiters</p>
            <MultiSelect
              options={recruiterOptions}
              value={selectedRecruiter}
              onChange={setSelectedRecruiter}
              labelledBy="Select"
            />
          </div>
        )}
        {employerOptions.length > 0 && (
          <div className={styles.worker}>
            <p>Select employers</p>
            <MultiSelect
              options={employerOptions}
              value={selectedEmployer}
              onChange={setSelectedEmployer}
              labelledBy="Select"
            />
          </div>
        )}
        <div className={styles.report}>
          <button
            className={styles.generate_report}
            onClick={() => {
              generateReport();
            }}
          >
            Generate Report
          </button>
          {report.length > 0 && (
            <button
              className={styles.download_report}
              onClick={() => {
                exportExcel(report, "RecruitersReport");
              }}
            >
              <BsDownload /> Download Report
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Reports;
