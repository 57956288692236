import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import styles from "../styles/createUser.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function VideoPopup(props) {
  return (
    <div>
      <Popup
        trigger={<button className={styles["show-btn"]}>Company Intro</button>}
        modal
        nested
      >
        {(close) => (
          <div className={styles["modal"]}>
            <button className={styles["close"]} onClick={close}>
              &times;
            </button>
            <div className={styles["header"]}> Company Intro </div>
            <div className={styles["popup-content"]}>
              <iframe
                src="https://drive.google.com/file/d/1d4dJorfmUW2rYst58bJYEebp3dyRe9mY/preview"
                width="100%"
                height="480"
                allow="autoplay"
              ></iframe>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default VideoPopup;
