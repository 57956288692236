import { Card, Form } from "react-bootstrap";
import styles from "../../styles/onBoardEmployer.module.css";
import { Country } from "country-state-city";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function OnboardCompany(props) {
  const token = localStorage.getItem("SavedToken");
  const role = props.role;
  const user = props.user;
  const [employers, setEmployers] = useState([user?.company?._id]);
  const navigate = useNavigate();
  const [employer, setEmployer] = useState({
    companyName: "",
    domain: "",
    country: "",
    totalEmployees: "",
    contactPersonEmail: "",
    contactPersonFirstName: "",
    contactPersonLastName: "",
    phoneNo: "",
    pinCode: "",
    subscriptionTitle: "",
    expiryDate: new Date(),
    paymentMethod: "",
    password: "",
    logo: "",
    paymentReferenceNumber: "",
  });
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setEmployer({ ...employer, [name]: value });
    console.log(employer, value);
  };
  const [error, setError] = useState({});
  const validate = () => {
    let status = false;
    let err = {};
    if (employer.contactPersonFirstName.trim() === "") {
      status = true;
      err.firstName = "First name can't be empty";
    } else if (!/^[a-zA-Z]+$/.test(employer.contactPersonFirstName)) {
      status = true;
      err.firstName = "Invalid First Name";
    }
    if (
      !/^[a-zA-Z]+$/.test(employer.contactPersonLastName) &&
      employer.contactPersonLastName !== ""
    ) {
      status = true;
      err.lastName = "Last name Invalid";
    }
    if (employer.contactPersonEmail === "") {
      status = true;
      err.email = "Email can't be empty";
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
        employer.contactPersonEmail
      )
    ) {
      status = true;
      err.email = "Invalid Email";
    }
    if (!/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/.test(employer.phoneNo)) {
      status = true;
      err.phoneNo = "Invalid Mobile no.";
    }

    if (employer.companyName.trim() === "") {
      status = true;
      err.companyName = "Company name can't be empty";
    }
    if (employer.domain.trim() === "") {
      status = true;
      err.domain = "Please select a domain first";
    }
    if (employer.country.trim() === "") {
      status = true;
      err.country = "Please select a country first";
    }
    if (employer.password.trim() === "") {
      status = true;
      err.password = "Please select a password first";
    }
    if (employer.totalEmployees === "") {
      status = true;
      err.totalEmployees = "Employees can't be empty";
    } else if (isNaN(employer.totalEmployees)) {
      status = true;
      err.totalEmployees = "Employees should be an integer";
    } else if (0 >= Number(employer.totalEmployees) >= 10000000) {
      status = true;
      err.totalEmployees = "Invalid Employee value";
    }
    if (employer.pinCode.trim() === "") {
      status = true;
      err.pinCode = "Pincode can't be empty";
    } else if (!/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/.test(employer.pinCode)) {
      status = true;
      err.pinCode = "Invalid Pincode Value";
    }
    if (employer.paymentMethod.trim() === "") {
      status = true;
      err.paymentMethod = "Payment method can't be empty";
    }
    if (employer.subscriptionTitle.trim() === "") {
      status = true;
      err.subscriptionTitle = "Subsctiption title can't be empty";
    }

    setError({ ...err });
    return status;
  };

  const postData = async (e) => {
    e.preventDefault();

    if (!validate()) {
      const {
        companyName,
        domain,
        country,
        totalEmployees,
        contactPersonEmail,
        contactPersonFirstName,
        contactPersonLastName,
        phoneNo,
        pinCode,
        subscriptionTitle,
        expiryDate,
        paymentMethod,
        password,
        paymentReferenceNumber,
      } = employer;
      // console.log(user);
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/addCompany`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
          body: JSON.stringify({
            companyName,
            domain,
            country,
            totalEmployees,
            contactPersonEmail,
            contactPersonFirstName,
            contactPersonLastName,
            phoneNo,
            pinCode,
            subscriptionTitle,
            expiryDate,
            paymentMethod,
            password,
            role,
            paymentReferenceNumber,
            employers,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 201) {
        toast.success("Successfully Added!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log("Successfully Added", data);
        setTimeout(() => {
          navigate(`/${props.role.toLowerCase()}s`);
        }, 2000);
      } else if (res.status === 422) {
        toast.error("Email already exists", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(data.message);
      } else {
        toast.error("Error while adding!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(data.message);
      }
    }
  };

  const handleUpload = async (image) => {
    try {
      if (image) {
        const formData = new FormData();
        formData.append("file", image);

        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/uploadImage`,
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();
        setEmployer({ ...employer, logo: data.url });
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("Error while uploading", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const countries = Country.getAllCountries();

  return (
    <div>
      <h1 className={styles["onboard-head"]}>Onboard New {role}</h1>
      <p className={styles["onboard-text"]}>Fill this form to add new {role}</p>
      <Card className="p-4 m-4">
        <Form className="d-flex flex-wrap row">
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Email <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="contactPersonEmail"
              value={employer.contactPersonEmail}
              className={styles["employer-forms"]}
              onChange={handleInputs}
            />
            {error.email ? (
              <p style={{ color: "red" }}>{error.email} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Company Name <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="companyName"
              value={employer.companyName}
              onChange={handleInputs}
              className={styles["employer-forms"]}
            />
            {error.companyName ? (
              <p style={{ color: "red" }}>{error.companyName} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Domain <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="domain"
              value={employer.domain}
              onChange={handleInputs}
              className={styles["employer-forms"]}
            />
            {error.domain ? (
              <p style={{ color: "red" }}>{error.domain} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Country <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Select
              name="country"
              value={employer.country}
              onChange={handleInputs}
              aria-label="Select"
              className={styles["employer-forms"]}
            >
              <option value="">Select Country</option>
              {countries.map((country, index) => (
                <option key={index}>{country.name}</option>
              ))}
            </Form.Select>
            {error.country ? (
              <p style={{ color: "red" }}>{error.country} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Total Employees <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="totalEmployees"
              value={employer.totalEmployees}
              onChange={handleInputs}
              className={styles["employer-forms"]}
              maxLength={8}
            />
            {error.totalEmployees ? (
              <p style={{ color: "red" }}>{error.totalEmployees} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              First Name <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="contactPersonFirstName"
              value={employer.contactPersonFirstName}
              onChange={handleInputs}
              className={styles["employer-forms"]}
            />
            {error.firstName ? (
              <p style={{ color: "red" }}>{error.firstName} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Last Name
            </Form.Label>
            <Form.Control
              name="contactPersonLastName"
              value={employer.contactPersonLastName}
              onChange={handleInputs}
              className={styles["employer-forms"]}
            />
            {error.lastName ? (
              <p style={{ color: "red" }}>{error.lastName} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Password <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="password"
              value={employer.password}
              onChange={handleInputs}
              className={styles["employer-forms"]}
            />
            {error.password ? (
              <p style={{ color: "red" }}>{error.password} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Pin Code <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="pinCode"
              value={employer.pinCode}
              onChange={handleInputs}
              aria-label="Select"
              className={styles["employer-forms"]}
              maxLength={6}
            >
              {/* <option>Country Code</option>
              {countries.map((country, index) => (
                <option key={index}>{country.isoCode}</option>
              ))} */}
            </Form.Control>
            {error.pinCode ? (
              <p style={{ color: "red" }}>{error.pinCode} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Phone No. <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="phoneNo"
              value={employer.phoneNo}
              onChange={handleInputs}
              className={styles["employer-forms"]}
              maxLength={10}
            />
            {error.phoneNo ? (
              <p style={{ color: "red" }}>{error.phoneNo} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Expiry Date <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <DatePicker
              minDate={new Date()}
              selected={employer.expiryDate}
              onChange={(date) => {
                setEmployer({ ...employer, ["expiryDate"]: date });
              }}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Subscription <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Select
              name="subscriptionTitle"
              value={employer.subscriptionTitle}
              onChange={handleInputs}
              className={styles["employer-forms"]}
            >
              <option value="">Select</option>

              <option value="basic">Basic</option>
              <option value="premium">Premium</option>
              <option value="enterprise">Enterprise</option>
              <option value="trial">Trial</option>
            </Form.Select>
            {error.subscriptionTitle ? (
              <p style={{ color: "red" }}>{error.subscriptionTitle} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Payment Method <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Select
              name="paymentMethod"
              value={employer.paymentMethod}
              onChange={handleInputs}
              aria-label="Select"
              className={styles["employer-forms"]}
            >
              <option>Select</option>
              <option value="Online">Online</option>
              <option value="Offline">Offline</option>
            </Form.Select>
            {error.paymentMethod ? (
              <p style={{ color: "red" }}>{error.paymentMethod} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Payment Reference No.
            </Form.Label>
            <Form.Control
              className={styles["employer-forms"]}
              value={employer.paymentReferenceNumber}
              name="paymentReferenceNumber"
              onChange={handleInputs}
              maxLength={16}
            />
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Company Logo
            </Form.Label>
            <input
              type="file"
              className={styles["image"]}
              accept=".jpg, .jpeg, .png"
              onChange={(e) => handleUpload(e.target.files[0])}
            />
          </div>
        </Form>
        <div className="row mx-0">
          <button
            className={`col-md-4 me-3 ${styles["onboard-btn-c"]}`}
            onClick={() => {
              navigate("/employers");
            }}
          >
            Cancel
          </button>
          <button
            className={`col-md-4 ${styles["onboard-btn-a"]}`}
            onClick={postData}
          >
            Add
          </button>
        </div>
      </Card>
    </div>
  );
}

export default OnboardCompany;
