import { Card, Form } from "react-bootstrap";
import styles from "../styles/onBoardEmployer.module.css";
import { Country } from "country-state-city";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditCompany() {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = localStorage.getItem("SavedToken");
  const [employer, setEmployer] = useState({
    companyName: "",
    domain: "",
    country: "",
    totalEmployees: "",
    contactPersonEmail: "",
    contactPersonFirstName: "",
    contactPersonLastName: "",
    phoneNo: "",
    pinCode: "",
    logo: "",
    subscriptionTitle: "",
    expiryDate: new Date(),
    paymentMethod: "",
    paymentReferenceNumber: "",
  });

  const handleUpload = async (image) => {
    try {
      if (image) {
        const formData = new FormData();
        formData.append("file", image);

        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/uploadImage`,
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();
        setEmployer({ ...employer, logo: data.url });
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("Error while uploading", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getEmployer = async () => {
    const token = localStorage.getItem("SavedToken");
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getCompany/${id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      const item = res.data.company;

      item.contactPersonFirstName = item.name.split(" ")[0];
      item.contactPersonLastName = item.name.split(" ").slice(1).toString();
      item.contactPersonEmail = item.email;
      // item.phoneNo = item?.mobile;
      setEmployer(item);
      console.log(item);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getEmployer();
  }, []);

  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setEmployer({ ...employer, [name]: value });
    console.log(employer, value);
  };

  const postData = async (e) => {
    e.preventDefault();
    const {
      companyName,
      domain,
      country,
      totalEmployees,
      contactPersonEmail,
      contactPersonFirstName,
      contactPersonLastName,
      phoneNo,
      logo,
      pinCode,
      subscriptionTitle,
      expiryDate,
      paymentMethod,
      paymentReferenceNumber,
    } = employer;

    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/editCompany/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          companyName,
          domain,
          country,
          totalEmployees,
          contactPersonEmail,
          contactPersonFirstName,
          contactPersonLastName,
          phoneNo,
          logo,
          pinCode,
          subscriptionTitle,
          expiryDate,
          paymentMethod,
          paymentReferenceNumber,
        }),
      }
    );
    const data = await res.json();
    if (res.status === 200) {
      setTimeout(() => {
        console.log("Successfully Added", data);
        navigate("/employers");
      }, 2000);
      toast.success("Successfully edited", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(data.message);
    }
  };

  const countries = Country.getAllCountries();

  return (
    <div>
      <h1 className={styles["onboard-head"]}>Edit Company</h1>
      <p className={styles["onboard-text"]}>Edit Company Details</p>
      <Card className="p-4 m-4">
        <Form className="d-flex flex-wrap row">
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Email <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="contactPersonEmail"
              value={employer?.contactPersonEmail}
              className={styles["employer-forms"]}
              onChange={handleInputs}
            />
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Company Name <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="companyName"
              value={employer.companyName}
              onChange={handleInputs}
              className={styles["employer-forms"]}
            />
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Domain <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="domain"
              value={employer.domain}
              onChange={handleInputs}
              className={styles["employer-forms"]}
            />
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Country <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Select
              name="country"
              value={employer.country}
              onChange={handleInputs}
              aria-label="Select"
              className={styles["employer-forms"]}
            >
              <option>Select Countries</option>
              {countries.map((country, index) => (
                <option key={index}>{country.name}</option>
              ))}
            </Form.Select>
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Total Employees <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="totalEmployees"
              value={employer.totalEmployees}
              onChange={handleInputs}
              className={styles["employer-forms"]}
            />
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              First Name <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="contactPersonFirstName"
              value={employer.contactPersonFirstName}
              onChange={handleInputs}
              className={styles["employer-forms"]}
            />
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Last Name
            </Form.Label>
            <Form.Control
              name="contactPersonLastName"
              value={employer.contactPersonLastName}
              onChange={handleInputs}
              className={styles["employer-forms"]}
            />
          </div>

          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Country Code <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="pinCode"
              // value={countries.filter(
              //   (country) => (country.name === employer.country)?.isoCode
              // )}
              value={employer.pinCode}
              onChange={handleInputs}
              // disabled
              aria-label="Select"
              className={styles["employer-forms"]}
            ></Form.Control>
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Phone No. <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="phoneNo"
              value={employer.phoneNo}
              onChange={handleInputs}
              className={styles["employer-forms"]}
            />
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Expiry Date <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <DatePicker
              minDate={new Date()}
              selected={new Date(employer.expiryDate)}
              onChange={(date) => {
                setEmployer({ ...employer, ["expiryDate"]: date });
              }}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Subscription <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="subscriptionTitle"
              value={employer.subscriptionTitle}
              onChange={handleInputs}
              className={styles["employer-forms"]}
            />
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Payment Method <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Select
              name="paymentMethod"
              value={employer.paymentMethod}
              onChange={handleInputs}
              aria-label="Select"
              className={styles["employer-forms"]}
            >
              <option>Select</option>
              <option value="Online">Online</option>
              <option value="Offline">Offline</option>
            </Form.Select>
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Payment Reference No.
            </Form.Label>
            <Form.Control
              className={styles["employer-forms"]}
              value={employer.paymentReferenceNumber}
              name="paymentReferenceNumber"
              onChange={handleInputs}
              maxLength={16}
            />
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["employer-labels"]}>
              Company Logo
            </Form.Label>
            <input
              type="file"
              className={styles["image"]}
              accept=".jpg, .jpeg, .png"
              onChange={(e) => handleUpload(e.target.files[0])}
            />
          </div>
        </Form>
        <div className="row mx-0">
          <button
            className={`col-md-4 me-3 ${styles["onboard-btn-c"]}`}
            onClick={() => {
              navigate("/employers");
            }}
          >
            Cancel
          </button>
          <button
            className={`col-md-4 ${styles["onboard-btn-a"]}`}
            onClick={postData}
          >
            Add
          </button>
        </div>
      </Card>
    </div>
  );
}

export default EditCompany;
