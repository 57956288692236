import styles from "../../styles/adminDashboard.module.css";
import { BsSearch } from "react-icons/bs";
import { BiBell, BiSearch } from "react-icons/bi";
import { Row, Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import SplineChart from "../splineChart";
import PieChart from "../pieChart";
import axios from "axios";
import { AiOutlineAim } from "react-icons/ai";
import { ImAlarm } from "react-icons/im";
import Loader from "../loader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

function SuperAdminDashboard() {
  const navigate = useNavigate();
  const token = localStorage.getItem("SavedToken");
  const [topCompanies, setTopCompanies] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [createdJobs, setCreatedJobs] = useState(0);
  const [companiesOnboarded, setCompaniesOnboarded] = useState(0);
  const [candidatesOnboarded, setCandidatesOnboarded] = useState(0);
  const [jobCandidates, setJobCandidates] = useState([]);
  const [jobsFrame, setJobFrame] = useState({
    labels: [],
    datasets: [],
  });

  const [interviews, setInterviews] = useState(0);
  const [weeks, setWeeks] = useState({
    createdJobs: "7",
    companiesOnboarded: "7",
    candidatesOnboarded: "7",
    interviews: "7",
    spline: "week",
  });
  let name, value;
  const handleWeekChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setWeeks({ ...weeks, [name]: value });
    console.log(weeks);
  };

  const getJobCandidates = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getJobsWithCandidates`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      setJobCandidates(res.data.jobsCandidates);
    } catch (err) {
      console.log(err);
    }
  };

  const getCreatedJobs = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getAllJobsCount/${weeks.createdJobs}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      setCreatedJobs(res.data.createdJobsCount);
    } catch (err) {
      console.log(err);
    }
  };
  const getCompaniesOnboarded = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getCompaniesCount/${weeks.companiesOnboarded}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      setCompaniesOnboarded(res.data.createdCompaniesCount);
    } catch (err) {
      console.log(err);
    }
  };

  const getCandidatesOnboarded = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getCandidatesCount/${weeks.candidatesOnboarded}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      setCandidatesOnboarded(res.data.onboardedCandidatesCount);
    } catch (err) {
      console.log(err);
    }
  };

  const getInterviews = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/interviews/scheduled/${weeks.interviews}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      setInterviews(res.data.interviewsScheduled);
    } catch (err) {
      console.log(err);
    }
  };

  const getJobFrames = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getAllJobsTimeFrame/${weeks.spline}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      setJobFrame({
        labels: res.data.labels,
        datasets: res.data.datasets,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getTopCompanies = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getTopCompanies`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      setTopCompanies(res.data);
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getCreatedJobs();
  }, [weeks.createdJobs]);

  useEffect(() => {
    getCompaniesOnboarded();
  }, [weeks.companiesOnboarded]);

  useEffect(() => {
    getCandidatesOnboarded();
  }, [weeks.candidatesOnboarded]);

  useEffect(() => {
    getInterviews();
  }, [weeks.interviews]);

  useEffect(() => {
    getJobFrames();
  }, [weeks.spline]);

  useEffect(() => {
    getJobCandidates();
    getTopCompanies();
  }, []);

  return (
    <div className={styles["admin"]}>
      <h1 className={`${styles["dash-head"]}`}>Dashboard</h1>
      <div className="d-flex justify-content-between align-items-center py-2">
        <p className={styles["dash-text"]}>User Activity Summary</p>
        <div className={styles.icons_holder}>
          <div className={styles["dash-icons"]}>
            <BiSearch size={"30px"} />
          </div>
          <div className={styles["dash-icons"]}>
            <BiBell size={"30px"} />
          </div>
        </div>
      </div>
      <Row className="mx-1 me-3 justify-content-between">
        <Card className={` py-2 ${styles["admin-cards"]}`}>
          <div className="d-flex justify-content-between">
            <h3 className={styles["card-head"]}>{createdJobs}</h3>
            <select
              name="createdJobs"
              id=""
              className={styles["card-right"]}
              onChange={handleWeekChange}
            >
              <option value="7">7 Days</option>
              <option value="30">1 Month</option>
              <option value="365">Last Year</option>
            </select>
          </div>
          <p className={styles["card-text"]}>Job listing created</p>
          <p className={styles["card-bottom"]}>
            {weeks.createdJobs === "7"
              ? "This Week"
              : weeks.createdJobs === "30"
              ? "This Month"
              : "Last Year"}
          </p>
        </Card>

        <Card className={`${styles["admin-cards"]} col-md-2  py-2`}>
          <div className="d-flex justify-content-between">
            <h3 className={styles["card-head"]}>{companiesOnboarded}</h3>
            <select
              name="companiesOnboarded"
              id=""
              className={styles["card-right"]}
              onChange={handleWeekChange}
            >
              <option value="7">7 Days</option>
              <option value="30">1 Month</option>
              <option value="365">Last Year</option>
            </select>
          </div>
          <p className={styles["card-text"]}>Closed Jobs</p>
          <p className={styles["card-bottom"]}>
            {weeks.companiesOnboarded === "7"
              ? "This Week"
              : weeks.companiesOnboarded === "30"
              ? "This Month"
              : "Last Year"}
          </p>
        </Card>
        <Card className={`${styles["admin-cards"]}  py-2`}>
          <div className="d-flex justify-content-between">
            <h3 className={styles["card-head"]}>{candidatesOnboarded}</h3>
            <select
              name="candidatesOnboarded"
              id=""
              className={styles["card-right"]}
              onChange={handleWeekChange}
            >
              <option value="7">7 Days</option>
              <option value="30">1 Month</option>
              <option value="365">Last Year</option>
            </select>
          </div>
          <p className={styles["card-text"]}>Candidates Onboarded</p>
          <p className={styles["card-bottom"]}>
            {weeks.candidatesOnboarded === "7"
              ? "This Week"
              : weeks.candidatesOnboarded === "30"
              ? "This Month"
              : "Last Year"}
          </p>
        </Card>
        <Card className={`${styles["admin-cards"]} col-md-2  py-2`}>
          <div className="d-flex justify-content-between">
            <h3 className={styles["card-head"]}>{interviews}</h3>
            <select
              name="interviews"
              id=""
              className={styles["card-right"]}
              onChange={handleWeekChange}
            >
              <option value="7">7 Days</option>
              <option value="30">1 Month</option>
              <option value="365">Last Year</option>
            </select>
          </div>
          <p className={styles["card-text"]}>Interviews Scheduled</p>
          <p className={styles["card-bottom"]}>This Week</p>
        </Card>
      </Row>

      {/* Row 2 */}

      <Row className="mx-1 mt-3 justify-content-between">
        <div className={styles["latest-jobs"]}>
          <div>
            <h3 className={styles["jobs-head"]}>Latest active jobs</h3>
            <table style={{ width: "100%" }}>
              <tbody>
                {jobCandidates.map((job) => (
                  <tr className="d-flex justify-content-between mt-3">
                    <td className={styles["active-jobs-text"]}>{job.title}</td>
                    <td className={styles["active-jobs-numbers"]}>
                      {job.candidates} Candidates
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles["spline-chart"]}>
          <div className="d-flex justify-content-between px-3 py-2 mb-4">
            <p>Engagement Stats</p>
            <select
              name="spline"
              id="spline"
              className={styles["chart-select"]}
              onChange={handleWeekChange}
            >
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>
          <SplineChart labels={jobsFrame.labels} data={jobsFrame.datasets} />
        </div>
      </Row>
      <Row className="mx-1 mt-3 justify-content-between">
        <p className={styles["open-jobs-head"]}>Top Companies</p>
        {showLoader ? (
          <Loader />
        ) : topCompanies.length === 0 ? (
          <h3 className="text-center"> No Company available</h3>
        ) : (
          topCompanies.map((company) => (
            <Card className={`${styles["job-card"]} pt-3`}>
              <p className={styles["job-title"]}>{company.employer}</p>
              <p className={styles.cardJobsText}> {company.jobCount} jobs</p>
            </Card>
          ))
        )}
      </Row>
    </div>
  );
}

export default SuperAdminDashboard;
