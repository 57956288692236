import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Register from "./components/register";
import Login from "./components/User/login";
import AdminHome from "./pages/adminHome";
import Jobs from "./pages/jobs";
import Recruiters from "./pages/recruiters";
import Candidates from "./pages/candidates";
import Companies from "./components/SuperAdmin/companies";
import SideNav from "./components/sideNav";
import SplineChart from "./components/splineChart";
import { useEffect, useState } from "react";
import axios from "axios";
import AdminDashboard from "./components/SuperAdmin/adminDashboard";
import OnboardCompany from "./components/SuperAdmin/onBoardEmployer";
import { AppContext } from "./components/appContext";
import EditCompany from "./pages/editCompany";
import EmployerHome from "./pages/employerHome";
import CreateJob from "./pages/createJob";
import EditJob from "./pages/editJobPost";
import MaintainencePage from "./components/maintainence";
import OnboardCandidate from "./pages/addCandidate";
import JobCandidates from "./pages/jobCandidates";
import CandidateForm from "./pages/candidateForm";
import Loader from "./components/loader";
import CompanyCandidates from "./pages/companyCandidates";
import AvailableCandidates from "./pages/availableCandidates";
import EmployerDashboard from "./components/employerDashboard";
import PieChart from "./components/pieChart";
import RecruiterDashboard from "./components/recruiterDashboard";
import RolesAndPermissions from "./components/Roles&Permission/permissions";
import Employees from "./components/Employer/employees";
import ApproveJobs from "./components/Approver/approveJobs";
import Profile from "./components/User/profile";
import SuperAdminDashboard from "./components/Dashboard/superAdmin";
import JobStatus from "./pages/jobStatus";
import Reports from "./pages/reports";
import Candidate from "./components/Candidates/candidateDetails";
import CandidateHome from "./pages/candidateHome";
import { ToastContainer } from "react-toastify";
import CompanyScreen from "./components/companyScreen/CompanyScreen";

function App() {
  const [user, setUser] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 100);
  }

  const getProfile = async () => {
    const token = localStorage.getItem("SavedToken");
    if (token) {
      try {
        const res = await axios({
          method: "post",
          url: `${process.env.REACT_APP_SERVER_URL}/profile`,
          headers: { authorization: token },
        });
        if (res.status === 201) {
          if (location.pathname === "/login") {
            navigate("/");
          }
          setUser(res.data.authData);
          console.log(res.data);
        } else {
          console.log("user not found");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    getProfile();
  }, [refreshPage]);

  return (
    !loading && (
      <>
        {/* <Routes>
          <Route
            path="/companyBoard/:companyName"
            element={<CompanyScreen />}>
          </Route>
        </Routes> */}
        <AppContext.Provider value={{ user, setUser }}>
          <div>
            {!user && (
              <Routes>
                {console.log("user exisists", user)}

                {/* <Routes> */}

                {/* </Routes> */}
                {/* <Route
                  path="/companyBoard/:companyName"
                  element={<CompanyScreen />}>
               </Route> */}

                <Route path="/register" element={<Register />}></Route>
                <Route
                  path="*"
                  element={<Login value={{ refreshPage, setRefreshPage }} />}
                ></Route>
                <Route path="/sidenav" element={<SideNav />}></Route>
                <Route path="/splinechart" element={<SplineChart />}></Route>
                <Route path="/piechart" element={<PieChart />}></Route>

                <Route
                  path="/onboardCandidate/:jid/:rid"
                  element={<CandidateForm />}
                ></Route>
                <Route
                  path="onBoardCandidate/:rid"
                  element={<OnboardCandidate />}
                ></Route>

                <Route
                  path="/companyBoard/:companyName"
                  element={<CompanyScreen />}
                ></Route>
              </Routes>
            )}
            {user && user.role?.name === "superAdmin" && (
              <Routes>
                <Route
                  path={"/"}
                  element={
                    <AdminHome
                      name={user.name}
                      role={user.role.name}
                      image={user.image}
                    />
                  }
                >
                  <Route>
                    <Route
                      path="employers"
                      element={<Companies role="Employer" />}
                    ></Route>
                    <Route path="createJob" element={<CreateJob />}></Route>
                    <Route path="editJob/:jobId" element={<EditJob />}></Route>

                    <Route
                      path="candidates"
                      element={<CompanyCandidates role="superAdmin" />}
                    ></Route>
                    <Route
                      path="recruiters"
                      element={<Companies role="Recruiter" />}
                    ></Route>
                    <Route path="jobs" element={<Jobs />}></Route>
                    <Route
                      path="roles"
                      element={<RolesAndPermissions />}
                    ></Route>
                    <Route
                      path="candidate/:candidateId"
                      element={<Candidate />}
                    ></Route>
                    <Route
                      path="jobCandidates/:jid"
                      element={<JobCandidates />}
                    ></Route>

                    <Route
                      path="/onboardCandidate/:jid/:rid"
                      element={<CandidateForm />}
                    ></Route>
                    <Route
                      path="onboardEmployer"
                      element={<OnboardCompany role="Employer" user={user} />}
                    ></Route>
                    <Route
                      path="editCompany/:id"
                      element={<EditCompany />}
                    ></Route>
                    <Route
                      path="onboardRecruiter"
                      element={<OnboardCompany role="Recruiter" user={user} />}
                    ></Route>
                    <Route
                      path="profile"
                      element={<Profile user={user} />}
                    ></Route>

                    <Route
                      path="/admin"
                      element={<SuperAdminDashboard />}
                    ></Route>
                    <Route
                      path="/masters"
                      element={<MaintainencePage />}
                    ></Route>
                    <Route
                      path="/settings"
                      element={<MaintainencePage />}
                    ></Route>
                  </Route>
                </Route>
              </Routes>
            )}
            {user && user.role?.name === "CompanyAdmin" && (
              <Routes>
                <Route
                  path={"/"}
                  element={
                    <AdminHome
                      name={user.name}
                      role={user.role.name}
                      image={user.image}
                    />
                  }
                >
                  <Route>
                    <Route
                      path="candidates"
                      element={<CompanyCandidates role="Employer" />}
                    ></Route>
                    <Route path="recruiters" element={<Recruiters />}></Route>
                    <Route
                      path="employees"
                      element={<Employees role="Employer" />}
                    ></Route>
                    <Route
                      path="reports"
                      element={<Reports role="employer" />}
                    ></Route>
                    <Route
                      path="jobs"
                      element={<Jobs user={"employer"} />}
                    ></Route>
                    <Route
                      path="job-status"
                      element={<JobStatus user={"employer"} />}
                    ></Route>
                    <Route
                      path="createJob"
                      element={
                        <CreateJob role="Employer" company={user.company._id} />
                      }
                    ></Route>
                    <Route path="editJob/:jobId" element={<EditJob />}></Route>
                    <Route
                      path="candidate/:candidateId"
                      element={<Candidate />}
                    ></Route>
                    <Route
                      path="onboardRecruiter"
                      element={<OnboardCompany role="Recruiter" user={user} />}
                    ></Route>

                    <Route
                      path="jobCandidates/:jid"
                      element={<JobCandidates />}
                    ></Route>
                    <Route
                      path="/onboardCandidate/:jid/:rid"
                      element={<CandidateForm />}
                    ></Route>
                    <Route
                      path="profile"
                      element={<Profile user={user} />}
                    ></Route>

                    <Route
                      path="/companyBoard/:companyName"
                      element={<CompanyScreen />}
                    ></Route>

                    <Route path="admin" element={<EmployerDashboard />}></Route>
                  </Route>
                </Route>
                {/* <Route path="*" element={<MaintainencePage />}></Route> */}
              </Routes>
            )}
            {user &&
              (user.role?.name === "RecruiterAdmin" ||
                user.role?.name === "TA") && (
                <Routes>
                  <Route
                    path={"/"}
                    element={
                      <AdminHome
                        name={user.name}
                        role={user.role.name}
                        image={user.image}
                      />
                    }
                  >
                    <Route>
                      <Route path="recruiters" element={<Recruiters />}></Route>
                      <Route
                        path="jobs"
                        element={<Jobs user={"recruiter"} />}
                      ></Route>
                      <Route
                        path="candidates"
                        element={
                          <CompanyCandidates
                            role="Recruiter"
                            recruiter={user.company._id}
                          />
                        }
                      ></Route>
                      <Route
                        path="reports"
                        element={<Reports role="recruiter" />}
                      ></Route>
                      <Route
                        path="candidate/:candidateId"
                        element={<Candidate />}
                      ></Route>
                      <Route
                        path="availableCandidates/:jid"
                        element={<AvailableCandidates rid={user.company} />}
                      ></Route>

                      <Route path="createJob" element={<CreateJob />}></Route>
                      <Route
                        path="editEmployer/:id"
                        element={<EditCompany />}
                      ></Route>
                      <Route
                        path="onBoardCandidate/:rid"
                        element={<OnboardCandidate />}
                      ></Route>
                      <Route
                        path="jobCandidates/:jid"
                        element={<JobCandidates recruiter={user.company} />}
                      ></Route>
                      <Route
                        path="/onboardCandidate/:jid/:rid"
                        element={<CandidateForm />}
                      ></Route>
                      <Route
                        path="employees"
                        element={<Employees role="Recruiter" />}
                      ></Route>

                      <Route
                        path="admin"
                        element={<RecruiterDashboard />}
                      ></Route>
                      <Route path="loader" element={<Loader />}></Route>
                      <Route
                        path="profile"
                        element={<Profile user={user} />}
                      ></Route>
                      <Route
                        path="/settings"
                        element={<MaintainencePage />}
                      ></Route>

                      {/* <Route path="*" element={<MaintainencePage />}></Route> */}
                    </Route>
                  </Route>
                </Routes>
              )}

            {user && user.role?.name === "Employee" && (
              <Routes>
                <Route
                  path={"/"}
                  element={
                    <AdminHome
                      name={user.name}
                      role={user.role.name}
                      image={user.image}
                    />
                  }
                >
                  <Route>
                    <Route
                      path="candidates"
                      element={<CompanyCandidates role="Employer" />}
                    ></Route>
                    <Route path="recruiters" element={<Recruiters />}></Route>

                    <Route
                      path="jobs"
                      element={<ApproveJobs user={"employer"} />}
                    ></Route>
                    <Route
                      path="createJob"
                      element={
                        <CreateJob role="Employer" company={user.company._id} />
                      }
                    ></Route>
                    <Route path="editJob/:jobId" element={<EditJob />}></Route>
                    <Route
                      path="jobCandidates/:jid"
                      element={<JobCandidates />}
                    ></Route>
                    <Route
                      path="/onboardCandidate/:jid/:rid"
                      element={<CandidateForm />}
                    ></Route>
                    <Route
                      path="profile"
                      element={<Profile user={user} />}
                    ></Route>

                    <Route path="admin" element={<EmployerDashboard />}></Route>

                    {/* <Route path="*" element={<MaintainencePage />}></Route> */}
                  </Route>
                </Route>
              </Routes>
            )}
            {user && user.role?.name === "Candidate" && (
              <Routes>
                <Route
                  path={"/"}
                  element={
                    <AdminHome
                      name={user.name}
                      role={user.role.name}
                      image={user.image}
                    />
                  }
                >
                  <Route path="home" element={<CandidateHome />}></Route>
                  <Route
                    path="profile"
                    element={<Profile user={user} />}
                  ></Route>
                  {/* <Route path="*" element={<CandidateHome />}></Route> */}
                </Route>
                <Route
                  path={"*"}
                  element={
                    <AdminHome
                      name={user.name}
                      role={user.role.name}
                      image={user.image}
                    />
                  }
                >
                  <Route path="" element={<CandidateHome />}></Route>
                </Route>
              </Routes>
            )}
            <ToastContainer />
          </div>
        </AppContext.Provider>
      </>
    )
  );
}

export default App;
