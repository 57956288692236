import axios from "axios";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { Form, Card } from "react-bootstrap";
import Loader from "../components/loader";
import { BiSearch, BiEditAlt } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { CiMail } from "react-icons/ci";
import styles from "../styles/manageEmployers.module.css";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineDelete } from "react-icons/md";
import JobDescription from "../components/jobDescription";
import { AiOutlineClose } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import { postComment } from "../api/jobs";
import { TbBell } from "react-icons/tb";
import { TbMessage2 } from "react-icons/tb";

import CommentModal from "../components/comments";
import Paginate from "../components/Pagination/pagination";

function JobStatus(props) {
  const [jobs, setJobs] = useState([]);
  const [disableReminder, setDisableReminder] = useState(false);
  const [status, setStatus] = useState("Pending");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [sort, setSort] = useState({ sort: "_id", order: "desc" });
  const [totalPages, setTotalPages] = useState(1);
  const [showLoader, setShowLoader] = useState(true);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const token = localStorage.getItem("SavedToken");
  const [selectedRows, setSelectedRows] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const options = ["Pending", "Approved", "Rejected"];
  const handleRowSelection = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setAllSelected(false);
      setSelectedRows([]);
    } else {
      setAllSelected(true);
      setSelectedRows(jobs.map((row) => row._id));
    }
  };

  const handleEdit = () => {
    navigate(`/editJob/${selectedRows[0]}`);
  };

  const getJobs = async () => {
    const token = localStorage.getItem("SavedToken");
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getJobStatus?page=${page}&sort=${sort.sort},${sort.order}&search=${search}&limit=${limit}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      setJobs(res.data.jobs);
      setTotalPages(res.data.totalPages);
      setTotal(res.data.total);
      setShowLoader(false);
    } catch (err) {
      console.log(err);
      setShowLoader(false);
    }
  };

  const handleCommentSubmit = async (jobId, content) => {
    try {
      await postComment(jobId, content);
      // Update the selected job's comments array with the new comment
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearch(query);
    // console.log(query);
    // var updatedList = [...jobs];
    // updatedList = updatedList.filter(
    //   (item) =>
    //     item.title?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
    //     item.description?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
    //     item.country?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
    //     item.companyName?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
    //     (item.jobId?.toString().indexOf(query) !== -1 ||
    //       (new Date(item.endDate) < new Date())
    //         .toString()
    //         .toLowerCase()
    //         .indexOf(query)) !== -1 ||
    //     item.currentApproval?.toString().indexOf(query) !== -1
    // );
    // console.log(updatedList);
  };

  const sendReminderMail = async (id) => {
    try {
      const res = await axios({
        method: "patch",
        url: `${process.env.REACT_APP_SERVER_URL}/sendReminder/${id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      if (res.status === 200) {
        console.log("Approved successfully");
        // setRefresh(!refresh);
        toast.dismiss();
        toast.success("Reminder Sent Successfully", {
          position: "top-center",
          // autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setTimeout(() => setDisableReminder(false), 5000);
    } catch (error) {
      console.log(error);
      toast.error("Error while sending reminder mail!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => setDisableReminder(false), 5000);
    }
  };

  const deleteEmployers = async (selected) => {
    try {
      console.log(selected);
      const res = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_SERVER_URL}/deleteJobs`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        data: {
          jobIds: selected,
        },
        credentials: "include",
      });
      console.log(res);
      toast.success("Jobs Deleted Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSelectedRows([]);
      setRefresh(!refresh);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this user",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteEmployers(selectedRows);
          },
        },
        {
          label: "No",
          onClick: () => console.log("aborted"),
        },
      ],
    });
  };

  useEffect(() => {
    getJobs();
  }, [refresh, page, search, sort, limit]);

  return (
    <div className={styles.table_main_container}>
      <h2 className={styles["manage-employer-head"]}>Jobs Status</h2>
      <p className={styles["manage-employer-text"]}>
        View the status of your job created
      </p>

      <div className={styles["large-screen"]}>
        <div className="d-flex justify-content-between mx-3 me-4">
          {/* <div className={styles["search-box"]}>
            <BiSearch color="#8692A6" />
            <input
              className={styles["search-text"]}
              type="text"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div> */}
          {/* search box */}
          <div className={styles["search-box"]}>
            <BiSearch
              className={styles.icon_search_modify}
              color="#8692A6"
              size="20px"
            />
            <input
              className={styles["search-text"]}
              type="text"
              placeholder="Search for Sites, Tools or Users"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {/* search box */}
          {selectedRows.length === 0 && (
            <div className="d-flex">
              <Form className="d-flex flex-wrap me-2">
                <div className="">
                  <Form.Select
                    aria-label="All Status"
                    className={styles["search-filters"]}
                    onChange={handleSearch}
                  >
                    <option value="">All Stages</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </Form.Select>
                </div>
              </Form>
              {/* <Link to={"/createJob"}>
                <button className={styles["add-btn"]}>+</button>
              </Link> */}
              <Link style={{ textDecoration: "none" }} to={"/createJob"}>
                <button className={styles.add_new_user}>
                  <span style={{ fontSize: "30px", paddingRight: "10px" }}>
                    +
                  </span>{" "}
                  Add New Job
                </button>
              </Link>
            </div>
          )}
          {selectedRows.length === 1 && (
            <div className="d-flex me-4 mt-3">
              <BiEditAlt size={30} color="#8692A6" onClick={handleEdit} />
              <MdOutlineDelete
                size={30}
                color="#8692A6"
                onClick={handleDelete}
              />
            </div>
          )}
          {selectedRows.length > 1 && (
            <div className="me-4 mt-3">
              <MdOutlineDelete
                size={30}
                color="#8692A6"
                className="me-2"
                onClick={handleDelete}
              />
            </div>
          )}
        </div>
        <div className="">
          <div className={`${styles["table-head"]} rows`}>
            <div className="col-sm-1">
              <input
                type="checkbox"
                className="me-2"
                checked={allSelected}
                onChange={handleSelectAll}
              />
              <span
                onClick={() =>
                  setSort({
                    sort: "_id",
                    order: sort.order === "asc" ? "desc" : "asc",
                  })
                }
              >
                ID
              </span>
            </div>
            <div
              className="col-md-1 col-lg-2"
              onClick={() =>
                setSort({
                  sort: "title",
                  order: sort.order === "asc" ? "desc" : "asc",
                })
              }
            >
              Title
            </div>
            <div className="col-lg-2 col-md-1 px-4">View</div>
            <div className="col-lg-2 col-md-2">Checked By</div>
            <div className="col-sm-1 col-lg-2">Status</div>
            <div className="col-sm-1">Comment</div>
            <div className="col-sm-1">Remind</div>
          </div>

          {showLoader ? (
            <Loader />
          ) : jobs.length === 0 ? (
            <h2 className="text-center mt-5"> No Data Available</h2>
          ) : (
            <div className={styles["table-body"]}>
              {jobs.map((job, index) => (
                <div className={`${styles["table-row"]} row`} key={index}>
                  <div className="col-sm-1" style={{ color: "black" }}>
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={selectedRows.includes(job._id)}
                      onChange={() => handleRowSelection(job._id)}
                    />
                    {job.jobId}
                  </div>
                  <div className="col-sm-1 col-lg-2">{job.title}</div>
                  <div className="col-sm-1 col-lg-2">
                    <JobDescription job={job} />
                  </div>
                  <div className="col-sm-1 col-lg-2 ">
                    {job.updatedBy?.name || "None"}
                  </div>

                  <div className="col-sm-1 col-lg-2">
                    {job.status}{" "}
                    {job.status === "Pending" &&
                      `${job.currentApproval}/${job.maxApproval}`}
                  </div>
                  <div className="col-sm-1" style={{ color: "black" }}>
                    <CommentModal
                      id={job._id}
                      name={job.title}
                      comments={job.comments}
                      onCommentSubmit={handleCommentSubmit}
                    />
                  </div>
                  <div className="col-sm-1 text-end">
                    <button
                      className={styles.reminder}
                      onClick={() => {
                        setDisableReminder(true);
                        sendReminderMail(job._id);
                      }}
                      disabled={disableReminder}
                    >
                      <TbBell color="black" size={25} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <Paginate
          page={page}
          total={total}
          setPage={setPage}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      </div>
      <div className={styles["small-screen"]}>
        <div className="d-flex justify-content-between me-2">
          <input
            className={styles["search-text"]}
            type="text"
            onChange={handleSearch}
            placeholder="Search"
          />

          {selectedRows.length === 1 && (
            <div className="d-flex">
              <BiEditAlt size={30} color="#8692A6" onClick={handleEdit} />
              <MdOutlineDelete
                size={30}
                color="#8692A6"
                onClick={handleDelete}
              />
            </div>
          )}
          {selectedRows.length > 1 && (
            <div>
              <MdOutlineDelete size={30} color="#8692A6" className="me-2" />
            </div>
          )}
        </div>
        {showLoader ? (
          <Loader />
        ) : jobs.length === 0 ? (
          <h2 className="text-center mt-5"> No Job Found</h2>
        ) : (
          <div className="my-3">
            {jobs.map((job, index) => (
              <Card
                key={index}
                className="p-3 my-2"
                style={{ border: "none", borderRadius: "0" }}
              >
                <div className="d-flex justify-content-between">
                  <div className={styles["job-id"]}>
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={selectedRows.includes(job._id)}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={() => {
                        handleRowSelection(job._id);
                      }}
                    />
                    {job.jobId}
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>{job.title}</p>
                  <div className={styles.right}>
                    <button
                      className={styles.reminder}
                      onClick={() => {
                        setDisableReminder(true);
                        sendReminderMail(job._id);
                      }}
                      disabled={disableReminder}
                    >
                      <TbBell color="#2186F2" size={20} />
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Checked by</p>
                  <p className={styles["right"]}>
                    {job.updatedBy?.name || "NONE"}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>View JD</p>
                  <p className={styles["right"]}>
                    <JobDescription job={job} />
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Status</p>
                  <p className={styles["right"]}>
                    {job.status} {job.currentApproval}/{job.maxApproval}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Comments</p>
                  <div className={styles["right"]}>
                    <CommentModal
                      id={job._id}
                      name={job.title}
                      comments={job.comments}
                      onCommentSubmit={handleCommentSubmit}
                    />
                  </div>
                </div>
              </Card>
            ))}
          </div>
        )}
        <Paginate
          page={page}
          total={total}
          setPage={setPage}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      </div>
    </div>
  );
}

export default JobStatus;
