import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "../styles/createJob.module.css";

const serverURL = process.env.REACT_APP_SERVER_URL;

const MultiSelectDropdown = (props) => {
  const [options, setOptions] = useState([
    "Option 1",
    "Option 2",
    "Option 3",
    "o",
    "od",
  ]);
  const [inputValue, setInputValue] = useState("");

  const getAvailableSkills = async () => {
    try {
      const res = await axios.get(`${serverURL}/getCustomizations`);
      setOptions(res.data.skills);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputValue.trim() !== "") {
        if (options.includes(inputValue)) {
          handleOptionClick(inputValue);
        } else {
          setOptions([...options, inputValue]);
          handleOptionClick(inputValue);
        }
      } else {
        setInputValue("");
      }
    }
  };

  const handleOptionClick = (option) => {
    option = option.trim();
    if (option !== "") {
      if (!props.value.selected.includes(option)) {
        props.value.setSelected([...props.value.selected, option]);
        setInputValue("");
      } else {
        props.value.setSelected(
          props.value.selected.filter(
            (selectedOption) => selectedOption !== option
          )
        );
      }
    }
  };

  const handleRemoveSkill = (skill) => {
    props.value.setSelected(
      props.value.selected.filter((selectedOption) => selectedOption !== skill)
    );
  };

  useEffect(() => {
    if (inputValue.length === 0) {
      document.getElementById("options-list").style.display = "none";
    } else {
      document.getElementById("options-list").style.display = "block";
    }
  }, [inputValue]);

  useEffect(() => {
    getAvailableSkills();
  }, []);

  return (
    <div className={styles.relative}>
      <div className={styles["multi-select-dropdown"]}>
        {props.value?.selected?.map((skill, index) => (
          <div key={index} className={styles["skills"]}>
            {skill}
            <button
              className={styles["skills-remover"]}
              onClick={(e) => {
                e.preventDefault();
                handleRemoveSkill(skill);
              }}
            >
              ×
            </button>
          </div>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          className={styles["multi-input"]}
          placeholder="Type and press enter"
          list="options-list"
        />
      </div>

      <div className={styles["option-list"]} id="options-list">
        <div
          className={styles.skillOptions}
          onClick={(e) => {
            handleOptionClick(inputValue);
          }}
        >
          Create new "{inputValue}"
        </div>
        {options
          .filter((option) =>
            option.toLowerCase().includes(inputValue.toLowerCase())
          )
          .map((option) => (
            <div
              className={styles.skillOptions}
              key={option}
              onClick={(e) => {
                handleOptionClick(option);
              }}
            >
              {option}
            </div>
          ))}
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
