import axios from "axios";
import {useState} from "react";
import {useEffect} from "react";
import {Form, Card} from "react-bootstrap";
import {MdOutlineDelete} from "react-icons/md";
import {BiSearch} from "react-icons/bi";
import Loader from "../loader";
import styles from "../../styles/manageEmployers.module.css";
import {confirmAlert} from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateUser from "../createUser";
import EditRecruiter from "../editEmployee";
import Paginate from "../Pagination/pagination";
import Image from "../User/image";

function Employees(props) {
    const [employees,
        setEmployees] = useState([]);
    const [search,
        setSearch] = useState("");
    const [limit,
        setLimit] = useState(10);
    const [page,
        setPage] = useState(1);
    const [total,
        setTotal] = useState(1);
    const [sort,
        setSort] = useState({sort: "_id", order: "desc"});
    const [totalPages,
        setTotalPages] = useState(1);
    const [refresh,
        setRefresh] = useState(false);
    const [selectedRows,
        setSelectedRows] = useState([]);
    const [allSelected,
        setAllSelected] = useState(false);
    const [availableRoles,
        setAvailableRoles] = useState([]);
    const token = localStorage.getItem("SavedToken");
    const [loading,
        setLoading] = useState(true);
    const [maxBadge,
        setMaxBadge] = useState(1);

    const handleRowSelection = (id) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
        } else {
            setSelectedRows([
                ...selectedRows,
                id
            ]);
        }
    };

    const handleSelectAll = () => {
        if (allSelected) {
            setAllSelected(false);
            setSelectedRows([]);
        } else {
            setAllSelected(true);
            setSelectedRows(employees.map((row) => row._id));
        }
    };

    const handleSearch = (e) => {
        const query = e.target.value;
        setSearch(query);
    };

    const handleEdit = () => {
        // code to edit single selected element
    };

    const getEmployeees = async() => {
        const token = localStorage.getItem("SavedToken");
        try {
            const res = await axios({
                method: "get",
                url: `${process.env.REACT_APP_SERVER_URL}/getEmployees?page=${page}&sort=${sort.sort},${sort.order}&search=${search}&limit=${limit}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    authorization: token
                },
                credentials: "include"
            });
            console.log(res);
            setEmployees(res.data.employees);
            setTotalPages(res.data.totalPages);
            setTotal(res.data.total);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const getAvailableRoles = async() => {
        try {
            const res = await axios({
                method: "get",
                url: `${process.env.REACT_APP_SERVER_URL}/get${props.role}Roles`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    authorization: token
                },
                credentials: "include"
            });
            console.log(res);
            if (res.status === 200) {
                setAvailableRoles(res.data.roles);
                setMaxBadge(res.data.maxBadge);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const deleteEmployers = async(eids) => {
        try {
            const res = await axios({
                method: "delete",
                url: `${process.env.REACT_APP_SERVER_URL}/deleteEmployees`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    authorization: token
                },
                data: {
                    userIds: eids
                },
                credentials: "include"
            });
            console.log(res);
            toast.success("User Deleted Successfully!", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            setSelectedRows([]);
            setRefresh(!refresh);
        } catch (err) {
            console.log(err);
        }
    };

    const handleDelete = async() => {
        confirmAlert({
            title: "Confirm Delete",
            message: "Are you sure to delete this user",
            buttons: [
                {
                    style: {
                        backgroundColor: "#2186F2"
                    },
                    label: "Yes",
                    onClick: () => {
                        deleteEmployers(selectedRows);
                    }
                }, {
                    label: "No",
                    onClick: () => console.log("aborted")
                }
            ]
        });
    };

    useEffect(() => {
        getEmployeees();
        getAvailableRoles();
    }, [refresh, page, search, sort, limit]);

    return (
        <div className={styles.table_main_container}>
            <h2 className={styles["manage-employer-head"]}>Manage Employees</h2>
            <p className={styles["manage-employer-text"]}>Manage User</p>
            <div className={styles["large-screen"]}>
                <div className="d-flex justify-content-between mx-3 me-4">
                    {/* <div className={styles["search-box"]}>
            <BiSearch color="#8692A6" />
            <input
              className={styles["search-text"]}
              type="text"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div> */}
                    {/* search box */}
                    <div className={styles["search-box"]}>
                        <BiSearch className={styles.icon_search_modify} color="#8692A6" size="20px"/>
                        <input
                            className={styles["search-text"]}
                            type="text"
                            placeholder="Search for Sites, Tools or Users"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}/>
                    </div>
                    {/* search box */}

                    {/* {selectedRows.length === 0 && (
            <div className="d-flex">
              <Form className="d-flex flex-wrap me-2">
                <div className="ms-3">
                  <Form.Select
                    aria-label="All Status"
                    className={styles["search-filters"]}
                    onChange={handleSearch}
                  >
                    <option value="">All Roles</option>
                    {availableRoles?.map((role, index) => (
                      <option value={role.name} key={index}>
                        {role.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Form>

            </div>
          )} */}
                    {selectedRows.length === 0 && (<CreateUser
                        props={{
                        refresh,
                        setRefresh
                    }}
                        roles={availableRoles}
                        role={props.role}
                        maxBadge={maxBadge}/>)}
                    {selectedRows.length === 1 && (
                        <div className="d-flex me-4 mt-3">
                            <EditRecruiter
                                roles={availableRoles}
                                id={selectedRows[0]}
                                refresh={refresh}
                                setRefresh={setRefresh}/>

                            <MdOutlineDelete size={30} color="#8692A6" onClick={handleDelete}/>
                        </div>
                    )}
                    {selectedRows.length > 1 && (
                        <div className="me-4 mt-3">
                            <MdOutlineDelete
                                size={30}
                                color="#8692A6"
                                className="me-2"
                                onClick={handleDelete}/>
                        </div>
                    )}
                </div>
                <div className="">
                    <div className={`${styles["table-head"]} row`}>
                        <div className="col-sm-1">
                            <input
                                type="checkbox"
                                className="me-2"
                                checked={allSelected}
                                onChange={handleSelectAll}/>
                        </div>
                        <div
                            className="col-sm-1"
                            onClick={() => setSort({
                            sort: "_id",
                            order: sort.order === "asc"
                                ? "desc"
                                : "asc"
                        })}>
                            ID
                        </div>
                        <div
                            className="col-sm-2"
                            onClick={() => setSort({
                            sort: "name",
                            order: sort.order === "asc"
                                ? "desc"
                                : "asc"
                        })}>
                            Name
                        </div>
                        <div
                            className="col-sm-2"
                            onClick={() => setSort({
                            sort: "mobile",
                            order: sort.order === "asc"
                                ? "desc"
                                : "asc"
                        })}>
                            Phone no.
                        </div>
                        <div
                            className="col-sm-3"
                            onClick={() => setSort({
                            sort: "email",
                            order: sort.order === "asc"
                                ? "desc"
                                : "asc"
                        })}>
                            Email
                        </div>
                        <div className="col-sm-2">Role</div>
                    </div>
                    <div className={styles["table-body"]}>
                        {loading
                            ? (<Loader/>)
                            : employees
                                ?.length === 0
                                    ? (
                                        <h1 className="text-center">
                                            No Data Available
                                        </h1>
                                    )
                                    : (employees
                                        ?.map((employer, index) => (
                                            <div className={`${styles["table-row"]} row`} key={index}>
                                                <div className="col-sm-1 justify-content-between">
                                                    <input
                                                        type="checkbox"
                                                        className="me-2"
                                                        checked={selectedRows.includes(employer
                                                        ?._id)}
                                                        onChange={() => handleRowSelection(employer
                                                        ?._id)}/>
                                                    <Image firstName={employer.name} image={employer.image} size={36}/>
                                                </div>
                                                <div
                                                    className="col-sm-1"
                                                    style={{
                                                    color: "black"
                                                }}>
                                                    {employer.userId}
                                                </div>
                                                <div className="col-sm-2">
                                                    {employer.name}
                                                    <p className={styles["employer-name"]}>
                                                        {employer
                                                            ?.companyName}
                                                    </p>
                                                </div>
                                                <div className="col-sm-2">{employer
                                                        ?.mobile}</div>
                                                <div className="col-sm-3">{employer
                                                        ?.email}</div>
                                                <div className="col-sm-2">
                                                    {employer.role
                                                        ?.name}
                                                    {employer.badge && employer.badge}
                                                </div>
                                            </div>
                                        )))}
                        <Paginate
                            page={page}
                            total={total}
                            setPage={setPage}
                            totalPages={totalPages}
                            limit={limit}
                            setLimit={setLimit}/>
                    </div>
                </div>
            </div>
            <div className={styles["small-screen"]}>
                <div className="d-flex justify-content-between me-2">
                    <input
                        className={styles["search-text"]}
                        type="text"
                        onChange={handleSearch}
                        placeholder="Search"/> {selectedRows.length === 0 && (<CreateUser
                        props={{
                        refresh,
                        setRefresh
                    }}
                        roles={availableRoles}
                        role={props.role}/>)}
                    {selectedRows.length === 1 && (
                        <div className="d-flex">
                            <EditRecruiter
                                roles={availableRoles}
                                id={selectedRows[0]}
                                refresh={refresh}
                                setRefresh={setRefresh}/>

                            <MdOutlineDelete size={30} color="#8692A6" onClick={handleDelete}/>
                        </div>
                    )}

                    {selectedRows.length > 1 && (
                        <div className=" mt-3">
                            <MdOutlineDelete size={30} color="#8692A6" className="me-2"/>
                        </div>
                    )}
                </div>
                <div className="mt-2">
                    <Form className="d-flex justify-content-between flex-wrap me-2">
                        <div className="">
                            <Form.Select
                                aria-label="All Status"
                                className={styles["search-filters"]}
                                style={{
                                width: "71vw"
                            }}
                                onChange={handleSearch}>
                                <option value="">All Roles</option>
                                {availableRoles.map((role) => (
                                    <option value={role.name}>{role.name}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </Form>
                </div>
                <div className="my-3">
                    {loading
                        ? (<Loader/>)
                        : (employees
                            ?.map((employee, index) => (
                                <Card
                                    key={index}
                                    className="p-3 my-2"
                                    style={{
                                    border: "none",
                                    borderRadius: "0"
                                }}>
                                    <div className={styles["job-id"]}>
                                        <input
                                            type="checkbox"
                                            className="me-2"
                                            checked={selectedRows.includes(employee._id)}
                                            onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                            onChange={() => {
                                            handleRowSelection(employee._id);
                                        }}/> {employee.userId}
                                    </div>
                                    <div className="d-flex justify-content-between my-1">
                                        <p className={styles["left"]}>{employee.name}</p>
                                        <p className={styles["right"]}>
                                            {employee.role
                                                ?.name}
                                            {employee.badge && employee.badge}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className={styles["left"]}>Phone no.</p>
                                        <p className={styles["right"]}>{employee.mobile}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className={styles["left"]}>Email</p>
                                        <p className={styles["right"]}>{employee.email}</p>
                                    </div>
                                </Card>
                            )))}
                </div>
                <Paginate
                    page={page}
                    total={total}
                    setPage={setPage}
                    totalPages={totalPages}
                    limit={limit}
                    setLimit={setLimit}/>
            </div>
        </div>
    );
}

export default Employees;
