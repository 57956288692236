import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import styles from "../styles/createUser.module.css";
import { Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { BiEditAlt, BiTrash } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditRecruiter(props) {
  console.log(props);
  const availableBadges = Array.from(
    { length: props.maxBadge || 0 },
    (_, index) => index + 1
  );
  const token = localStorage.getItem("SavedToken");
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
    role: "",
    badge: "",
  });
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
    console.log(user, value);
  };

  const id = props.id;

  const getEmployee = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getEmployeeDetails/${id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      const item = res.data;
      item.role = item.role._id;
      item.firstName = item.name.split(" ")[0];
      item.lastName = item.name.split(" ").slice(1).toString();
      setUser(item);
      console.log(item);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getEmployee();
  }, []);

  const postData = async (e) => {
    const { firstName, lastName, email, mobile, role, badge } = user;
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/updateEmployee/${props.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          mobile,
          role,
          badge,
        }),
      }
    );
    const data = await res.json();
    if (res.status === 201) {
      console.log("Successfully Reggistration", data);
      console.log(props.refresh);
      props.setRefresh(!props.refresh);
      toast.success("Updated Successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("Error while updating", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(data.message);
    }
    e.currentTarget.disabled = false;
  };
  return (
    <div>
      <Popup
        trigger={
          <button className={styles["edit-button"]}>
            <BiEditAlt size={30} color="#8692A6" />
          </button>
        }
        modal
        nested
      >
        {(close) => (
          <div className={styles["modal"]}>
            <button className={styles["close"]} onClick={close}>
              &times;
            </button>
            <div className={styles["header"]}> Edit Employee </div>
            <div className={styles["popup-content"]}>
              <Form className="row justify-content-center">
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    size="sm"
                    value={user.role}
                    onChange={handleInputs}
                    name="role"
                  >
                    {props.roles?.map((role) => (
                      <option value={role._id}>{role.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3 col-md-6">
                  <Form.Label className={styles["label"]}>
                    First name
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    value={user.firstName}
                    onChange={handleInputs}
                    placeholder="First Name"
                    name="firstName"
                  />
                </Form.Group>
                <Form.Group className="mb-3 col-md-6">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    size="sm"
                    value={user.lastName}
                    onChange={handleInputs}
                    placeholder="Last name"
                    name="lastName"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    size="sm"
                    type="email"
                    value={user.email}
                    onChange={handleInputs}
                    placeholder="Enter email"
                    name="email"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Mobile No.</Form.Label>
                  <Form.Control
                    size="sm"
                    type="mobile"
                    value={user.mobile}
                    onChange={handleInputs}
                    placeholder="Enter mobile no."
                    name="mobile"
                  />
                </Form.Group>

                <div className="mb-0 d-flex justify-content-center">
                  <Button
                    className={styles["login-button"]}
                    onClick={(e) => {
                      e.preventDefault();
                      e.currentTarget.disabled = true;
                      postData(e);
                      setTimeout(() => {
                        close();
                      }, 2000);
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default EditRecruiter;
