import { Navbar, Container, Form, Button } from "react-bootstrap";

import Dropdown from "react-bootstrap/Dropdown";
import logo from "../images/image.png";
import { BiSearch } from "react-icons/bi";
import { AiFillQuestionCircle, AiOutlineMenu } from "react-icons/ai";
import { BsCalendar } from "react-icons/bs";
import { FaBell } from "react-icons/fa";
import photo from "../images/priyanshu.jpg";
import styles from "../styles/navbar.module.css";
import ResponsiveSideNav from "./responsiveSideNav";
import { Link } from "react-router-dom";

function NavBar(props) {
  console.log(props);
  const logout = () => {
    // console.log("logout caled");
    localStorage.clear();
  };
  return (
    <Navbar className={`${styles["navbar"]}`}>
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex align-items-center">
          <Navbar.Brand href="#" className="my-2 mx-3">
            <img src={logo} alt="logo" className={styles["nav-img"]} />
          </Navbar.Brand>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          {/* <Form className={`d-flex ${styles["search"]}`}>
            <Form.Control
              type="search"
              placeholder="Search by anything"
              className={`mx-0 ${styles["nav-search"]}`}
              aria-label="Search"
              disabled
            />
            <Button variant="outline-success" className={styles["search-btn"]}>
              <BiSearch />
            </Button>
          </Form>

          <BiSearch
            className={`mx-md-3 ${styles["search-icon"]}`}
            color="#2186F2"
          />

          <BsCalendar
            className={`mx-md-3 ${styles["question-icon"]}`}
            color="#2186F2"
          />

          <AiFillQuestionCircle
            className={`mx-md-3 ${styles["question-icon"]}`}
            color="#2186F2"
          />
          <FaBell
            color="#2186F2"
            className={`mx-md-3 ${styles["question-icon"]}`}
          /> */}
          <Dropdown>
            <Dropdown.Toggle
              className={styles["dropdown-toggle"]}
              align={"start"}
            >
              {!props.image || props.image === "" ? (
                <div className={`mx-md-3 ${styles["profile-icon"]}`}>
                  <p className={styles["profile-name"]}>{props.name[0]}</p>
                </div>
              ) : (
                <div className={styles["profile-icon"]}>
                  <img src={props.image} className="img-fluid" />
                </div>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles["dropdown-menu"]}>
              <Dropdown.Item>
                <Link
                  to="/profile"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  My Profile
                </Link>
              </Dropdown.Item>
              <Dropdown.Item href="/login" onClick={logout}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ResponsiveSideNav user={props.role} />
        </div>
      </div>
    </Navbar>
  );
}

export default NavBar;
