import React from "react";
import Popup from "reactjs-popup";
import styles from "../../styles/createUser.module.css";
import { Button, Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";

function AddRecruiter(props) {
  let name, value;
  const token = localStorage.getItem("SavedToken");
  const [selectedRecruiters, setSelectedRecruiters] = useState([]);

  const [error, setError] = useState("");

  const validate = () => {
    let status = false;
    if (selectedRecruiters.length === 0) {
      status = true;
      setError("Please select atleast one recruiter ");
    }
    return status;
  };

  const addRecruiter = async (jid) => {
    try {
      let recruiters = [];
      selectedRecruiters.map((recruiter) => recruiters.push(recruiter.value));
      const res = await axios({
        method: "patch",
        url: `${process.env.REACT_APP_SERVER_URL}/addRecruiter`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        data: {
          job: jid,
          recruiters,
        },
        credentials: "include",
      });
      if (res.status === 200) {
        console.log("Recruiter Added successfully");
        toast.success("Recruiter Added", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.setRefresh(!props.refresh);
      }
    } catch (err) {
      toast.error("You are not authorized!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(err);
    }
  };

  return (
    <div>
      <Popup
        trigger={
          <button className={styles["approve-btn"]}>Add Recruiters</button>
        }
        modal
        nested
      >
        {(close) => (
          <div className={styles["modal"]}>
            <div className={styles.new_container_flex}>
              <div className={styles["header"]}> Add Recruiters</div>
              <button className={styles["close"]} onClick={close}>
                &times;
              </button>
            </div>
            <div className={styles["popup-content"]}>
              <Form className="row justify-content-center">
                <Form.Group className="mb-3">
                  <Form.Label className={styles["label"]}>
                    Recruiters
                  </Form.Label>
                  {props.recruiterOptions?.length > 0 && (
                    <MultiSelect
                      options={props.recruiterOptions}
                      value={selectedRecruiters}
                      onChange={setSelectedRecruiters}
                      labelledBy="Select"
                    />
                  )}
                  {error ? <p style={{ color: "red" }}>{error} </p> : null}
                </Form.Group>
                <div className="mb-0 d-flex justify-content-center">
                  <Button
                    className={styles["login-button"]}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!validate()) {
                        addRecruiter(props.jobId);
                        setTimeout(() => {
                          close();
                        }, 2000);
                      }
                    }}
                  >
                    Add Recruiters
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default AddRecruiter;
