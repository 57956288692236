import styles from "../styles/adminDashboard.module.css";
import { BsSearch } from "react-icons/bs";
import { BiBell, BiSearch } from "react-icons/bi";
import { Row, Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import SplineChart from "./splineChart";
import PieChart from "./pieChart";
import axios from "axios";
import { AiOutlineAim } from "react-icons/ai";
import { ImAlarm } from "react-icons/im";
import Loader from "./loader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

function RecruiterDashboard() {
  const navigate = useNavigate();
  const token = localStorage.getItem("SavedToken");
  const [jobs, setJobs] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [createdJobs, setCreatedJobs] = useState(0);
  const [closedJobs, setClosedJobs] = useState(0);
  const [hired, setHired] = useState(0);
  const [candidateStatuses, setCandidateStatuses] = useState({
    statuses: [],
    data: [],
  });
  const [jobsFrame, setJobFrame] = useState({
    labels: [],
    datasets: [],
  });

  const [interviews, setInterviews] = useState(0);
  const [weeks, setWeeks] = useState({
    createdJobs: "7",
    closedJobs: "7",
    hired: "7",
    interviews: "7",
    spline: "week",
  });
  let name, value;
  const handleWeekChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setWeeks({ ...weeks, [name]: value });
    console.log(weeks);
  };

  const getCandidateStatuses = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/groupRecruiterCandidateswithStatus`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      if (res.status === 200) {
        setCandidateStatuses({
          statuses: res.data.statuses,
          data: res.data.candidates,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCreatedJobs = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/assignedjobs/count/${weeks.createdJobs}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      if (res.status === 200) {
        setCreatedJobs(res.data.createdJobsCount);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getClosedJobs = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/assignedjobs/count/${weeks.closedJobs}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      if (res.status === 200) {
        setClosedJobs(res.data.endedJobsCount);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getHiredCandidates = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/recruitercandidates/hired/${weeks.hired}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      if (res.status === 200) {
        setHired(res.data.hiredCandidatesCount);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getInterviews = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/recruitercandidates/interviewed/${weeks.interviews}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      if (res.status === 200) {
        setInterviews(res.data.interviewedCandidates);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getJobFrames = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/recruiterjobstimeframe/${weeks.spline}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      if (res.status === 200) {
        setJobFrame({
          labels: res.data.labels,
          datasets: res.data.datasets,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getJobs = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/activeJobs`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      if (res.status === 201) {
        setJobs(res.data);
      }
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      console.log(err);
    }
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getCreatedJobs();
  }, [weeks.createdJobs]);

  useEffect(() => {
    getClosedJobs();
  }, [weeks.closedJobs]);

  useEffect(() => {
    getHiredCandidates();
  }, [weeks.hired]);

  useEffect(() => {
    getInterviews();
  }, [weeks.interviews]);

  useEffect(() => {
    getJobFrames();
  }, [weeks.spline]);

  useEffect(() => {
    getCandidateStatuses();
    getJobs();
  }, []);

  return (
    <div className={styles["admin"]}>
      <h1 className={`${styles["dash-head"]}`}>Dashboard</h1>
      <div className="d-flex justify-content-between align-items-center py-2">
        <p className={styles["dash-text"]}>User Activity Summary</p>
        <div className={styles.icons_holder}>
          <div className={styles["dash-icons"]}>
            <BiSearch size={"30px"} />
          </div>
          <div className={styles["dash-icons"]}>
            <BiBell size={"30px"} />
          </div>
        </div>
      </div>
      <Row className="mx-1 me-3 justify-content-between mb-5">
        <Card className={`py-2 ${styles["admin-cards"]}`}>
          <div className="d-flex justify-content-between">
            <h3 className={styles["card-head"]}>{createdJobs}</h3>
            <select
              name="createdJobs"
              id=""
              className={styles["card-right"]}
              onChange={handleWeekChange}
            >
              <option value="7">7 Days</option>
              <option value="30">1 Month</option>
              <option value="365">Last Year</option>
            </select>
          </div>
          <p className={styles["card-text"]}>Jobs assigned</p>
          <p className={styles["card-bottom"]}>
            {weeks.createdJobs === "7"
              ? "This Week"
              : weeks.createdJobs === "30"
              ? "This Month"
              : "Last Year"}
          </p>
        </Card>

        <Card className={`${styles["admin-cards"]} col-md-2 py-2`}>
          <div className="d-flex justify-content-between">
            <h3 className={styles["card-head"]}>{closedJobs}</h3>
            <select
              name="closedJobs"
              id=""
              className={styles["card-right"]}
              onChange={handleWeekChange}
            >
              <option value="7">7 Days</option>
              <option value="30">1 Month</option>
              <option value="365">Last Year</option>
            </select>
          </div>
          <p className={styles["card-text"]}>Closed Jobs</p>
          <p className={styles["card-bottom"]}>
            {weeks.closedJobs === "7"
              ? "This Week"
              : weeks.closedJobs === "30"
              ? "This Month"
              : "Last Year"}
          </p>
        </Card>
        <Card className={`${styles["admin-cards"]} py-2`}>
          <div className="d-flex justify-content-between">
            <h3 className={styles["card-head"]}>{hired}</h3>
            <select
              name="hired"
              id=""
              className={styles["card-right"]}
              onChange={handleWeekChange}
            >
              <option value="7">7 Days</option>
              <option value="30">1 Month</option>
              <option value="365">Last Year</option>
            </select>
          </div>
          <p className={styles["card-text"]}>Candidates hired</p>
          <p className={styles["card-bottom"]}>
            {weeks.hired === "7"
              ? "This Week"
              : weeks.hired === "30"
              ? "This Month"
              : "Last Year"}
          </p>
        </Card>
        <Card className={`${styles["admin-cards"]} col-md-2 py-2`}>
          <div className="d-flex justify-content-between">
            <h3 className={styles["card-head"]}>{interviews}</h3>
            <select
              name="interviews"
              id=""
              className={styles["card-right"]}
              onChange={handleWeekChange}
            >
              <option value="7">7 Days</option>
              <option value="30">1 Month</option>
              <option value="365">Last Year</option>
            </select>
          </div>
          <p className={styles["card-text"]}>Interviews Scheduled</p>
          <p className={styles["card-bottom"]}>This Week</p>
        </Card>
      </Row>

      {/* Row 2 */}

      <Row className="mx-1 mt-3 justify-content-between">
        <div className={styles["pie-chart"]}>
          <PieChart
            statuses={candidateStatuses.statuses}
            data={candidateStatuses.data}
          />
        </div>
        <div className={styles["spline-chart"]}>
          <div className="d-flex justify-content-between px-3 py-2 mb-4">
            <p>Engagement Stats</p>
            <select
              name="spline"
              id="spline"
              className={styles["chart-select"]}
              onChange={handleWeekChange}
            >
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>
          <SplineChart labels={jobsFrame.labels} data={jobsFrame.datasets} />
        </div>
      </Row>

      {/* row 3 */}
      <p className={styles["open-jobs-head"]}>Open Jobs</p>
      <div className={styles["slider"]}>
        <Slider {...settings}>
          {showLoader ? (
            <Loader />
          ) : jobs.length === 0 ? (
            <h1 className="mt-2"> No Jobs</h1>
          ) : (
            jobs.map((job) => (
              <Card
                className={styles["job-card"]}
                onClick={() => {
                  navigate(`/jobCandidates/${job._id}`);
                }}
              >
                <div
                  className={styles["circle"]}
                  style={{
                    backgroundColor:
                      job.priority === "high"
                        ? "red"
                        : job.priority === "low"
                        ? "blue"
                        : "#FFE340",
                  }}
                ></div>
                <p className={styles["job-title"]}>{job.title}</p>
                <p className={styles["job-field"]}>
                  <AiOutlineAim size={20} /> {job.functionalArea}
                </p>
                <p className={styles["job-field"]}>
                  <ImAlarm size={17} />{" "}
                  {parseInt(
                    (new Date(job.endDate) - new Date()) / (24 * 60 * 60 * 1000)
                  ) >= 0
                    ? `${parseInt(
                        (new Date(job.endDate) - new Date()) /
                          (24 * 60 * 60 * 1000)
                      )} days left`
                    : `${Math.abs(
                        parseInt(
                          (new Date(job.endDate) - new Date()) /
                            (24 * 60 * 60 * 1000)
                        )
                      )} days gone`}
                </p>
                <hr className="m-0" />
                <p className={styles["job-recruiters"]}>Recruiters</p>
              </Card>
            ))
          )}
        </Slider>
      </div>
    </div>
  );
}

export default RecruiterDashboard;
