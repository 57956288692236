import axios from "axios";
import { toast } from "react-toastify";

const serverURL = process.env.REACT_APP_SERVER_URL;

export const getRoles = async (token) => {
  try {
    const res = await axios.get(`${serverURL}/getRoles`, {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPermissions = async (token) => {
  try {
    const res = await axios.get(`${serverURL}/getPermissions`, {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createRole = async (name, allowed_permissions, token) => {
  try {
    const res = await axios.post(
      `${serverURL}/createRole`,
      { name, allowed_permissions },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    );
    if (res.status === 201) {
      toast.success("Added Successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return res.data;
    } else {
      toast.error("Error while adding", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    toast.error("Error while adding", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log(error);
  }
};

export const updateRole = async (id, name, allowed_permissions, token) => {
  try {
    const res = await axios.put(
      `${serverURL}/updateRole`,
      { id, name, allowed_permissions },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    );
    if (res.status === 200) {
      toast.success("Updated Successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return res.data;
    } else {
      toast.error("Error while updating", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    toast.error("Error while updating", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log(error);
  }
};

export const deleteRole = async (id, token) => {
  try {
    const res = await axios.delete(`${serverURL}/deleteRole/${id}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
    });
    if (res.status === 200) {
      toast.success("Deleted Successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return res.data;
    } else {
      toast.error("Error while deleting", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    toast.error("Error while deleting", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log(error);
  }
};
