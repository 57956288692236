import React from "react";
import Form from "react-bootstrap/Form";
import styles from "../../styles/login.module.css";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loader";
import { useState } from "react";

function ForgetPassword(props) {
  return (
    <div>
      <h2 className={styles["login-heading"]}>Forgot Password</h2>
      <p className={styles["login-text"]}>
        Enter your email address and we’ll send you an OTP on mail to reset your
        password
      </p>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className={styles["label"]}>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={props.email}
            onChange={(e) => {
              props.setEmail(e.target.value);
            }}
          />
        </Form.Group>

        <button
          className={`${styles["otp-btn"]} px-3`}
          onClick={props.sendOTP}
          disabled={props.disable}
        >
          Send Otp
        </button>
        <button className={`${styles["cancel-btn"]} px-3`}>Cancel</button>
      </Form>
      {props.showLoader && <Loader />}
    </div>
  );
}

export default ForgetPassword;
