import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import styles from "../styles/createUser.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import styles from "../styles/createUser.module.css";

function JobDescription(props) {
  return (
    <div className="d-inline">
      <Popup
        trigger={
          <button className={styles["show-btn2"]}>{props.job.title}</button>
        }
        modal
        nested
      >
        {(close) => (
          <div className={styles["modal"]}>
            <div className={styles.heading_container}>
              <button className={styles["close"]} onClick={close}>
                &times;
              </button>
              <div className={styles["header"]}> Job Description </div>
            </div>
            <div className={`${styles["popup-content"]} mx-1 mt-3`}>
              <div>
                <p className={styles["job-text"]}>
                  <strong> Job title : </strong> {props.job.title}
                </p>
              </div>
              <div>
                <p className={styles["job-text"]}>
                  <strong> Job Description : </strong> {props.job.description}
                </p>
              </div>
              <div>
                <p className={styles["job-text"]}>
                  <strong> Skills required : </strong>{" "}
                  {props.job.skills?.map((skill) => (
                    <span
                      style={{
                        backgroundColor: "silver",
                        margin: "0 5px",
                        padding: "1px 5px",
                        borderRadius: "5px",
                      }}
                    >
                      {skill}{" "}
                    </span>
                  ))}
                </p>
              </div>
              <div>
                <p className={styles["job-text"]}>
                  <strong> Job location : </strong> {props.job.city} ,{" "}
                  {props.job.state}
                </p>
              </div>
              <div>
                <p className={styles["job-text"]}>
                  <strong> Job type : </strong> {props.job.type}
                </p>
              </div>
              <div>
                <p className={styles["job-text"]}>
                  <strong> Candidate preference : </strong>{" "}
                  {props.job.preference}
                </p>
              </div>
              <div>
                <p className={styles["job-text"]}>
                  <strong> End Date : </strong>{" "}
                  {`${new Date(props.job.endDate).getDate()}-${new Date(props.job.endDate).getMonth() + 1
                    }-${new Date(props.job.endDate).getFullYear()}`}
                </p>
              </div>
              <div>
                <p className={styles["job-text"]}>
                  <strong> Priority : </strong> {props.job.priority}
                </p>
              </div>
              <div>
                <p className={styles["job-text"]}>
                  <strong> Minimum experience : </strong> {props.job.experience}{" "}
                  years
                </p>
              </div>
              <div>
                <p className={styles["job-text"]}>
                  <strong> Company Name : </strong> {props.job.companyName}
                </p>
              </div>
              <div>
                <p className={styles["job-text"]}>
                  <strong> Minimum salary : </strong> {props.job.minSalary}
                </p>
              </div>
              <div>
                <p className={styles["job-text"]}>
                  <strong> Maximum salary : </strong> {props.job.maxSalary}
                </p>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default JobDescription;
