import { FiHome, FiPieChart, FiCreditCard } from "react-icons/fi";
import { AiOutlineTrophy, AiOutlineMenu } from "react-icons/ai";
import { BiIdCard } from "react-icons/bi";
import { useState, useEffect, useContext } from "react";
import { Link, useLocation, Outlet } from "react-router-dom";
import { AppContext } from "../components/appContext";

import Popup from "reactjs-popup";
// import "reactjs-popup/dist/index.css";
import styles from "../styles/responsiveSideNav.module.css";
import { TbReportSearch } from "react-icons/tb";

function ResponsiveSideNav(props) {
  const myContext = useContext(AppContext);
  const location = useLocation();
  const [menuItem, setMenuItem] = useState([]);
  const setMenu = () => {
    if (props.user === "superAdmin") {
      setMenuItem([
        {
          path: `/admin`,
          name: "Home",
          icon: <FiHome size={21} />,
        },
        {
          path: "/employers",
          name: "Employers",
          icon: <FiPieChart size={21} />,
        },
        {
          path: "/jobs",
          name: "Jobs",
          icon: <FiCreditCard size={21} />,
        },

        {
          path: "/recruiters",
          name: "Recruiters",
          icon: <AiOutlineTrophy size={21} />,
        },
        {
          path: "/candidates",
          name: "Candidates",
          icon: <BiIdCard size={21} />,
        },
        {
          path: "/masters",
          name: "Masters",
          icon: <BiIdCard size={21} />,
        },
        {
          path: "/roles",
          name: "Roles & Permissions",
          icon: <BiIdCard size={21} />,
        },
        {
          path: "/profile",
          name: "Account",
          icon: <BiIdCard size={21} />,
        },
        {
          path: "/settings",
          name: "Settings",
          icon: <BiIdCard size={21} />,
        },
      ]);
    } else if (props.user === "CompanyAdmin") {
      setMenuItem([
        {
          path: `/admin`,
          name: "Home",
          icon: <FiHome size={21} />,
        },
        {
          path: "/jobs",
          name: "Jobs",
          icon: <FiCreditCard size={21} />,
        },
        {
          path: "/recruiters",
          name: "Recruiters",
          icon: <AiOutlineTrophy size={21} />,
        },
        {
          path: "/candidates",
          name: "Candidates",
          icon: <BiIdCard size={21} />,
        },
        {
          path: "/employees",
          name: "Roles and Permission",
          icon: <FiPieChart size={21} />,
        },
        {
          path: "/job-status",
          name: "Jobs Status",
          icon: <FiCreditCard size={21} />,
        },
        {
          path: `/reports`,
          name: "Reports",
          icon: <TbReportSearch size={21} />,
        },
      ]);
    } else if (props.user === "Employee") {
      setMenuItem([
        {
          path: `/admin`,
          name: "Home",
          icon: <FiHome size={21} />,
        },
        {
          path: "/jobs",
          name: "Jobs",
          icon: <FiCreditCard size={21} />,
        },
      ]);
    } else if (props.user === "RecruiterAdmin") {
      setMenuItem([
        {
          path: `/admin`,
          name: "Home",
          icon: <FiHome size={21} />,
        },
        {
          path: "/jobs",
          name: "Jobs",
          icon: <FiCreditCard size={21} />,
        },

        {
          path: "/candidates",
          name: "Candidates",
          icon: <BiIdCard size={21} />,
        },
        {
          path: "/employees",
          name: "Roles and Permission",
          icon: <FiPieChart size={21} />,
        },
        {
          path: "/account",
          name: "Account",
          icon: <FiPieChart size={21} />,
        },
        {
          path: "/settings",
          name: "Settings",
          icon: <FiPieChart size={21} />,
        },
        {
          path: `/reports`,
          name: "Reports",
          icon: <TbReportSearch size={21} />,
        },
      ]);
    } else if (props.user === "TA") {
      setMenuItem([
        {
          path: `/admin`,
          name: "Home",
          icon: <FiHome size={21} />,
        },
        {
          path: "/jobs",
          name: "Jobs",
          icon: <FiCreditCard size={21} />,
        },

        {
          path: "/candidates",
          name: "Candidates",
          icon: <BiIdCard size={21} />,
        },

        {
          path: "/account",
          name: "Account",
          icon: <FiPieChart size={21} />,
        },
        {
          path: "/settings",
          name: "Settings",
          icon: <FiPieChart size={21} />,
        },
      ]);
    }
    if (props.user === "Candidate") {
      setMenuItem([
        {
          path: `/home`,
          name: "Home",
          icon: <FiHome size={21} />,
        },
        {
          path: "/profile",
          name: "Account",
          icon: <FiPieChart size={21} />,
        },
      ]);
    }
  };

  useEffect(() => {
    setMenu();
  }, [myContext.user]);

  return (
    <div>
      <Popup
        className="sidenav-popup"
        trigger={
          <AiOutlineMenu
            className={`mx-md-3 ${styles["toggle-icon"]}`}
            color="#2186F2"
          />
        }
        modal
        nested
      >
        {(close) => (
          <div className={styles["modal"]}>
            <button className={styles["close"]} onClick={close}>
              &times;
            </button>
            <div id="sidenav" className={styles["sidenav"]}>
              {menuItem.map((item, index) => (
                <Link
                  key={index}
                  to={item.path}
                  className={`${styles["sidepanel-icons"]} ${
                    location.pathname === item.path ? styles["active"] : ""
                  } d-flex align-items-center`}
                >
                  <span>{item.icon}</span>

                  <p className={`${styles["sidepanel-items"]}`}>{item.name}</p>
                </Link>
              ))}
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default ResponsiveSideNav;
