import React from "react";
import styles from "../styles/candidate.module.css";
import { AiFillEye } from "react-icons/ai";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Application from "../components/viewApplication";

const CandidateHome = () => {
  const token = localStorage.getItem("SavedToken");
  const [jobs, setJobs] = useState([]);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [status, setStatus] = useState("active");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getCandidates = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/candidateJobs?status=${status}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },

                credentials: "include",
            });
            console.log("candidate login page -> ", res.data.candidates);
            setJobs(res.data.candidates);
      setActiveCount(res.data.active);
      setInactiveCount(res.data.inactive);
        }
        catch (err) {
            console.log('no data found in the candidates home page')
        }
    };

  useEffect(() => {
    getCandidates();
  }, [status]);

  return (
    <>
      <div className={styles.main_container}>
        <div className={styles.title_container}>
          <div className={styles.title}>Candidate</div>
          <div className={styles.subtitle_container}>
            <div
              className={
                status === "active" ? styles.sub_title2 : styles.sub_title
              }
              onClick={() => setStatus("active")}
            >
              Active ({activeCount})
            </div>
            <div
              className={
                status === "inactive" ? styles.sub_title2 : styles.sub_title
              }
              onClick={() => setStatus("inactive")}
            >
              Inactive ({inactiveCount})
            </div>
          </div>
        </div>

        {/* heading */}
        <div className={styles.heading_container}>
          <div className={styles.job_title}>Job Title</div>
          <div className={styles.job_req}>Job Req</div>
          <div className={styles.job_status}>Status</div>
          <div className={styles.job_date_submit}>Date Submitted</div>
          <div className={styles.job_view}>View</div>
        </div>

        {/* candidates details */}
        {jobs.map((job, index) => (
          <div key={index} className={styles.detail_container}>
            <div className={styles.job_title_mobile}>
              <span className={styles.mobile_view_cont}>Job Title :</span>{" "}
              <strong> {job.job?.title}</strong>
            </div>
            <div className={styles.job_req_mobile}>
              <span className={styles.mobile_view_cont}>Job Ref :</span>{" "}
              <strong>{job.reqId ? job.reqId : 21478}</strong>
            </div>
            <div className={styles.job_status_mobile}>
              <span className={styles.mobile_view_cont}>Job Status : </span>
              <div
                className={
                  status === "active"
                    ? styles.job_status_detail_mobile
                    : styles.job_status_detail_mobile2
                }
              >
                <strong>
                  {status === "active" ? "In Process" : "Rejected"}
                </strong>
              </div>
            </div>
            <div className={styles.job_date_submit_mobile}>
              <span className={styles.mobile_view_cont}>Date Submitted : </span>{" "}
              <strong>{`${months[new Date(job.date).getMonth()]} ${new Date(
                job.date
              ).getDate()}, ${new Date(job.date).getFullYear()} `}</strong>
            </div>
            <div className={styles.job_view_mobile}>
              <span className={styles.mobile_view_cont}>View : </span>
              <Application candidates={job.candidate} job={job.job} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CandidateHome;
