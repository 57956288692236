import { FiHome, FiPieChart, FiCreditCard } from "react-icons/fi";
import { AiOutlineTrophy, AiOutlineMenu } from "react-icons/ai";
import { BiIdCard } from "react-icons/bi";
import { useState, useEffect } from "react";
import styles from "../styles/sidenav.module.css";
import { Link, useLocation, Outlet } from "react-router-dom";
import { TbReportSearch } from "react-icons/tb";
import logo from '../images/logo.svg'
import { MdHomeFilled, MdManageAccounts } from "react-icons/md";
import { GiDiamonds } from 'react-icons/gi'
import { AiFillStar, AiFillCopy } from 'react-icons/ai'
import { IoIosSettings } from 'react-icons/io'
import { IoDocumentLockOutline } from 'react-icons/io5'
import { GiPapers } from 'react-icons/gi'
import { BsAwardFill, BsFillBagDashFill, BsHddRackFill } from 'react-icons/bs'
import { FaCrown, FaStopCircle } from "react-icons/fa";
import { RiFolderReceivedFill } from 'react-icons/ri'
import { HiHome } from 'react-icons/hi'
import { AiFillFund } from 'react-icons/ai';
import { toast } from "react-toastify";
import axios from "axios";

function SideNav(props) {
  const location = useLocation();
  const [menuItem,
    setMenuItem] = useState([]);

  const [companyName, setCompanyName] = useState("")

  console.log("props", props)

  const handleRedirect = () => {
    navigator
      .clipboard
      .writeText(`${process.env.REACT_APP_CLIENT_URL}/companyBoard/${companyName}`)
      .then(() => {
        toast.success("Link Copied!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      });
  };

  const getCompanyName = async () => {
    const token = localStorage.getItem("SavedToken");
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getUser`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token
        },
        credentials: "include"
      });
      console.log("ress", res?.data);
      // setEmployees(res.data.employees);
      // setTotalPages(res.data.totalPages);
      // setTotal(res.data.total);
      // setLoading(false);
      setCompanyName(res?.data?.company?.companyName)
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getCompanyName()
  }, [])

  const setMenu = () => {
    if (props.user === "superAdmin") {
      setMenuItem([
        {
          path: `/admin`,
          name: "Home",
          icon: <HiHome size={24} />
        }, {
          path: "/employers",
          name: "Employers",
          icon: <BsFillBagDashFill size={22} />
        }, {
          path: "/jobs",
          name: "Jobs",
          icon: <AiFillStar size={24} />
        }, {
          path: "/recruiters",
          name: "Recruiters",
          icon: <FaStopCircle size={24} />
        }, {
          path: "/candidates",
          name: "Candidates",
          icon: <BsAwardFill size={24} />
        }, {
          path: "/masters",
          name: "Masters",
          icon: <FaCrown size={24} />
        }, {
          path: "/roles",
          name: "Roles & Permissions",
          icon: <RiFolderReceivedFill size={24} />
        }, {
          path: "/profile",
          name: "Account",
          icon: <MdManageAccounts size={29} />
        }, {
          path: "/settings",
          name: "Settings",
          icon: <IoIosSettings size={29} />
        }
      ]);
    } else if (props.user === "CompanyAdmin") {
      setMenuItem([
        {
          path: `/admin`,
          name: "Home",
          icon: <HiHome size={24} />
        }, {
          path: "/jobs",
          name: "Jobs",
          icon: <AiFillStar size={24} />
        }, {
          path: "/recruiters",
          name: "Recruiters",
          icon: <FaStopCircle size={24} />
        }, {
          path: "/candidates",
          name: "Candidates",
          icon: <BsAwardFill size={24} />
        }, {
          path: "/employees",
          name: "Roles and Permission",
          icon: <RiFolderReceivedFill size={24} />
        }, {
          path: "/job-status",
          name: "Jobs Status",
          icon: <AiFillFund size={24} />
        }, {
          path: `/reports`,
          name: "Reports",
          icon: <GiPapers size={24} />
        },

        // const handleShare = () => {   navigator.clipboard     .writeText(
        // `${process.env.REACT_APP_CLIENT_URL}/onBoardCandidate/${jid}/${recruiter._id}
        // `     )     .then(() => {       toast.success("Link Copied!", { position:
        // "top-center",         autoClose: 3000,         hideProgressBar: false,
        //  closeOnClick: true,         pauseOnHover: false, draggable: true,
        // progress: undefined,         theme: "light", });     }); };

        {
          path: `/board_copy_link`,
          name: "Board Link",
          icon: <AiFillCopy size={24} />
        }
      ]);
    } else if (props.user === "Employee") {
      setMenuItem([
        {
          path: `/admin`,
          name: "Home",
          icon: <HiHome size={24} />
        }, {
          path: "/jobs",
          name: "Jobs",
          icon: <AiFillStar size={24} />
        }, 
        {
          path: "/companyBoard",
          name: "Company",
          icon: <BsAwardFill size={24} />
        }

        // {   path: "/recruiters",   name: "Recruiters",   icon: <AiOutlineTrophy
        // size={24} />, }, {   path: "/candidates",   name: "Candidates",   icon:
        // <BiIdCard size={24} />, },
      ]);
    } else if (props.user === "RecruiterAdmin") {
      setMenuItem([
        {
          path: `/admin`,
          name: "Home",
          icon: <HiHome size={24} />
        }, {
          path: "/jobs",
          name: "Jobs",
          icon: <AiFillStar size={24} />
        }, {
          path: "/candidates",
          name: "Candidates",
          icon: <BsAwardFill size={24} />
        }, {
          path: "/employees",
          name: "Roles and Permission",
          icon: <RiFolderReceivedFill size={24} />
        }, {
          path: "/profile",
          name: "Account",
          icon: <MdManageAccounts size={24} />
        }, {
          path: "/settings",
          name: "Settings",
          icon: <IoIosSettings size={24} />
        }, {
          path: `/reports`,
          name: "Reports",
          icon: <GiPapers size={24} />
        }
      ]);
    } else if (props.user === "TA") {
      setMenuItem([
        {
          path: `/admin`,
          name: "Home",
          icon: <HiHome size={24} />
        }, {
          path: "/jobs",
          name: "Jobs",
          icon: <FiCreditCard size={24} />
        }, {
          path: "/candidates",
          name: "Candidates",
          icon: <BiIdCard size={24} />
        }, {
          path: "/account",
          name: "Account",
          icon: <MdManageAccounts size={24} />
        }, {
          path: "/settings",
          name: "Settings",
          icon: <IoIosSettings size={24} />
        }
      ]);
    }
    if (props.user === "Candidate") {
      setMenuItem([
        {
          path: `/home`,
          name: "Home",
          icon: <HiHome size={24} />
        }, {
          path: "/profile",
          name: "Account",
          icon: <MdManageAccounts size={24} />
        }
      ]);
    }
  };

  useEffect(() => {
    setMenu();
  }, []);

  return (
    <div id="sidenav" className={styles["sidenav"]}>
      <div className={styles.sidenavbar_logo}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.sidenavbar_menus}>
        {menuItem.map((item, index) => (
          <Link
            key={index}
            to={item.name !== "Board Link"
              ? item.path
              : null}
            className={`${styles["sidepanel-icons"]} ${location.pathname === item.path
              ? styles["active"]
              : ""} d-flex align-items-center`}>
            <span>{item.icon}</span>
            {item.name === "Board Link"
              ? <p className={`${styles["sidepanel-items"]}`} onClick={handleRedirect}>{item.name}</p>
              : <p className={`${styles["sidepanel-items"]}`}>{item.name}</p>}
            {/* <p className={`${styles["sidepanel-items"]}`}>{item.name}</p> */}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default SideNav;
