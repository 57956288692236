import { ThreeDots } from "react-loader-spinner";

function Loader() {
  return (
    <div className="d-flex justify-content-center mt-5">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#2186F2"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
}

export default Loader;
