import React from "react";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import styles from "../../styles/login.module.css";
import { AiFillEye } from "react-icons/ai";
import { useState, useContext } from "react";
import { AppContext } from "../appContext";
import frame from "../../images/Frame.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubLogin from "./subLogin";
import ForgetPassword from "./forget";
import OTP from "./otp";
import ResetPassword from "./resetPassword";

function Login(props) {
  const [passwordType, setPasswordType] = useState("password");
  const [showLoader, setShowLoader] = useState(false);
  const [remember, setRemember] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState("login");
  const [newPassword, setNewPassword] = useState("");
  const [disable, setDisable] = useState(false);
  const myContext = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const submitLogin = async (e) => {
    setShowLoader(true);
    setDisable(true);
    toast.dismiss();
    e.preventDefault();
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_URL}/login`,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email,
          password,
          remember,
        },
      });
      if (res.status === 201) {
        const token = res.data.token;
        localStorage.setItem("SavedToken", "bearer " + token);
        myContext.setUser(res.data);
        window.location.reload(false);
        setDisable(false);
      } else {
        setError(true);
        setDisable(false);
      }
      setShowLoader(false);
      setDisable(false);
    } catch (err) {
      setShowLoader(false);
      setError(true);
      setDisable(false);
    }
  };

  const sendOTP = async (e) => {
    setShowLoader(true);
    setDisable(true);
    toast.dismiss();
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_URL}/sendOTP`,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email,
        },
      });
      if (res.status === 200) {
        toast.success("Otp Sent Successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisable(false);
        setPage("otp");
      } else if (res.status === 404) {
        setTimeout(() => {
          setDisable(false);
        }, 3000);
        console.log("not working");
        toast.error("Email not registered", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setShowLoader(false);
    } catch (err) {
      setError(true);
      setShowLoader(false);
    }
  };

  const submitOTP = async (e) => {
    setShowLoader(true);
    setDisable(true);
    toast.dismiss();
    e.preventDefault();
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_URL}/matchOTP`,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email,
          otp,
        },
      });
      if (res.status === 200) {
        toast.success("Otp Verified Successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisable(false);
        setPage("reset");
      } else if (res.status === 404) {
        setTimeout(() => {
          setDisable(false);
        }, 3000);
        console.log("not working");
        toast.error("Invalid Otp", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (res.status === 498) {
        setTimeout(() => {
          setDisable(false);
        }, 3000);
        console.log("not working");
        toast.error("OTP Expired", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setShowLoader(false);
    } catch (err) {
      setTimeout(() => {
        setDisable(false);
      }, 3000);
      toast.error("Invalid OTP", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowLoader(false);
    }
  };

  const resetPassword = async (e) => {
    setShowLoader(true);
    setDisable(true);
    toast.dismiss();
    e.preventDefault();
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_URL}/resetPassword`,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email,
          otp,
          password: newPassword,
        },
      });
      if (res.status === 200) {
        toast.success("Password Changed Successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisable(false);
        setEmail("");
        setPassword("");
        setPage("login");
      }
      if (res.status === 204) {
        toast.error("Your password must be difffferent from previous one!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisable(false);
        setPage("reset");
      }
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
    }
  };

  const togglePassword = async (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <Row style={{ height: "100vh", margin: "0" }}>
      <Col md="7" sm="5" className={styles["login-page"]}>
        <img src={frame} alt="" className="" />
      </Col>
      <Col md="5" sm="7" className={`${styles["login-form"]} px-5`}>
        {page === "login" ? (
          <SubLogin
            email={email}
            showLoader={showLoader}
            setEmail={setEmail}
            passwordType={passwordType}
            password={password}
            setPassword={setPassword}
            togglePassword={togglePassword}
            setDisable={setDisable}
            disable={disable}
            submitLogin={submitLogin}
            remember={remember}
            setRemember={setRemember}
            setPage={setPage}
            error={error}
          />
        ) : page === "forget" ? (
          <ForgetPassword
            email={email}
            setEmail={setEmail}
            setDisable={setDisable}
            disable={disable}
            setPage={setPage}
            sendOTP={sendOTP}
            showLoader={showLoader}
          />
        ) : page === "otp" ? (
          <OTP
            email={email}
            showLoader={showLoader}
            setEmail={setEmail}
            setDisable={setDisable}
            disable={disable}
            setPage={setPage}
            otp={otp}
            setOtp={setOtp}
            sendOTP={sendOTP}
            submitOTP={submitOTP}
          />
        ) : (
          <ResetPassword
            showLoader={showLoader}
            setDisable={setDisable}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            disable={disable}
            setPage={setPage}
            passwordType={passwordType}
            togglePassword={togglePassword}
            resetPassword={resetPassword}
          />
        )}
      </Col>
    </Row>
  );
}

export default Login;
