import NavBar from "../components/navBar";
import SideNav from "../components/sideNav";
import { Outlet } from "react-router-dom";
import styles from "../styles/adminDashboard.module.css";
function EmployerHome(props) {
  return (
    <div className={styles["parent-page"]}>
      <NavBar />
      <SideNav user={props.role} />
      <div className={styles["dashboard"]}>
        <Outlet />
      </div>
    </div>
  );
}

export default EmployerHome;
