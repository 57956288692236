import React from "react";
import styles from "../styles/maintainence.module.css";

function MaintainencePage() {
  return (
    <div className={styles["maintainance-page"]}>
      <h1 className="mt-5 text-center">Page Under Construction</h1>
    </div>
  );
}

export default MaintainencePage;
