import React from "react";
import Form from "react-bootstrap/Form";
import styles from "../../styles/login.module.css";
import OtpInput from "react-otp-input";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loader";
import { useState } from "react";
import { useEffect } from "react";

function OTP(props) {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [otpdisable, setOtpDisable] = useState(true);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          setOtpDisable(false);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  return (
    <div>
      <h2 className={styles["login-heading"]}>Enter OTP</h2>
      <p className={styles["login-text"]}>
        We have mailed you an OTP on your email address.
      </p>
      <OtpInput
        value={props.otp}
        onChange={props.setOtp}
        numInputs={6}
        renderSeparator={<span> </span>}
        renderInput={(props) => <input {...props} />}
        inputStyle={{ width: "30px", backgroundColor: "#F4F4F4" }}
        containerStyle={{
          width: "90%",
          display: "flex",
          justifyContent: "space-between",
        }}
      />
      <div className={styles.resendOTP}>
        <p>
          Time Remaining: {minutes}:{seconds}
        </p>

        <button
          className={styles.resend}
          style={{ color: otpdisable ? "silver" : "#2186F2" }}
          onClick={(e) => {
            e.preventDefault();
            props.sendOTP();
            setSeconds(59);
            setOtpDisable(true);
          }}
          disabled={otpdisable}
        >
          Resend OTP
        </button>
      </div>
      <button
        className={`${styles["otp-btn"]} px-3`}
        onClick={props.submitOTP}
        disabled={props.disable}
      >
        Submit OTP
      </button>
      <button
        className={`${styles["cancel-btn"]} px-3`}
        onClick={() => {
          window.location.reload(false);
        }}
      >
        Cancel
      </button>
      {props.showLoader && <Loader />}
    </div>
  );
}

export default OTP;
