import NavBar from "../components/navBar";
import SideNav from "../components/sideNav";
import { Outlet, useLocation } from "react-router-dom";
import styles from "../styles/adminDashboard.module.css";
import { AppContext } from "../components/appContext";
import { useContext, useEffect, useState } from "react";
import EmployerDashboard from "../components/employerDashboard";
import SuperAdminDashboard from "../components/Dashboard/superAdmin";
import RecruiterDashboard from "../components/recruiterDashboard";
import CandidateHome from "./candidateHome";


function AdminHome(props) {
  const myContext = useContext(AppContext);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  return (
    <div className={styles["parent-page"]}>
      <NavBar name={props.name} role={props.role} image={props.image} />
      <SideNav user={props.role} />

      <div className={styles["dashboard"]}>
        {currentPath === "/" ? (
          props.role === "superAdmin" ? (
            <SuperAdminDashboard />
          ) : props.role === "CompanyAdmin" || props.role === "Employee" ? (
            <EmployerDashboard />
          ) : props.role === "RecruiterAdmin" || props.role === "TA" ? (
            <RecruiterDashboard />
          ) : (
            <CandidateHome />
          )
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
}

export default AdminHome;
