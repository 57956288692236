import { Card } from "react-bootstrap";
import { HiDownload } from "react-icons/hi";
import styles from "./styles.module.css";
import Image from "../User/image";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

function ImageCard(props) {
  return (
    <Card className="p-2">
      <div className="d-flex justify-content-end">
        <a className={styles.resume} href={props.candidate.resume}>
          <HiDownload size={20} color="white" />
        </a>
      </div>
      <div className="m-auto">
        <Image firstName={props.candidate?.name} size={180} />
      </div>
      <p className={styles.candidateName}>{props.candidate.name}</p>
      <p className={styles.candidateProfile}>
        {props.candidate.desiredJobtitle}
      </p>
      <p className={styles.mobile}>
        {" "}
        <BsFillTelephoneFill /> Tel: {props.candidate.mobile}{" "}
      </p>
      <p className={styles.mobile}>
        <MdEmail />
        Email: {props.candidate.email}
      </p>
    </Card>
  );
}

export default ImageCard;
