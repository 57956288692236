import { Card } from "react-bootstrap";
import { MdMessage } from "react-icons/md";
import styles from "./styles.module.css";
import { FaUserGraduate } from "react-icons/fa";
import { BiRupee } from "react-icons/bi";
import { AiFillHome } from "react-icons/ai";

function DetailsCard(props) {
  return (
    <Card className="p-3 py-4">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className={styles.name}>{props.candidate?.name}</h2>
        <MdMessage size={26} color="grey" />
      </div>
      <div className="d-flex justify-content-between mt-5 align-items-center">
        <p className={styles.details}>
          <FaUserGraduate color="grey" /> {props.candidate?.experience} years
        </p>
        <p className={styles.details}>
          <BiRupee color="grey" /> {props.candidate?.desiredJobexpectedCTC}
        </p>
        <p className={styles.details}>
          <AiFillHome color="grey" /> {props.candidate?.city}
        </p>
      </div>
      <p className={styles.current}>
        <strong> Current:</strong>{" "}
        {props.company?.company ? props.company?.company : "None"}
      </p>
      <p className={styles.current}>
        <strong> Key Skills:</strong>{" "}
        {props.candidate.skills?.map((skill) => (
          <span className={styles.skillbox}> {skill}</span>
        ))}
      </p>
      <span className={styles.status}>{props.status}</span>
      {/* TO BE COMPLETED */}
    </Card>
  );
}

export default DetailsCard;
