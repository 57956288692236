import React from "react";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import styles from "../../styles/login.module.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { useState, useContext } from "react";
import frame from "../../images/Frame.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";

function SubLogin(props) {
  const [show, setShow] = useState(false);
  const handleClickEyeToggle = (e) => {
    e.preventDefault();
    props.togglePassword(e);
    setShow(!show);
  };

  return (
    <div>
      <h2 className={styles["login-heading"]}>Account login</h2>
      <p className={styles["login-text"]}>
        Login with your official email address and password
      </p>

      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className={styles["label"]}>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={props.email}
            className={styles["email_field"]}
            onChange={(e) => {
              props.setEmail(e.target.value);
            }}
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label className={styles["label"]}>Password </Form.Label>
          <div className="d-flex border rounded">
            <Form.Control
              type={props.passwordType}
              className={styles["password"]}
              placeholder="Password"
              value={props.password}
              onChange={(e) => {
                console.log(props.remember);
                props.setPassword(e.target.value);
              }}
            />
            <span
              className={styles["password-toogle"]}
              onClick={handleClickEyeToggle}
            >
              {show ? <AiFillEyeInvisible /> : <FiEye />}
            </span>
          </div>
          {props?.error ? (
            <span style={{ fontSize: "13px", color: "red" }}>
              Wrong Email or password{" "}
            </span>
          ) : (
            ""
          )}
        </Form.Group>

        <div className={styles.forget_and_remember_me}>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              className={styles.remember_me}
              label="Remember me"
              checked={props.remember}
              onClick={() => {
                props.setRemember(!props.remember);
              }}
            />
          </Form.Group>

          <a
            href="/login"
            onClick={(e) => {
              e.preventDefault();
              props.setPage("forget");
            }}
            className={`${styles["forgot-password"]}`}
          >
            Forget Password?
          </a>
        </div>

        <button
          className={`${styles["login-btn"]} px-3`}
          onClick={props.submitLogin}
          disabled={props.disable}
        >
          {props.showLoader ? (
            <RotatingLines
              strokeColor="white"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          ) : (
            "Login"
          )}
        </button>
      </Form>
    </div>
  );
}

export default SubLogin;
