import { Card, Form } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import { AppContext } from "../components/appContext";
import { Link } from "react-router-dom";
import axios from "axios";
import CreateUser from "../components/createUser";
import EditRecruiter from "../components/editEmployee";
import { BiSearch, BiEditAlt } from "react-icons/bi";
import { MdOutlineDelete } from "react-icons/md";
import Loader from "../components/loader";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../styles/manageEmployers.module.css";
import Paginate from "../components/Pagination/pagination";
import Switch from "react-switch";

function Recruiters() {
  const [recruiters, setRecruiters] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [active, setActive] = useState("All");
  const [totalPages, setTotalPages] = useState(1);
  const [showLoader, setShowLoader] = useState(true);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState({ sort: "_id", order: "desc" });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const token = localStorage.getItem("SavedToken");
  const [allSelected, setAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const myContext = useContext(AppContext);
  const handleRowSelection = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setAllSelected(false);
      setSelectedRows([]);
    } else {
      setAllSelected(true);
      setSelectedRows(recruiters.map((row) => row.userId));
    }
  };

  const getRecruiters = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getRecruiters?page=${page}&sort=${sort.sort},${sort.order}&search=${search}&active=1&limit=${limit}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      setShowLoader(false);
      setRecruiters(res.data.employers);
      setTotalPages(res.data.totalPages);
      setTotal(res.data.totalEmployers);
    } catch (err) {
      console.log(err);
    }
  };

  const handleToogle = async (rid) => {
    try {
      toast.dismiss();
      const res = await axios({
        method: "patch",
        url: `${process.env.REACT_APP_SERVER_URL}/toogleRecruiter/${rid}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      if (res.status === 201) {
        console.log("Updated successfully");
        setRefresh(!refresh);
      } else {
        toast.error("You are not authorized", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteRecruiters = async (rows) => {
    try {
      const res = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_SERVER_URL}/deleteRecruiters`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        data: {
          userIds: rows,
        },
        credentials: "include",
      });
      console.log(res);
      toast.success("🦄 User Deleted Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setRefresh(!refresh);
      setSelectedRows([]);
    } catch (err) {
      toast.error("🦄 Error while deleting", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(err);
    }
  };
  const handleDelete = async () => {
    // console.log(e);
    // console.log("called for", eid);
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this user",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log(selectedRows);
            deleteRecruiters(selectedRows);
          },
        },
        {
          label: "No",
          onClick: () => console.log("aborted"),
        },
      ],
    });
  };

  useEffect(() => {
    getRecruiters();
  }, [refresh, search, active, page, limit]);

  return (
    <div className={styles.table_main_container}>
      <h2 className={styles["manage-employer-head"]}>Manage Recruiters</h2>
      <p className={styles["manage-employer-text"]}>
        List of available Recruiters
      </p>
      <div className={styles["large-screen"]}>
        <div className="d-flex justify-content-between mx-3 me-4">
          {/* <div className={styles["search-box"]}>
            <BiSearch color="#8692A6" />
            <input
              className={styles["search-text"]}
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div> */}

          {/* search box */}
          <div className={styles["search-box"]}>
            <BiSearch
              className={styles.icon_search_modify}
              color="#8692A6"
              size="20px"
            />
            <input
              className={styles["search-text"]}
              type="text"
              placeholder="Search for Sites, Tools or Users"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <Link to={`/onboardRecruiter`}>
            <button className={styles["add-btn"]}>+</button>
          </Link>
          {/* search box */}

          {/* add button add in plus */}
          {/* {selectedRows.length === 0 && (
            <div className="d-flex">
              <Form className="d-flex flex-wrap me-2">
                <div className="ms-3">
                  <Form.Select
                    aria-label="All Status"
                    className={styles["search-filters"]}
                    value={active}
                    onChange={(e) => setActive(e.target.value)}
                  >
                    <option value="All">All Status</option>
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </Form.Select>
                </div>
              </Form>
              
            </div>
          )} */}
        </div>
        <div className="">
          <div className={`${styles["table-head"]} row`}>
            <div className="col-sm-1">ID</div>
            <div className="col-sm-3">Name</div>
            <div className="col-sm-2">Phone no.</div>
            <div className="col-sm-3">Email</div>
            <div className="col-sm-1">Status</div>
          </div>
          <div className={styles["table-body"]}>
            {showLoader ? (
              <Loader />
            ) : recruiters.length === 0 ? (
              <h2 className="text-center">No Data Found</h2>
            ) : (
              recruiters?.map((recruiter, index) => (
                <div className={`${styles["table-row"]} row`} key={index}>
                  <div className="col-sm-1" style={{ color: "black" }}>
                    {recruiter.companyId}
                  </div>
                  <div className="col-sm-3">{recruiter.name}</div>
                  <div className="col-sm-2">{recruiter.phoneNo}</div>
                  <div className="col-sm-3">{recruiter.email}</div>
                  <Form className="col-sm-1">
                    {/* <Form.Check
                      type="switch"
                      id="custom-switch"
                      value={new Date(recruiter.expiryDate) > new Date()}
                      onChange={() => handleToogle(recruiter._id)}
                      checked={new Date(recruiter.expiryDate) > new Date()}
                    /> */}

                    <label htmlFor="material-switch">
                      <Switch
                        value={new Date(recruiter.expiryDate) > new Date()}
                        onChange={() => handleToogle(recruiter._id)}
                        checked={new Date(recruiter.expiryDate) > new Date()}
                        onClick={(e) => e.stopPropagation()}
                        onColor="#E4CFFF"
                        onHandleColor="#2186F2"
                        handleDiameter={18}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={12.23}
                        width={30}
                        className="react-switch"
                        id="material-switch"
                      />
                    </label>
                  </Form>
                </div>
              ))
            )}
          </div>
          <Paginate
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            total={total}
          />
        </div>
      </div>
      <div className={styles["small-screen"]}>
        <div className="d-flex justify-content-between me-2">
          <div>
            <input
              className={styles["search-text"]}
              type="text"
              style={{ width: "95vw" }}
              placeholder="Search here"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
        {
          <div className="my-3">
            {showLoader ? (
              <Loader />
            ) : recruiters.length === 0 ? (
              <h2 className="text-center">No Data Found</h2>
            ) : (
              recruiters?.map((recruiter, index) => (
                <Card
                  key={index}
                  className="p-3 my-2"
                  style={{ border: "none", borderRadius: "0" }}
                >
                  <div className="d-flex justify-content-between mb-1">
                    <p className={styles["left"]}>{recruiter.name}</p>
                    <div className={styles["job-id"]}>
                      {recruiter.companyId}
                    </div>

                    {/* <Form className="col-sm-1 text-end">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    value={recruiter.isActive}
                    onChange={(e) => {
                      e.cancelBubble = true;
                      handleToogle(recruiter._id);
                    }}
                    checked={recruiter.isActive}
                  />
                </Form> */}
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className={styles["left"]}>Phone no.</p>
                    <p className={styles["right"]}>{recruiter.phoneNo}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className={styles["left"]}>Email</p>
                    <p className={styles["right"]}>{recruiter.email}</p>
                  </div>
                </Card>
              ))
            )}
          </div>
        }

        <Paginate
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          total={total}
        />
      </div>
    </div>
  );
}

export default Recruiters;
