import React, { useEffect } from "react";
import { useState } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import styles from "../styles/adminDashboard.module.css";

const SplineChart = (props) => {
  const data = {
    labels: props.labels,
    datasets: [
      {
        label: "Jobs Posted",
        data: props.data,
        backgroundColor: "#4E36E2",
        borderColor: "#4E36E2",
        borderWidth: 3,
        tension: 0.4,
      },
    ],
  };

  useEffect(() => {}, []);

  return (
    <div className={styles["chart-container"]}>
      <Line
        data={data}
        options={{
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                fontColor: "#000",
                usePointStyle: true,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default SplineChart;
