import styles from './Company.module.css';
import { BsBagDash } from 'react-icons/bs';
import { IoLocationOutline } from 'react-icons/io5';
import { BiRupee } from 'react-icons/bi';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import loaderImage from './loader.gif';

const CompanyScreen = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { companyName } = params;
  const [company, setCompany] = useState({});
  const [openings, setOpenings] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedExperience, setSelectedExperience] = useState("");
  const [filteredOpenings, setFilteredOpenings] = useState([]);
  const [loading, setLoading] = useState(true)
  const [uniqueDepartments, setUniqueDepartments] = useState(new Set());


  const fetchData = async () => {
    await axios.post(`${process.env.REACT_APP_SERVER_URL}/getCompany`, { data: companyName }).then((res) => {
      setCompany(res?.data?.company);
      setOpenings(res?.data?.jobs);
      setLoading(false)
    })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setCompany(null);
        setOpenings([]);
      })
  };

  const handleRedirect = (jobId) => {
    navigate(`/onBoardCandidate/${jobId}/undefined`);
  };

  const calculateDaysAgo = (postedDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const currentDate = new Date();
    const posted = new Date(postedDate);
    const timeDiff = Math.abs(currentDate.getTime() - posted.getTime());
    const daysAgo = Math.floor(timeDiff / oneDay);
    return daysAgo;
  };

  const handleDepartmentFilter = (department) => {
    setSelectedDepartment(department);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let filteredJobs = openings;

    if (selectedDepartment) {
      filteredJobs = filteredJobs.filter((job) => job.functionalArea === selectedDepartment);
    }
    if (selectedLocation) {
      filteredJobs = filteredJobs.filter((job) => job.city === selectedLocation);
    }
    if (selectedExperience) {
      filteredJobs = filteredJobs.filter((job) => job.experience === selectedExperience);
    }

    setFilteredOpenings(filteredJobs);

    const departments = new Set(openings.map((job) => job.functionalArea));
    setUniqueDepartments(departments);

  }, [openings, selectedDepartment, selectedLocation, selectedExperience]);

  console.log("company ->", company);
  console.log("jobs -> ", openings);
  console.log("load", loading)

  return (
    <>

      {
        loading ? (
          <div className={styles.loadingContainer}>
            <img src={loaderImage} alt="Loading..." className={styles.loader} />
          </div>
        ) : (
          company && Object.keys(company).length > 0 ?
            (
              <div className={styles.companyScreen}>
                <div className={styles.about_company}>
                  <div className={styles.companyhead_logo}>
                    <img src={company.logo} alt="" />
                  </div>
                  <div className={styles.companyhead}>
                    <div className={styles.companyhead_title}>{company.companyName}</div>
                    <div className={styles.companyhead_desc}>
                      {/* Short description for company and the roles it offers */}
                      {company.company_description}
                    </div>
                    <div className={styles.companyhead_chips}>
                      {company.domain}
                    </div>
                  </div>
                </div>
                <div className={styles.company_body}>
                  <div className={styles.headline}>Departments with open positions at {company.companyName}</div>
                  <div className={styles.bluebox}>
                    {openings && openings.length > 0 ? (
                      // Iterate over the unique departments (converted from the Set)
                      Array.from(uniqueDepartments).map((department) => {
                        // Filter the openings for the current department
                        const departmentOpenings = openings.filter((job) => job.functionalArea === department);

                        // Calculate the total openings for the current department
                        const totalOpenings = departmentOpenings.reduce((total, job) => total + job.openings, 0);

                        return (
                          <div
                            className={styles.box}
                            key={department}
                            onClick={() => handleDepartmentFilter(department)}
                          >
                            <div className={styles.field_name}>{department} Fields</div>
                            <div className={styles.opening}>
                              {totalOpenings} openings {`>`}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className={styles.loadingContainer}>
                        <img src={loaderImage} alt="Loading..." className={styles.loader} />
                      </div>
                    )}
                  </div>

                  <div className={styles.headline2}>
                    {company.openings} Job openings at {company.companyName}
                  </div>

                  <div className={styles.job_dropdowns}>
                    <div className={styles.dropdown}>
                      <select name="Department" onChange={(e) => setSelectedDepartment(e.target.value)}>
                        <option value="">Department</option>
                        {openings &&
                          openings.length > 0 &&
                          [...new Set(openings.map((job) => job.functionalArea))].map((area) => (
                            <option key={area} value={area}>
                              {area}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className={styles.dropdown}>
                      <select name="Location" onChange={(e) => setSelectedLocation(e.target.value)}>
                        <option value="">Location</option>
                        {openings &&
                          openings.length > 0 &&
                          [...new Set(openings.map((job) => job.city))].map((city) => (
                            <option key={city} value={city}>
                              {city}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className={styles.dropdown}>
                      <select name="Experience" onChange={(e) => setSelectedExperience(e.target.value)}>
                        <option value="">Experience</option>
                        {openings &&
                          openings.length > 0 &&
                          [...new Set(openings.map((job) => job.experience))].map((experience) => (
                            <option key={experience} value={experience}>
                              {experience}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div style={{ cursor: 'pointer' }}>
                    {filteredOpenings && filteredOpenings.length > 0 ? (
                      filteredOpenings.map((job) => (
                        <div className={styles.jobs} key={job._id} onClick={() => handleRedirect(job._id)}>
                          <div className={styles.job_head}>
                            <div className={styles.job_img}>
                              <img src={company.logo} alt="" />
                            </div>
                            <div className={styles.job_head2}>
                              <div className={styles.job_title}>{job.title}</div>
                              <div className={styles.job_name}>{job.companyName}</div>
                            </div>
                          </div>
                          <div className={styles.job_head_first}>
                            <BsBagDash />
                            <span className={styles.spacing}>{job.experience} |</span>
                            <BiRupee />
                            <span className={styles.spacing}>
                              {job.minSalary}-{job.maxSalary} LPA |
                            </span>
                            <IoLocationOutline />
                            <span className={styles.spacing}>
                              {job.city}, {job.state}
                            </span>
                          </div>
                          <div className={styles.job_head_sec}>{job.description}</div>
                          <div className={styles.job_head_third}>
                            {/* US Process • Customer Service • Development */}
                            {job.skills.join(' • ')}
                          </div>
                          <div className={styles.job_head_four}>
                            Posted {calculateDaysAgo(job.postedDate)}d ago
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>Oops! No job found. Please try removing filters to expand your search</p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.loadingContainer}>
                <img src={loaderImage} alt="Loading..." className={styles.loader} />
              </div>
            )
        )}

    </>
  );
};

export default CompanyScreen;
