import React from "react";
import Popup from "reactjs-popup";
import styles from "../../styles/createUser.module.css";
import { Button, Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
function RejectJD(props) {
  let name, value;

  const [error, setError] = useState("");
  const validate = () => {
    let status = false;

    if (props.rejection.trim() === "") {
      status = true;
      setError("Rejection can't be empty");
    }

    return status;
  };
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    props.setRejection(value);
  };

  return (
    <div>
      <Popup
        trigger={
          <button className={styles["approve-btn"]}>
            <AiOutlineClose color="red" />
          </button>
        }
        modal
        nested
      >
        {(close) => (
          <div className={styles["modal"]}>
            <button className={styles["close"]} onClick={close}>
              &times;
            </button>
            <div className={styles["header"]}> Reject Job Description</div>
            <div className={styles["popup-content"]}>
              <Form className="row justify-content-center">
                <Form.Group className="mb-3">
                  <Form.Label className={styles["label"]}>Reason</Form.Label>
                  <Form.Control
                    size="sm"
                    value={props.rejection}
                    onChange={handleInputs}
                    placeholder="Rejection reason"
                    name="rejection"
                  />
                  {error ? <p style={{ color: "red" }}>{error} </p> : null}
                </Form.Group>

                <div className="mb-0 d-flex justify-content-center">
                  <Button
                    className={styles["login-button"]}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!validate()) {
                        props.rejectJD(props.jobId);
                        setTimeout(() => {
                          close();
                        }, 2000);
                      }
                    }}
                  >
                    Reject
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default RejectJD;
