import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { MdOutlineDelete } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import Switch from 'react-switch'
import { BiEditAlt, BiEdit, BiTrash } from "react-icons/bi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "../../styles/manageEmployers.module.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paginate from "../Pagination/pagination";

function Companies(props) {
  const [employers, setEmployers] = useState([]);
  const [active, setActive] = useState("All");
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(1);
  const [sort, setSort] = useState({ sort: "_id", order: "desc" });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showLoader, setShowLoader] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const location = useLocation();
  const token = localStorage.getItem("SavedToken");

  const handleRowSelection = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setAllSelected(false);
      setSelectedRows([]);
    } else {
      setAllSelected(true);
      setSelectedRows(employers.map((row) => row._id));
    }
  };

  const getEmployers = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/get${props.role}s?page=${page}&sort=${sort.sort},${sort.order}&search=${search}&active=${active}&limit=${limit}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      console.log("rrrrrrrrrrrrrr", res.data);
      setEmployers(res.data.employers);
      setTotalPages(res.data.totalPages);
      setTotal(res.data.total)
      

    } catch (err) {
      console.log(err);
    }
  };
  const handleToogle = async (jid) => {
    try {
      const res = await axios({
        method: "patch",
        url: `${process.env.REACT_APP_SERVER_URL}/tooglecompany/${jid}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      if (res.status === 200) {
        console.log("Updated successfully");
        setRefresh(!refresh);
        toast.success("Last Date Updated!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setRefresh(!refresh);
    } catch (err) {
      toast.error("You are not authorized!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(err);
      setRefresh(!refresh);
    }
  };

  const deleteEmployers = async (eids) => {
    const token = localStorage.getItem("SavedToken");

    try {
      const res = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_SERVER_URL}/deleteCompanies`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        data: {
          companyIds: eids,
        },
        credentials: "include",
      });
      console.log(res);
      toast.success("Company Deleted Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSelectedRows([]);
      setRefresh(!refresh);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this company",
      buttons: [
        {
          style: { backgroundColor: "#2186F2" },
          label: "Yes",
          onClick: () => {
            deleteEmployers(selectedRows);
          },
        },
        {
          label: "No",
          onClick: () => console.log("aborted"),
        },
      ],
    });
  };

  useEffect(() => {
    setPage(1);
    setSearch("");
    setActive("All");
    setRefresh(!refresh);
    setSelectedRows([]);
  }, [location.pathname]);

  useEffect(() => {
    getEmployers();
  }, [refresh, search, active, page, limit, sort]);

  return (
    <div className={styles.table_main_container}>
      <h2 className={styles["manage-employer-head"]}>Manage {props.role}</h2>
      <p className={styles["manage-employer-text"]}>Manage Companies list</p>
      <div className="d-flex justify-content-between mx-3 me-4">
        <div className={styles["search-box"]}>
          <BiSearch color="#8692A6" />
          <input
            className={styles["search-text"]}
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {selectedRows.length === 0 && (
          <div className="d-flex">
            <Form className="d-flex flex-wrap me-2">
              <div className="ms-3">
                <Form.Select
                  aria-label="All Status"
                  className={styles["search-filters"]}
                  value={active}
                  onChange={(e) => setActive(e.target.value)}
                >
                  <option value="All">All Status</option>
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </Form.Select>
              </div>
            </Form>
            <Link to={`/onboard${props.role}`}>
              <button className={styles["add-btn"]}>+</button>
            </Link>
          </div>
        )}
        {selectedRows.length === 1 && (
          <div className="d-flex me-4 mt-3">
            <Link to={`/editCompany/${selectedRows[0]}`}>
              <BiEditAlt
                size={30}
                color="#8692A6"
                style={{ marginRight: "10px" }}
              />
            </Link>

            <MdOutlineDelete size={30} color="#8692A6" onClick={handleDelete} />
          </div>
        )}
        {selectedRows.length > 1 && (
          <div className="me-4 mt-3">
            <MdOutlineDelete
              size={30}
              color="#8692A6"
              className="me-2"
              onClick={handleDelete}
            />
          </div>
        )}
      </div>
      <div className="">
        <div className={`${styles["table-head"]} row`}>
          <div className="col-sm-1">
            <input
              type="checkbox"
              className="me-2"
              checked={allSelected}
              onChange={handleSelectAll}
            />
            ID
          </div>
          <div className="col-sm-2">Name</div>
          <div className="col-sm-2">Phone no.</div>
          <div className="col-sm-3">Email</div>
          <div className="col-sm-2">Status</div>
        </div>
        <div className={styles["table-body"]}>
          {employers?.map((employer, index) => (
            <div className={`${styles["table-row"]} row`} key={index}>
              <div className="col-sm-1" style={{ color: "black" }}>
                <input
                  type="checkbox"
                  className="me-2"
                  checked={selectedRows.includes(employer?._id)}
                  onChange={() => handleRowSelection(employer?._id)}
                />
                {employer.companyId}
              </div>
              <div className="col-sm-2 mt-2">
                <div className={styles.new_flex_box}>
                  {employer.name}
                  <p className={styles["employer-name"]}>
                    {employer?.companyName}
                  </p>
                </div>
              </div>
              <div className="col-sm-2">{employer?.phoneNo}</div>
              <div className="col-sm-3">{employer?.email}</div>
              <div className="col-sm-2">
                {/* <Form.Check
                  type="switch"
                  id="custom-switch"
                  value={new Date(employer.expiryDate) >= new Date()}
                /> */}

                <label htmlFor="material-switch">
                  {/* <span>Switch with style inspired by Material Design</span> */}
                  <Switch
                    // checked={job.isActive}
                    checked={new Date(employer.expiryDate) >= new Date()}
                    onChange={() => handleToogle(employer._id)}
                    onColor="#E4CFFF"
                    onHandleColor="#2186F2"
                    handleDiameter={18}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={12.23}
                    width={30}
                    className="react-switch"
                    id="material-switch"
                  />
                </label>
              </div>
            </div>
          ))}
        </div>
        <Paginate totalPages={totalPages} total={total} page={page} setPage={setPage} setLimit={setLimit} limit={limit} />

      </div>
    </div>
  );
}

export default Companies;
