import styles from "../../styles/image.module.css";

function Image(props) {
  return (
    <>
      {props.image === undefined || props.image === "" ? (
        <div
          className={`${styles["profile-icon"]}`}
          style={{ width: `${props.size}px`, height: `${props.size}px` }}
        >
          <p
            className={styles["profile-name"]}
            style={{
              fontSize: `${props.size / 2}px`,
              lineHeight: `${props.size / 2}px`,
            }}
          >
            {props.firstName?.[0]}
          </p>
        </div>
      ) : (
        <div
          className={styles["profile-icon"]}
          style={{ width: `${props.size}px`, height: `${props.size}px` }}
        >
          <img src={props.image} alt="User_Image" className="img-fluid" />
        </div>
      )}
    </>
  );
}

export default Image;
