import axios from "axios";
import { toast } from "react-toastify";

const serverURL = process.env.REACT_APP_SERVER_URL;

export const fillJD = async (e, role, job, setJob, token, setSelected) => {
  try {
    const res = await axios.get(`${serverURL}/fillJd/${role}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token,
      },
      credentials: "include",
    });
    if (res.status === 200) {
      const newJob = res.data;
      newJob.description = newJob.description?.replaceAll("#####", "\n \n");
      newJob.description = newJob.description?.replaceAll("####", "\n");
      console.log(job, newJob);
      setSelected(newJob.skills);

      setJob({ ...job, ...newJob });
    }
    console.log("job", job);
  } catch (error) {
    console.log(error);
    toast.error("Error!, Please try again with some other keyword", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};
