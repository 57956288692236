import React, { useState, useEffect } from "react";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import {
  getRoles,
  getPermissions,
  createRole,
  updateRole,
  deleteRole,
} from "../../api/roles";
import styles from "./permission.module.css";
import { ToastContainer } from "react-toastify";

const RolesAndPermissions = () => {
  const token = localStorage.getItem("SavedToken");
  const [refresh, setRefresh] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [options, setOptions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [newRoleName, setNewRoleName] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedPermissions2, setSelectedPermissions2] = useState([]);

  const [selectedRole, setSelectedRole] = useState(null);
  const [updatedRoleName, setUpdatedRoleName] = useState("");

  const loadPermissions = async () => {
    const res = await getPermissions(token);
    console.log(res);
    setPermissions(res.data);
    let arr = [];
    res.data.map((permission) => {
      arr.push({
        label: permission.name,
        value: permission._id,
      });
    });
    setOptions(arr);
  };

  const loadRoles = async () => {
    console.log("asdf");
    const res = await getRoles(token);
    console.log(res);
    setRoles(res.data);
  };

  useEffect(() => {
    loadPermissions();
    loadRoles();
  }, [refresh]);

  const handleNewRoleNameChange = (event) => {
    setNewRoleName(event.target.value);
  };

  const handleSelectedPermissionChange = (event) => {
    const selectedPermissionIds = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedPermissions(selectedPermissionIds);
  };

  const handleRoleClick = (role) => {
    setSelectedRole(role);
    setUpdatedRoleName(role.name);
    let arr = [];
    role.allowed_permissions.map((p) => {
      arr.push({
        label: p.name,
        value: p._id,
      });
    });
    setSelectedPermissions2(arr);
  };

  const handleUpdatedRoleNameChange = (event) => {
    setUpdatedRoleName(event.target.value);
  };

  const handleCreateRole = async () => {
    console.log(selectedPermissions);
    let allowed_permissions = [];
    selectedPermissions.map((p) => {
      allowed_permissions.push(p.value);
    });
    console.log(allowed_permissions);
    const res = await createRole(newRoleName, allowed_permissions, token);
    console.log(res.data);
    setNewRoleName("");
    setSelectedPermissions([]);
    setRefresh(!refresh);
  };

  const handleUpdateRole = async () => {
    let allowed_permissions = [];
    selectedPermissions2.map((p) => {
      allowed_permissions.push(p.value);
    });
    const res = await updateRole(
      selectedRole._id,
      updatedRoleName,
      allowed_permissions,
      token
    );
    console.log(res.data);
    setRefresh(!refresh);
    setSelectedRole(null);
  };

  const handleDeleteRole = async () => {
    const res = await deleteRole(selectedRole._id, token);
    console.log(res);
    setRefresh(!refresh);
    setSelectedRole(null);
  };

  return (
    <div className="px-4">
      <h2 className={styles["head"]}>Roles and Permissions</h2>
      <div className={styles["roles"]}>
        {roles.map((role) => (
          <button
            key={role._id}
            onClick={() => handleRoleClick(role)}
            className={styles["role-btn"]}
          >
            {role.name}
          </button>
        ))}
      </div>
      <h3 className={styles["create-role"]}>Create New Role</h3>
      <div className={styles["role-row"]}>
        <div className="mt-2">
          <label className="me-2">Name:</label>
          <input
            type="text"
            className={styles["role-input"]}
            value={newRoleName}
            onChange={handleNewRoleNameChange}
            placeholder="Role name"
          />
        </div>
        <div className="d-flex mt-2 align-items-center">
          <label className="me-2">Allowed Permissions:</label>
          <MultiSelect
            options={options}
            className={styles["multi-select"]}
            onChange={setSelectedPermissions}
            value={selectedPermissions}
          />
        </div>
      </div>
      <button onClick={handleCreateRole} className={styles["create-btn"]}>
        Create Role
      </button>
      {selectedRole && (
        <>
          <h3 className={styles["create-role"]}>Update Role</h3>
          <div className={styles["role-row"]}>
            <div className="mt-2">
              <label className="me-2">Name:</label>
              <input
                type="text"
                className={styles["role-input"]}
                value={updatedRoleName}
                onChange={handleUpdatedRoleNameChange}
              />
            </div>
            <div className="mt-2 d-flex align-items-center">
              <label className="me-2">Allowed Permissions:</label>
              <MultiSelect
                options={options}
                className={styles["multi-select"]}
                onChange={setSelectedPermissions2}
                value={selectedPermissions2}
              />
            </div>
          </div>

          <button onClick={handleUpdateRole} className={styles["create-btn"]}>
            Update Role
          </button>
          <button onClick={handleDeleteRole} className={styles["delete-btn"]}>
            Delete Role
          </button>
        </>
      )}
    </div>
  );
};

export default RolesAndPermissions;
