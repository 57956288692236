import { Card, Form } from "react-bootstrap";
import styles from "../styles/onBoardEmployer.module.css";
import { Country, State, City } from "country-state-city";
import MultiSelectDropdown from "../components/multiselectDropdown";
import { useState, useEffect } from "react";

import axios from "axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import logo from "../images/logo.png";
import WorkExperience from "../components/workExperience";

function OnboardCandidate() {
  const { rid } = useParams();
  const token = localStorage.getItem("SavedToken");
  const [showCustomSector, setShowCustomSector] = useState(false);
  const navigate = useNavigate();
  const [customizations, setCustomizations] = useState({});
  const [workExperience, setWorkExperience] = useState([
    { company: "", startDate: "", endDate: "", present: false },
  ]);
  const [candidate, setCandidate] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    experience: "",
    resume: "",
    resumeText: "",
    desiredJobcity: "",
    desiredJobcountry: "",
    desiredJobexpectedCTC: "",
    desiredJobtype: "",
    desiredJobtitle: "",
    desiredJobsector: "",
    customSector: "",
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    country: "",
    experience: "",
    resume: "",
    desiredJobcity: "",
    desiredJobcountry: "",
    desiredJobexpectedCTC: "",
    desiredJobtype: "",
    desiredJobtitle: "",
    desiredJobsector: "",
  });

  const validate = () => {
    let status = false;
    let err = {};
    if (candidate.firstName === "") {
      status = true;
      err.firstName = "First name can't be empty";
    } else if (!/^[a-zA-Z]+$/.test(candidate.firstName)) {
      status = true;
      err.firstName = "Invalid First Name";
    }
    if (!/^[a-zA-Z]+$/.test(candidate.lastName) && candidate.lastName !== "") {
      status = true;
      err.lastName = "Last name Invalid";
    }
    if (candidate.email === "") {
      status = true;
      err.email = "Email can't be empty";
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(candidate.email)
    ) {
      status = true;
      err.email = "Invalid Email";
    }
    if (!/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/.test(candidate.mobile)) {
      status = true;
      err.mobile = "Invalid Mobile no.";
    }
    if (selected.length === 0) {
      status = true;
      err.skills = "Skills Can't be Empty";
    }

    if (selectedCountry === null) {
      status = true;
      err.country = "Country can't be empty";
    }
    if (selectedState === null) {
      status = true;
      err.state = "State can't be empty";
    }
    if (selectedCity === null) {
      status = true;
      err.city = "City can't be empty";
    }
    if (candidate.experience === "") {
      status = true;
      err.experience = "Experience can't be empty";
    } else if (isNaN(candidate.experience)) {
      status = true;
      err.experience = "Experience should be an integer";
    } else if (0 >= Number(candidate.experience) >= 100) {
      status = true;
      err.experience = "Invalid Experience value";
    } else {
      console.log(Number(candidate.experience));
    }
    if (candidate.resume === "") {
      status = true;
      err.resume = "Please select a resume first";
    }
    if (candidate.desiredJobcity.trim() === "") {
      status = true;
      err.desiredJobcity = "City can't be empty";
    } else if (!/^[a-zA-Z0-9 ]*$/.test(candidate.desiredJobcity)) {
      status = true;
      err.desiredJobcity = "Invalid City Name";
    }
    if (candidate.desiredJobexpectedCTC === "") {
      status = true;
      err.desiredJobexpectedCTC = "CTC can't be empty";
    }
    if (candidate.desiredJobtype === "") {
      status = true;
      err.desiredJobType = "Type can't be empty";
    }
    if (candidate.desiredJobtitle.trim() === "") {
      status = true;
      err.desiredJobtitle = "Title can't be empty";
    } else if (!/^[a-zA-Z0-9\s]*$/.test(candidate.desiredJobtitle)) {
      status = true;
      err.desiredJobtitle = "Invalid value for Job title";
    }
    if (candidate.desiredJobsector.trim() === "") {
      status = true;
      err.desiredJobsector = "Sector can't be empty";
    } else if (
      candidate.desiredJobsector.trim() === "Others" &&
      candidate.customSector.trim() === ""
    ) {
      status = true;
      err.customSector = "Sector can't be empty";
    }
    setError({ ...err });
    return status;
  };

  const [selected, setSelected] = useState([]);

  const handleCtcValue = (e) => {
    name = e.target.name;
    value = e.target.value;
    value = value.replace(/,/gi, "");
    if (isNaN(value)) {
      value = "";
    } else {
      value = Number(value).toLocaleString("en-IN");
    }
    console.log(value);
    setCandidate({ ...candidate, [name]: value });
    console.log(candidate, value);
  };
  const handleSectorChange = (e) => {
    const { value } = e.target;
    if (value !== "Others") {
      setShowCustomSector(false);
    } else {
      setShowCustomSector(true);
    }
    setCandidate((prev) => ({
      ...prev,
      desiredJobsector: value,
    }));
  };

  const handleCustomSectorChange = (e) => {
    const { value } = e.target;
    setCandidate((prev) => ({ ...prev, customSector: value }));
  };

  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setCandidate({ ...candidate, [name]: value });
    console.log(candidate, value);
  };

  const handleUpload = async (pdf) => {
    if (pdf) {
      const formData = new FormData();
      formData.append("file", pdf);

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/uploadCV`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      setCandidate({
        ...candidate,
        resume: data.url,
        resumeText: data.pdfText,
      });
    }
  };

  const postData = async (e) => {
    e.preventDefault();
    if (!validate()) {
      const {
        firstName,
        lastName,
        resume,
        email,
        mobile,
        experience,
        desiredJobcity,
        resumeText,
        desiredJobexpectedCTC,
        desiredJobtype,
        desiredJobtitle,
        desiredJobsector,
        customSector,
      } = candidate;
      const country = selectedCountry.name;
      const state = selectedState.name;
      const city = selectedCity.name;

      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/onBoardCandidate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
          body: JSON.stringify({
            recruiterId: rid,
            firstName,
            lastName,
            resumeText,
            resume,
            email,
            mobile,
            experience,
            city,
            state,
            country,
            skills: selected,
            desiredJobcity,
            desiredJobcountry: country,
            desiredJobexpectedCTC,
            desiredJobtype,
            desiredJobtitle,
            desiredJobsector:
              desiredJobsector === "Others" ? customSector : desiredJobsector,
            workExperience,
            jobId: "",
          }),
        }
      );
      const data = await res.json();

      if (res.status === 201) {
        toast.success(" Successfully Added!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log("Successfully Added", data);
        setTimeout(() => {
          navigate("/candidates");
        }, 2000);
      } else {
        toast.error("Error while adding!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(data.message);
      }
    }
  };
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const getFields = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getcustomizations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      setCustomizations(res.data);
      console.log(res.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    setSelectedState(
      State.getStatesOfCountry(selectedCountry?.isoCode).find(
        (data) => data.name === candidate.state
      )
        ? State.getStatesOfCountry(selectedCountry?.isoCode).find(
            (data) => data.name === candidate.state
          )
        : null
    );
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedCity(
      City.getCitiesOfState(
        selectedState?.countryCode,
        selectedState?.isoCode
      ).find((data) => data.name === candidate.city)
        ? City.getCitiesOfState(
            selectedState?.countryCode,
            selectedState?.isoCode
          ).find((data) => data.name === candidate.city)
        : null
    );
  }, [selectedState]);

  useEffect(() => {
    getFields();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h1 className={styles["onboard-head"]}>Candidate Form</h1>
        <div className="me-md-5 mt-2">
          <img src={logo} alt="logo" height={50} />
        </div>
      </div>
      <div className="">
        <p className={styles["onboard-text"]}>Fill your details for the Job</p>
      </div>

      <Card className="p-4 m-md-4">
        <Form className="d-flex flex-wrap row">
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              Email <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="email"
              value={candidate.email}
              placeholder="youremail@gmail.com"
              className={styles["candidate-forms"]}
              onChange={handleInputs}
            />
            {error.email ? (
              <p style={{ color: "red" }}>{error.email} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              First Name <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="firstName"
              value={candidate.firstName}
              onChange={handleInputs}
              className={styles["candidate-forms"]}
            />
            {error.firstName ? (
              <p style={{ color: "red" }}>{error.firstName} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              Last Name
            </Form.Label>
            <Form.Control
              name="lastName"
              value={candidate.lastName}
              onChange={handleInputs}
              className={styles["candidate-forms"]}
            />
            {error.lastName ? (
              <p style={{ color: "red" }}>{error.lastName} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              Phone No. <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="mobile"
              value={candidate.mobile}
              onChange={handleInputs}
              className={styles["candidate-forms"]}
              maxLength={10}
            />
            {error.mobile ? (
              <p style={{ color: "red" }}>{error.mobile} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              Country <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Select
              options={Country.getAllCountries()}
              getOptionLabel={(options) => {
                return options["name"];
              }}
              getOptionValue={(options) => {
                return options["name"];
              }}
              value={selectedCountry}
              onChange={(item) => {
                setSelectedCountry(item);
              }}
            />
            {error.country ? (
              <p style={{ color: "red" }}>{error.country} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              State <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Select
              options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
              getOptionLabel={(options) => {
                return options["name"];
              }}
              getOptionValue={(options) => {
                return options["name"];
              }}
              value={selectedState}
              onChange={(item) => {
                setSelectedState(item);
              }}
            />
            {error.state ? (
              <p style={{ color: "red" }}>{error.state} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              City <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Select
              options={City.getCitiesOfState(
                selectedState?.countryCode,
                selectedState?.isoCode
              )}
              getOptionLabel={(options) => {
                return options["name"];
              }}
              getOptionValue={(options) => {
                return options["name"];
              }}
              value={selectedCity}
              onChange={(item) => {
                setSelectedCity(item);
              }}
            />
            {error.city ? <p style={{ color: "red" }}>{error.city} </p> : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              Experience{" "}
              <span style={{ fontWeight: 50, color: "silver" }}> (years) </span>
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="experience"
              value={candidate.experience}
              onChange={handleInputs}
              placeholder="e.g. 5"
              className={styles["candidate-forms"]}
              maxLength={2}
            />
            {error.experience ? (
              <p style={{ color: "red" }}>{error.experience} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={`styles["candidate-labels"] d-block`}>
              Resume/CV <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <input
              type="file"
              accept="application/pdf,application/msword,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              className={styles["file-input"]}
              onChange={(e) => handleUpload(e.target.files[0])}
            />
            {error.resume ? (
              <p style={{ color: "red" }}>{error.resume} </p>
            ) : null}
          </div>

          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              Skills <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <MultiSelectDropdown value={{ selected, setSelected }} />
            {error.skills ? (
              <p style={{ color: "red" }}>{error.skills} </p>
            ) : null}
          </div>

          <h3>Looking for position</h3>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              City <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="desiredJobcity"
              value={candidate.desiredJobcity}
              onChange={handleInputs}
              className={styles["candidate-forms"]}
            />
            {error.desiredJobcity ? (
              <p style={{ color: "red" }}>{error.desiredJobcity} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              Compensation Expected
              <span style={{ fontWeight: 50, color: "silver" }}> (Rs) </span>
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="desiredJobexpectedCTC"
              value={candidate.desiredJobexpectedCTC}
              onChange={handleCtcValue}
              placeholder="e.g. 300000"
              aria-label="Select"
              className={styles["candidate-forms"]}
            />
            {error.desiredJobexpectedCTC ? (
              <p style={{ color: "red" }}>{error.desiredJobexpectedCTC} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              Job Type <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Select
              name="desiredJobtype"
              value={candidate.desiredJobtype}
              onChange={handleInputs}
              className={styles["candidate-forms"]}
            >
              <option>Select</option>
              <option value="Full-time">Full-time</option>
              <option value="Part-time">Part-time</option>
              <option value="Remote">Remote</option>
            </Form.Select>
            {error.desiredJobType ? (
              <p style={{ color: "red" }}>{error.desiredJobType} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              Job Title <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name="desiredJobtitle"
              value={candidate.desiredJobtitle}
              onChange={handleInputs}
              placeholder="e.g. Java Developer"
              className={styles["candidate-forms"]}
            />
            {error.desiredJobtitle ? (
              <p style={{ color: "red" }}>{error.desiredJobtitle} </p>
            ) : null}
          </div>
          <div className="col-lg-4 col-md-5 mb-5">
            <Form.Label className={styles["candidate-labels"]}>
              Sector <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Select
              name="desiredJobsector"
              id="desiredJobsector"
              value={candidate.desiredJobsector}
              onChange={handleSectorChange}
              className={styles["candidate-forms"]}
            >
              <option value="">Select</option>
              {customizations.functionalAreas?.map((area) => (
                <option value={area}>{area}</option>
              ))}
            </Form.Select>

            {error.desiredJobsector ? (
              <p style={{ color: "red" }}>{error.desiredJobsector} </p>
            ) : null}
          </div>
          {showCustomSector && (
            <div className="col-lg-4 col-md-5 mb-5">
              <Form.Label className={styles["candidate-labels"]}>
                Other Sector <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="customSector"
                id="customSector"
                value={candidate.customSector}
                onChange={handleCustomSectorChange}
                className={styles["candidate-forms"]}
              />
              {error.customSector ? (
                <p style={{ color: "red" }}>{error.customSector} </p>
              ) : null}
            </div>
          )}

          <h3>Work Experience</h3>

          <WorkExperience
            experience={workExperience}
            setExperience={setWorkExperience}
          />
        </Form>
        <div className="row mx-0">
          <button
            className={`col-md-4 me-3 ${styles["onboard-btn-c"]}`}
            onClick={() => {
              navigate("/candidates");
            }}
          >
            Cancel
          </button>
          <button
            className={`col-md-4 ${styles["onboard-btn-a"]}`}
            onClick={postData}
          >
            Add
          </button>
        </div>
      </Card>
    </div>
  );
}

export default OnboardCandidate;
