import { FiCheckCircle } from "react-icons/fi";
import { BsCalendar, BsPerson } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { MultiSelect } from "react-multi-select-component";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Form } from "react-bootstrap";
import MultiSelectDropdown from "../components/multiselectDropdown";
import styles from "../styles/createJob.module.css";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { useState, useEffect, useContext } from "react";
import { AppContext } from "../components/appContext";
import AddTemplate from "../components/addTemplate";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function EditJob(props) {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [availableApprovers, setAvailableApprovers] = useState([]);
  const [selectedQualification, setSelectedQualification] = useState([]);
  const [maxBadge, setMaxBadge] = useState(1);
  const [employerId, setEmployerId] = useState("");
  const [availableBadges, setAvailableBadges] = useState([1]);

  const [selectedCity, setSelectedCity] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [customizations, setCustomizations] = useState({});
  const myContext = useContext(AppContext);
  const navigate = useNavigate();
  const [approverOptions, setApproverOptions] = useState([]);

  const [template, setTemplate] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const token = localStorage.getItem("SavedToken");
  const [job, setJob] = useState({
    title: "",
    type: "",
    openings: "",
    description: "",
    preference: "",
    endDate: new Date(),
    priority: "",
    recruiters: [],
    approvers: [],
    companyName: "",
    companyDetails: "",
    experience: "",
    qualification: "",
    functionalArea: "",
    industry: "",
    minSalary: "",
    maxSalary: "",
    maxApproval: "",
  });
  const [error, setError] = useState({
    title: "",
    type: "",
    openings: "",
    description: "",
    priority: "",
    recruiters: "",
    skills: "",
    companyName: "",
    companyDetails: "",
    experience: "",
    qualification: "",
    functionalArea: "",
    industry: "",
    minSalary: "",
    maxSalary: "",
    city: "",
    state: "",
    country: "",
    maxApproval: "",
  });
  const getApprovers = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getApprovers/${employerId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res.data);
      setAvailableApprovers(res.data);
    } catch (error) {
      console.log(error);
    }
  };

// sahil
  const getRecruiters = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getRecruitersforjobpost`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res.data, "rec");
      let arr = [];
      res.data.recruiters.map((recruiter) => {
        console.log(recruiter._id);
        if (recruiter.name === "0") {
          arr.push({
            label: `${recruiter.email}`,
            value: recruiter._id,
            disabled: true,
          });
        } else {
          arr.push({
            label: `${recruiter.name}, ${recruiter.email}`,
            value: recruiter._id,
          });
        }
      });
      setRecruiterOptions(arr);
      console.log("arr", arr);
    } catch (err) {
      console.log(err);
    }
  };

  const getApproverOptions = () => {
    try {
      let arr = [];
      for (var i = 0; i < (job.maxApproval || 0); i++) {
        availableApprovers[i].map((approver) => arr.push(approver));
      }
      setApproverOptions(arr);
      console.log("heeeeeeeeeeeeeee", arr, approverOptions);
    } catch (err) {
      console.log(err);
    }
  };
  const getMaxBadge = async () => {
    try {
      console.log(employerId);
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getMaxBadge/${employerId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },

        credentials: "include",
      });
      console.log(res.data);
      setMaxBadge(res.data);
      setAvailableBadges(
        Array.from({ length: res.data }, (_, index) => index + 1)
      );
    } catch (err) {
      console.log(err);
    }
  };

  const { jobId } = useParams();
  const getJobDetails = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getJob/${jobId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      const {
        title,
        type,
        employer,
        openings,
        description,
        preference,
        endDate,
        priority,
        recruiters,
        skills,
        approvers,
        companyName,
        companyDetails,
        experience,
        maxApproval,
        qualification,
        functionalArea,
        industry,
        minSalary,
        maxSalary,
        country,
        state,
        city,
      } = res.data;

      recruiters.map((recruiter) => {
        recruiter.value = recruiter._id;
        recruiter.label = recruiter.name + " " + recruiter.email;
      });
      approvers.map((approver) => {
        approver.value = approver._id;
        approver.label = approver.name + " " + approver.email;
      });
      let qualificationArray = [];
      qualification.split(",").map((qualification) => {
        qualificationArray.push({ value: qualification, label: qualification });
      });
      console.log("#####################", qualificationArray);
      setSelectedQualification(qualificationArray);
      let date = new Date(endDate);
      console.log(recruiters);
      setJob({
        title,
        type,
        employer,
        openings,
        description,
        endDate: date,
        preference,
        priority,
        approvers,
        maxApproval,
        recruiters,
        companyName,
        companyDetails,
        experience,
        qualification,
        functionalArea,
        industry,
        minSalary,
        maxSalary,
        state,
        priority,
        city,
      });
      setSelected(skills);
      // setSelectedQualification(qualification.split(","));
      setSelectedCountry(
        Country.getAllCountries().find((data) => data.name === country)
      );

      console.log(job);
    } catch (err) {
      console.log(err);
    }
  };

  const validate = () => {
    let status = false;
    let err = { title: "", type: "" };
    if (job.title.trim() === "") {
      status = true;
      err.title = "Title can't be empty";
    }
    if (job.type.trim() === "") {
      status = true;
      console.log("error", error);
      err.type = "Type can't be empty";
    }
    if (job.openings === "") {
      status = true;
      err.openings = "Openings can't be empty";
    } else if (isNaN(Number(job.openings))) {
      status = true;
      err.openings = "Openings should be integer only";
    }
    if (job.description.trim() === "") {
      status = true;
      err.description = "Description can't be empty";
    }
    if (job.priority.trim() === "") {
      status = true;
      err.priority = "Priority can't be empty";
    }
    // if (selected.length === 0) {
    //   status = true;
    //   err.skills = "Skills can't be empty";
    // }
    // if (job.recruiters.length === 0) {
    //   status = true;
    //   // console.log(job.recruiters.length);
    //   err.recruiter = "Select atleast one recruiter";
    // }
    if (job.companyName.trim() === "") {
      status = true;
      err.companyName = "Company name can't be empty";
    }
    if (job.experience.trim() === "") {
      status = true;
      err.experience = "Experience can't be empty";
    }
    if (selectedQualification.length === 0) {
      status = true;
      err.qualification = "Qualification can't be empty";
    }
    if (job.functionalArea.trim() === "") {
      status = true;
      err.functionalArea = "Dunctional Area can't be empty";
    }
    if (job.industry.trim() === "") {
      status = true;
      err.industry = "Industry can't be empty";
    }

    if (job.maxSalary !== "") {
      if (isNaN(Number(job.maxSalary))) {
        status = true;
        err.maxSalary = "Invalid value";
      } else if (Number(job.maxSalary) < Number(job.minSalary)) {
        status = true;
        err.minSalary = "Minimum salary must be lesser than maximum salary";
      }
    }
    if (selectedCountry === null) {
      status = true;
      err.country = "country can't be empty";
    }
    if (selectedState === null) {
      status = true;
      err.state = "State can't be empty";
    }
    if (selectedCity === null) {
      status = true;
      err.city = "City can't be empty";
    }
    if (job.maxApproval === "") {
      status = true;
      err.maxApproval = "Please select approvals required";
    }

    if (job.approvers.length === 0) {
      status = true;
      err.approver = "Select atleast one approver";
    } else {
      let flag = false;
      for (var i = 0; i < job.maxApproval; i++) {
        flag = true;
        availableApprovers[i].forEach((approver) => {
          if (job.approvers.find((data) => data.value === approver.value)) {
            flag = false;
          }
        });
        if (flag) {
          status = true;
          err.approver = `Please select atleast one approver with badge ${i + 1
            }`;
        }
      }
    }
    setError({ ...err });
    return status;
  };

  const [recruiterOptions, setRecruiterOptions] = useState([]);
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setJob({ ...job, [name]: value });
    console.log(job, value);
  };

  const [selected, setSelected] = useState([]);
  const selectTemplate = async () => {
    if (selectedTemplate === "") {
      setSelected([]);
      setJob({
        ...job,
        title: "",
        type: "",
        openings: "",
        description: "",
        preference: "",
      });
    } else {
      try {
        const res = await axios({
          method: "get",
          url: `${process.env.REACT_APP_SERVER_URL}/getJobTemplatebyId/${selectedTemplate}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: token,
          },
          credentials: "include",
        });
        console.log(res);
        // setTemplate(res.data);
        const { title, description, type, preference, skills } = res.data;
        setJob({ ...job, title, description, type, preference });
        setSelected(skills);
        console.log(job);
        setSelected(res.data.skills);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getTemplates = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getJobTemplates`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      setTemplate(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  // const getRecruiters = async () => {
  //   try {
  //     const res = await axios({
  //       method: "get",
  //       url: `${process.env.REACT_APP_SERVER_URL}/getRecruitersforjobpost`,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         authorization: token,
  //       },
  //       credentials: "include",
  //     });
  //     console.log(res);
  //     let arr = [];
  //     res.data.recruiters.map((recruiter) => {
  //       arr.push({
  //         label: `${recruiter.name} ${recruiter.email}`,
  //         value: recruiter._id,
  //       });
  //     });
  //     setRecruiterOptions(arr);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const postData = async (e) => {
    e.preventDefault();
    if (!validate()) {
      const {
        title,
        type,
        openings,
        description,
        preference,
        endDate,
        priority,
        recruiters,
        companyName,
        companyDetails,
        experience,
        functionalArea,
        industry,
        minSalary,
        approvers,
        maxApproval,
        maxSalary,
      } = job;
      let recruiterArray = [];
      recruiters.map((recruiter) => recruiterArray.push(recruiter.value));
      let approverArray = [];
      approvers.map((approver) => approverArray.push(approver.value));
      let qualificationArray = [];
      selectedQualification.map((qualification) =>
        qualificationArray.push(qualification.value)
      );
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/updateJob/${jobId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
          body: JSON.stringify({
            title,
            type,
            openings,
            description,
            preference,
            endDate,
            priority,
            recruiters: recruiterArray,
            approvers: approverArray,
            skills: selected,
            companyName,
            companyDetails,
            experience,
            qualification: qualificationArray.join(","),
            functionalArea,
            industry,
            minSalary,
            maxSalary,
            maxApproval,

            country: selectedCountry.name,
            state: selectedState.name,
            city: selectedCity.name,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 201) {
        toast.success(
          "Successfully Updated, Please ask the approver to approve the changes",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        console.log("Successfully Added", data);
        setTimeout(() => {
          navigate("/jobs");
        }, 2000);
      } else {
        toast.error("Error while Updating!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(data.message);
      }
    }
  };
  const handleNewField = (value) => ({
    label: value,
    value: value.toUpperCase(),
  });

  const getFields = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getcustomizations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      console.log(res);
      setCustomizations(res.data);
      let arr = [];
      res.data.qualifications?.map((qualification) => {
        arr.push({ label: qualification, value: qualification });
      });
      setCustomizations({ ...res.data, qualifications: arr });
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getRecruiters();
    getJobDetails();
    getFields();
  }, []);

  useEffect(() => {
    setEmployerId(job?.employer?._id);
  }, [job.employer?._id]);

  useEffect(() => {
    getApprovers();
    getMaxBadge();
  }, [employerId]);
  useEffect(() => {
    if (job.maxApproval !== "") {
      getApproverOptions();
    }
  }, [job.maxApproval]);

  useEffect(() => {
    getTemplates();
  }, [myContext.user, refresh]);

  useEffect(() => {
    selectTemplate();
  }, [selectedTemplate]);

  useEffect(() => {
    setSelectedState(
      State.getStatesOfCountry(selectedCountry?.isoCode).find(
        (data) => data.name === job.state
      )
        ? State.getStatesOfCountry(selectedCountry?.isoCode).find(
          (data) => data.name === job.state
        )
        : null
    );
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedCity(
      City.getCitiesOfState(
        selectedState?.countryCode,
        selectedState?.isoCode
      ).find((data) => data.name === job.city)
        ? City.getCitiesOfState(
          selectedState?.countryCode,
          selectedState?.isoCode
        ).find((data) => data.name === job.city)
        : null
    );
  }, [selectedState]);

  return (
    <div>
      <div className="mx-4 mt-4">
        <Row>
          <Col md="6" className={styles["left-form"]}>
            {myContext.user.role === "employer" && (
              <div>
                <div className="d-flex justify-content-between">
                  <h2 className={styles["job-detail"]}>Job Detail</h2>
                  <AddTemplate refresh={refresh} setRefresh={setRefresh} />
                </div>
                <Form.Label className={styles["form-label"]}>
                  <span className="ps-1"> Select Template</span>
                </Form.Label>
                <Form.Select
                  name="template"
                  className={styles["form-input"]}
                  placeholder="e.g. 4"
                  value={selectedTemplate}
                  onChange={(e) => setSelectedTemplate(e.target.value)}
                >
                  <option value="">Select</option>
                  {template?.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.title}
                    </option>
                  ))}
                </Form.Select>
              </div>
            )}
            <Form>
              <Form.Label className={styles["form-label-required"]}>
                Company Name
              </Form.Label>
              <Form.Control
                name="companyName"
                value={job.companyName}
                onChange={handleInputs}
                className={styles["form-input"]}
              />
              {error.companyName ? (
                <p style={{ color: "red" }}>{error.title} </p>
              ) : null}

              <Form.Label className={styles["form-label-required"]}>
                Job Title
              </Form.Label>
              <Form.Control
                name="title"
                value={job.title}
                onChange={handleInputs}
                className={styles["form-input"]}
                placeholder="e.g. Software Engineer Trainee"
              />
              {error.title ? (
                <p style={{ color: "red" }}>{error.title} </p>
              ) : null}

              <Form.Label className={styles["form-label-required"]}>
                Skills required
              </Form.Label>
              <MultiSelectDropdown value={{ selected, setSelected }} />
              {error.skills && <p style={{ color: "red" }}>{error.skills} </p>}

              <Form.Label className={styles["form-label-required"]}>Job Type</Form.Label>
              <Form.Check
                inline
                value="In-Office"
                onChange={handleInputs}
                type={"radio"}
                id={`office`}
                label={`In-office`}
                name={"type"}
                checked={"In-Office" === job.type}
                className={styles["radio"]}
              />
              <Form.Check
                inline
                value="Remote"
                onChange={handleInputs}
                type={"radio"}
                id={`remote`}
                label={`Remote`}
                name={"type"}
                checked={"Remote" === job.type}
                className={styles["radio"]}
              />
              <Form.Check
                inline
                value="Hybrid"
                onChange={handleInputs}
                type={"radio"}
                id={`hybrid`}
                label={`Hybrid`}
                name={"type"}
                checked={"Hybrid" === job.type}
                className={styles["radio"]}
              />
              {error.type ? (
                <p style={{ color: "red" }}>{error.type} </p>
              ) : null}

              <Form.Label className={styles["form-label-required"]}>
                No. of Openings
              </Form.Label>
              <Form.Control
                value={job.openings}
                onChange={handleInputs}
                name="openings"
                className={styles["form-input"]}
                placeholder="e.g. 4"
              />
              {error.openings && (
                <p style={{ color: "red" }}>{error.openings} </p>
              )}

              <Form.Label className={styles["form-label-required"]}>
                Job Description
              </Form.Label>
              <Form.Control
                value={job.description}
                onChange={handleInputs}
                name="description"
                className={styles["form-input"]}
                placeholder="e.g. Key Responsibilities
                1.
                2.
                3."
                as="textarea"
                rows={4}
              />
              {error.description && (
                <p style={{ color: "red" }}>{error.description} </p>
              )}
              <Form.Label className={styles["form-label-required"]}>
                Required Experience
              </Form.Label>
              <Form.Control
                value={job.experience}
                onChange={handleInputs}
                name="experience"
                className={styles["form-input"]}
                placeholder="e.g. 4 years"
              />
              {error.experience && (
                <p style={{ color: "red" }}>{error.experience} </p>
              )}

              <Form.Label className={styles["form-label-required"]}>
                <span className="ps-1"> Functional Area</span>
              </Form.Label>
              <Form.Select
                name="functionalArea"
                className={styles["form-input"]}
                placeholder="e.g. 4"
                value={job.functionalArea}
                onChange={handleInputs}
              >
                <option value="">Select</option>
                {customizations?.functionalAreas?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              {error.functionalArea && (
                <p style={{ color: "red" }}>{error.functionalArea} </p>
              )}
              <Form.Label className={styles["form-label-required"]}>
                <span className="ps-1">Educational qualification</span>
              </Form.Label>
              {customizations.qualifications && (
                <MultiSelect
                  options={customizations.qualifications}
                  value={selectedQualification}
                  onChange={setSelectedQualification}
                  labelledBy="Select"
                  isCreatable={true}
                  onCreateOption={handleNewField}
                />
              )}
              {error.qualification && (
                <p style={{ color: "red" }}>{error.qualification} </p>
              )}

              <Form.Label className={styles["form-label-required"]}>
                <span className="ps-1">Industry</span>
              </Form.Label>
              <Form.Select
                name="industry"
                className={styles["form-input"]}
                placeholder="e.g. 4"
                value={job.industry}
                onChange={handleInputs}
              >
                <option value="">Select</option>
                {customizations?.industry?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              {error.industry && (
                <p style={{ color: "red" }}>{error.industry} </p>
              )}

              <Form.Label className={styles["form-label-required"]}>Country</Form.Label>
              <Select
                options={Country.getAllCountries()}
                getOptionLabel={(options) => {
                  return options["name"];
                }}
                getOptionValue={(options) => {
                  return options["name"];
                }}
                value={selectedCountry}
                onChange={(item) => {
                  setSelectedCountry(item);
                }}
              />
              {error.country ? (
                <p style={{ color: "red" }}>{error.country} </p>
              ) : null}

              <Form.Label className={styles["form-label-required"]}>State</Form.Label>
              <Select
                options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                getOptionLabel={(options) => {
                  return options["name"];
                }}
                getOptionValue={(options) => {
                  return options["name"];
                }}
                value={selectedState}
                onChange={(item) => {
                  setSelectedState(item);
                }}
              />
              {error.state ? (
                <p style={{ color: "red" }}>{error.state} </p>
              ) : null}

              <Form.Label className={styles["form-label-required"]}>City</Form.Label>
              <Select
                options={City.getCitiesOfState(
                  selectedState?.countryCode,
                  selectedState?.isoCode
                )}
                getOptionLabel={(options) => {
                  return options["name"];
                }}
                getOptionValue={(options) => {
                  return options["name"];
                }}
                value={selectedCity}
                onChange={(item) => {
                  setSelectedCity(item);
                }}
              />
              {error.city ? (
                <p style={{ color: "red" }}>{error.city} </p>
              ) : null}
              <Form.Label className={styles["form-label-required"]}>
                Salary Range
              </Form.Label>
              <div className="d-flex justify-content-between align-items-end">
                <Form.Control
                  value={job.minSalary}
                  onChange={handleInputs}
                  name="minSalary"
                  className={styles["form-input"]}
                  placeholder="Minimum"
                />

                <span className="mx-2"> to </span>
                <Form.Control
                  value={job.maxSalary}
                  onChange={handleInputs}
                  name="maxSalary"
                  className={styles["form-input"]}
                  placeholder="Maximum"
                />
              </div>
              {error.minSalary ? (
                <p style={{ color: "red" }}>{error.minSalary} </p>
              ) : null}
              {error.maxSalary && (
                <p style={{ color: "red" }}>{error.maxSalary} </p>
              )}

              <Form.Label className={styles["form-label"]}>
                Do you have any candidate preference?{" "}
                <span style={{ color: "silver", fontWeight: "10" }}>
                  (Optional)
                </span>
              </Form.Label>
              <Form.Control
                value={job.preference}
                onChange={handleInputs}
                name="preference"
                className={styles["form-input"]}
                placeholder="e.g. Only Computer Science graduate preferred"
                rows={2}
                as="textarea"
              />
            </Form>
          </Col>
          <Col md="6">
            <Form>
              <div className={styles["right-form"]}>
                <Form.Label className={styles["form-label-required"]}>
                  <BsPerson />
                  <span className="ps-1">Recruiters Assigned</span>
                </Form.Label>
                <div>
                  {/* <pre>
                    {job.recruiters.map((recruiter, index) => (
                      <div className="mt-1" key={index}>
                        <div className={styles.new_font_manner}>
                          {recruiter.label}
                        </div>
                      </div>
                    ))}
                  </pre> */}
                  <MultiSelect
                    options={recruiterOptions}
                    value={job.recruiters}
                    onChange={(value) => {
                      // console.log(value);
                      setJob({
                        ...job,
                        ["recruiters"]: value,
                      });
                    }}
                    labelledBy="Select"
                  />
                </div>
                {error.recruiter && (
                  <p style={{ color: "red" }}>{error.recruiter} </p>
                )}
              </div>
              <div className={styles["right-form"]}>
                <Form.Label className={styles["form-label-required"]}>
                  <FiCheckCircle />
                  <span className="ps-1"> Approvals Required</span>
                </Form.Label>
                <Form.Select
                  name="maxApproval"
                  className={styles["form-input"]}
                  placeholder="e.g. 4"
                  value={job.maxApproval}
                  onChange={handleInputs}
                >
                  <option value="">Select</option>
                  {availableBadges.map((badge) => (
                    <option value={badge}>{badge}</option>
                  ))}
                </Form.Select>
                {error.maxApproval && (
                  <p style={{ color: "red" }}>{error.maxApproval} </p>
                )}
              </div>
              <div className={styles["right-form"]}>
                <Form.Label className={styles["form-label-required"]}>
                  <BsPerson />
                  <span className="ps-1"> Approvers Assigned</span>
                </Form.Label>
                <div>
                  {/* <pre>
                    {job.approvers?.map((approver) => (
                      <p className={styles["approver"]}>{approver.label}</p>
                    ))}
                  </pre> */}
                  {
                    <MultiSelect
                      options={approverOptions}
                      value={job.approvers}
                      onChange={(value) => {
                        console.log("a", value);
                        setJob({
                          ...job,
                          ["approvers"]: value,
                        });
                      }}
                      labelledBy="Select"
                    />
                  }
                </div>
                {error.approver && (
                  <p style={{ color: "red" }}>{error.approver} </p>
                )}
              </div>
              <div className={styles["right-form"]}>
                <Form.Label className={styles["form-label-required"]}>
                  <BsCalendar />
                  <span className="ps-1"> Expected Date of Joining </span>
                </Form.Label>
                <div className="date-picker-container">
                  <DatePicker
                    showIcon
                    minDate={new Date()}
                    selected={job.endDate}
                    onChange={(date) => {
                      setJob({ ...job, ["endDate"]: date });
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
              <div className={styles["right-form"]}>
                <Form.Label className={styles["form-label-required"]}>
                  <FiCheckCircle />
                  <span className="ps-1"> Priority</span>
                </Form.Label>
                <Form.Select
                  name="priority"
                  className={styles["form-input"]}
                  placeholder="e.g. 4"
                  value={job.priority}
                  onChange={handleInputs}
                >
                  <option>Select</option>
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                </Form.Select>
                {error.priority && (
                  <p style={{ color: "red" }}>{error.priority} </p>
                )}
              </div>
            </Form>
            <div>
              <button className={styles["submit"]} onClick={postData}>
                Submit
              </button>
              <Link to="/jobs">
                <button className={styles["cancel"]}>Cancel</button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default EditJob;
