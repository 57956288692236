import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Form, Card } from "react-bootstrap";
import { BiSearch, BiEditAlt } from "react-icons/bi";
import { FaShareSquare } from "react-icons/fa";
import Loader from "../components/loader";
import { Link, useNavigate } from "react-router-dom";
import styles from "../styles/manageEmployers.module.css";
import { BsDownload } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineDelete } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import Paginate from "../components/Pagination/pagination";
import Image from "../components/User/image";
import exportExcel from "../utils/exportExcel";

function CompanyCandidates(props) {
  const [search, setSearch] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [sortedList, setSortedList] = useState([]);
  const [slicedList, setSlicedList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sort, setSort] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams?.get("status") || "";
  const navigate = useNavigate();
  const [availableCities, setAvailableCities] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const token = localStorage.getItem("SavedToken");
  const [selectedRows, setSelectedRows] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const handleRowSelection = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const generateCandidatesReport = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/generateCandidatesReport`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },

        credentials: "include",
      });
      exportExcel(res.data, "CandidatesReport");
    } catch (err) {
      console.log(err);
    }
  };
  const handleSelectAll = () => {
    if (allSelected) {
      setAllSelected(false);
      setSelectedRows([]);
    } else {
      setAllSelected(true);
      setSelectedRows(candidates.map((row) => row._id));
    }
  };

  const handleShare = () => {
    navigator.clipboard
      .writeText(
        `${process.env.REACT_APP_CLIENT_URL}/onBoardCandidate/${props.recruiter}`
      )
      .then(() => {
        toast.success("Link Copied!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    console.log(query);
    var updatedList = [...candidates];

    updatedList = updatedList.filter(
      (item) =>
        item.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.profile?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.address?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.candidateId?.toString().indexOf(query) !== -1
    );
    setFilterList(updatedList);
    setTotal(updatedList.length);
    console.log(updatedList);
  };

  const handleSort = (field) => {
    let updatedList = [...filterList];
    updatedList.sort((a, b) => {
      let fa = a[field]?.toLowerCase(),
        fb = b[field]?.toLowerCase();
      if (fa < fb) {
        return -1 * sort;
      }
      if (fa > fb) {
        return 1 * sort;
      }
      return 0;
    });
    setFilterList(updatedList);
  };

  const handleSlice = (page, limit, total) => {
    setTotalPages(Math.ceil(total / limit));
    setSlicedList(
      filterList.slice((page - 1) * limit, Math.min(page * limit, total))
    );
  };

  useEffect(
    () => handleSlice(page, limit, total),
    [page, limit, total, filterList]
  );

  const getCandidates = async (status) => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/get${props.role}Candidates?status=${status}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      if (res.status === 200) {
        setCandidates(res.data);
        setFilterList(res.data);
        setTotal(res.data.length);
        let cities = new Set();
        res.data.map((candidate) => {
          cities.add(candidate?.city);
        });
        setAvailableCities(Array.from(cities));
      } else {
        toast.error("Error While getting Candidates", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      console.log(err);
    }
  };

  const deleteCandidate = async (selected) => {
    try {
      console.log(selected);
      const res = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_SERVER_URL}/deleteCandidates`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        data: {
          candidateIds: selected,
        },
        credentials: "include",
      });
      console.log(res);
      toast.success("Candidate Deleted Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSelectedRows([]);
      setRefresh(!refresh);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this user",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteCandidate(selectedRows);
          },
        },
        {
          label: "No",
          onClick: () => console.log("aborted"),
        },
      ],
    });
  };

  useEffect(() => {
    getCandidates(status);
  }, [refresh, status]);

  return (
    <div className={styles.table_main_container}>
      <h2 className={styles["manage-employer-head"]}>
        {status && status} Candidates
      </h2>
      <div className="d-flex justify-content-between">
        <p className={styles["manage-employer-text"]}>Manage Candidates</p>
        {props.role === "superAdmin" && (
          <button
            className={styles.generateReport}
            onClick={(e) => {
              e.preventDefault();
              generateCandidatesReport();
            }}
          >
            Generate Report
          </button>
        )}
      </div>
      <div className={styles["large-screen"]}>
        <div className="d-flex justify-content-between mx-3 me-4">
          {/* <div className={styles["search-box"]}>
            <BiSearch color="#8692A6" />
            <input
              className={styles["search-text"]}
              type="text"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div> */}
          {/* search box */}
          <div className={styles["search-box"]}>
            <BiSearch
              className={styles.icon_search_modify}
              color="#8692A6"
              size="20px"
            />
            <input
              className={styles["search-text"]}
              type="text"
              placeholder="Search for Sites, Tools or Users"
              onChange={handleSearch}
            />
          </div>
          {/* search box */}
          {selectedRows.length === 0 && (
            <div className="d-flex">
              <Form className="d-flex flex-wrap me-2">
                <div className="">
                  <Form.Select
                    aria-label="All Cities"
                    className={styles["search-filters"]}
                    onChange={handleSearch}
                  >
                    <option value="">All cities</option>
                    {availableCities?.map((city, index) => (
                      <option value={city} key={index}>
                        {city}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Form>
              {selectedRows.length == 0 &&
                props.role === "Recruiter" && (
                  <button className={styles["share-btn"]} onClick={handleShare}>
                    <FaShareSquare />
                  </button>
                ) && (
                  <Link to={`/onBoardCandidate/${props.recruiter}`}>
                    <button className={styles["add-btn"]}>+</button>
                  </Link>
                )}
            </div>
          )}

          {selectedRows.length >= 1 && (
            <div className="me-4 mt-3">
              <MdOutlineDelete
                size={32}
                color="#8692A6"
                className="me-2"
                onClick={handleDelete}
              />
            </div>
          )}
        </div>
        <div className="">
          <div className={`${styles["table-head"]} row`}>
            <div className="col-sm-1">
              <input
                type="checkbox"
                className="me-1"
                checked={allSelected}
                onChange={handleSelectAll}
              />
            </div>
            <div
              className="col-sm-1"
              onClick={() => {
                handleSort("candidateId");
                setSort(-1 * sort);
              }}
            >
              ID
            </div>
            <div
              className="col-sm-2"
              onClick={() => {
                handleSort("name");
                setSort(-1 * sort);
              }}
            >
              Name
            </div>
            <div
              className="col-sm-1"
              onClick={() => {
                handleSort("profile");
                setSort(-1 * sort);
              }}
            >
              Profile
            </div>
            <div
              className="col-sm-1"
              onClick={() => {
                handleSort("experience");
                setSort(-1 * sort);
              }}
            >
              Experience
            </div>
            <div
              className="col-sm-2"
              onClick={() => {
                handleSort("city");
                setSort(-1 * sort);
              }}
            >
              Address
            </div>
            <div
              className="col-sm-1"
              onClick={() => {
                handleSort("ectc");
                setSort(-1 * sort);
              }}
            >
              ECTC
            </div>
            <div
              className="col-sm-1"
              onClick={() => {
                handleSort("job");
                setSort(-1 * sort);
              }}
            >
              Job Applied
            </div>
            <div className="col-sm-1">CV</div>
          </div>
          {showLoader ? (
            <Loader />
          ) : slicedList.length === 0 ? (
            <h1 className="mt-5 text-center"> No Candidates Available</h1>
          ) : (
            <div className={styles["table-body"]}>
              {slicedList?.map((candidate, index) => (
                <div
                  className={`${styles["table-row"]} row`}
                  key={index}
                  onClick={() => navigate(`/candidate/${candidate._id}`)}
                >
                  <div
                    className="col-sm-1 justify-content-between"
                    style={{ color: "black" }}
                  >
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={selectedRows.includes(candidate._id)}
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => handleRowSelection(candidate._id)}
                    />
                    <Image firstName={candidate.name} size={36} />
                  </div>
                  <div className="col-sm-1">{candidate.candidateId}</div>
                  <div className="col-sm-2">{candidate.name}</div>
                  <div className="col-sm-1">{candidate.profile}</div>

                  <div className="col-sm-1">{candidate.experience}</div>
                  <div className="col-sm-2">{candidate.address}</div>
                  <div className="col-sm-1">{candidate?.ectc}</div>
                  <div className="col-sm-1">
                    {candidate.job ? candidate.job : "None"}
                  </div>
                  <div
                    className="col-sm-1"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <a href={candidate.resume}>
                      <BsDownload size={20} />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <Paginate
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          total={total}
        />
      </div>
      <div className={styles["small-screen"]}>
        <div className="d-flex justify-content-between me-2">
          <input
            className={styles["search-text"]}
            type="text"
            placeholder="Search here"
            onChange={handleSearch}
          />

          {selectedRows.length >= 1 && (
            <div className="">
              <MdOutlineDelete size={30} color="#8692A6" className="me-2" />
            </div>
          )}
        </div>

        <div className="mt-2">
          <Form className="d-flex justify-content-between flex-wrap me-2">
            <div className="">
              <Form.Select
                aria-label="All Cities"
                style={{ width: "75vw" }}
                className={styles["search-filters"]}
                onChange={handleSearch}
              >
                <option value="">All cities</option>
                {availableCities?.map((city, index) => (
                  <option value={city} key={index}>
                    {city}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Form>
        </div>

        {showLoader ? (
          <Loader />
        ) : slicedList.length === 0 ? (
          <h2 className="text-center mt-5"> No Candidate Onboarded Yet </h2>
        ) : (
          <div className="my-3">
            {slicedList.map((candidate, index) => (
              <Card
                key={index}
                className="shadow p-3 my-2"
                style={{ border: "none", borderRadius: "0px" }}
                onClick={() => navigate(`/candidate/${candidate?._id}`)}
              >
                <div className="d-flex justify-content-between mb-1">
                  <div className={styles["job-id"]}>
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={selectedRows.includes(candidate._id)}
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => handleRowSelection(candidate._id)}
                    />
                    {candidate.candidateId}
                  </div>
                  <a
                    href={candidate.resume}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <BsDownload size={20} />
                  </a>
                </div>
                <p className={styles["left"]}>{candidate.name}</p>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Profile</p>
                  <p className={styles["right"]}>{candidate.profile}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Experience</p>
                  <p className={styles["right"]}>{candidate.experience}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>City & Country</p>
                  <p className={styles["right"]}>{candidate.address}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Expected CTC</p>
                  <p className={styles["right"]}>{candidate.ectc}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Job Applied</p>
                  <p className={styles["right"]}>
                    {candidate?.job ? candidate?.job : "None"}
                  </p>
                </div>
              </Card>
            ))}
            <Paginate
              totalPages={totalPages}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              total={total}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CompanyCandidates;
