import { useState } from "react";
import { Card, Form } from "react-bootstrap";
import styles from "./profile.module.css";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { ImKey } from "react-icons/im";
import ChangePassword from "./changePassword";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DeleteUser from "./deleteUser";

function Profile() {
  const token = localStorage.getItem("SavedToken");
  const navigate = useNavigate();
  const [user, setUser] = useState({
    firstName: "", //props.user.name.split(" ")[0],
    lastName: "", //props.user.name.split(" ").slice(1).toString(),
    email: "", //props.user.email,
    mobile: "", //props.user.mobile,
    image: "",
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  });

  const validate = () => {
    let status = false;
    let err = {};

    if (user.firstName.trim() === "") {
      status = true;
      err.firstName = "First name can't be empty";
    } else if (!/^[a-zA-Z]+$/.test(user.firstName)) {
      status = true;
      err.firstName = "Invalid First Name";
    }
    if (!/^[a-zA-Z]+$/.test(user.lastName) && user.lastName !== "") {
      status = true;
      err.lastName = "Last name Invalid";
    }
    if (user.email === "") {
      status = true;
      err.email = "Email can't be empty";
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email)
    ) {
      status = true;
      err.email = "Invalid Email";
    }
    if (!/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/.test(user.mobile)) {
      status = true;
      err.mobile = "Invalid Mobile no.";
    }
    setError(err);
    return status;
  };

  const getProfile = async () => {
    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/getUser`, {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
    });
    console.log(res);
    const { name, email, mobile, image, country, state, city } = res.data;
    if (res.status === 200) {
      setUser({
        firstName: name.split(" ")[0],
        lastName: name.split(" ").slice(1).toString(),
        email,
        mobile,
        image: image ? image : "",
        country,
        state,
        city,
      });
      setSelectedCountry(
        Country.getAllCountries().find((data) => data.name === country)
      );
    }
  };

  const handleUpload = async (image) => {
    try {
      if (image) {
        const formData = new FormData();
        formData.append("file", image);

        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/uploadImage`,
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();
        setUser({ ...user, image: data.url });
        console.log(user.image);
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("Error while uploading", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const submit = async () => {
    if (!validate()) {
      try {
        const { firstName, lastName, email, mobile, image } = user;
        const res = await axios({
          method: "put",
          url: `${process.env.REACT_APP_SERVER_URL}/updateUserDetails`,
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
          data: {
            firstName,
            lastName,
            email,
            mobile,
            image,
            country: selectedCountry?.name,
            state: selectedState?.name,
            city: selectedCity?.name,
          },
        });
        const data = res.data;
        console.log("Updated", data);
        if (res.status === 200) {
          console.log("updated");
          toast.dismiss();
          toast.success("Updated Successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
        toast.dismiss();
        toast.error("Error while Updating Data", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  let name, value;
  const handleInput = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
    console.log(user, value);
  };

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    getProfile();
  }, []);
  useEffect(() => {
    console.log(selectedState);
    setSelectedState(
      State.getStatesOfCountry(selectedCountry?.isoCode).find(
        (data) => data.name === user.state
      )
        ? State.getStatesOfCountry(selectedCountry?.isoCode).find(
            (data) => data.name === user.state
          )
        : null
    );
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedCity(
      City.getCitiesOfState(
        selectedState?.countryCode,
        selectedState?.isoCode
      ).find((data) => data.name === user.city)
        ? City.getCitiesOfState(
            selectedState?.countryCode,
            selectedState?.isoCode
          ).find((data) => data.name === user.city)
        : null
    );
  }, [selectedState]);
  return (
    <div>
      <Card className={styles["profile"]}>
        <div className={styles["photo-options"]}>
          {user.image === "" ? (
            <div className={`${styles["profile-icon"]}`}>
              <p className={styles["profile-name"]}>{user.firstName[0]}</p>
            </div>
          ) : (
            <div className={styles["profile-icon"]}>
              <img src={user.image} alt="asd" className="img-fluid" />
            </div>
          )}
          <div className={styles["photo-right"]}>
            <p className={styles["head"]}>Profile Picture</p>
            <p className={styles["type"]}>Upload (.jpg, .jpeg, .png file)</p>
            <div className="d-flex">
              <input
                type="file"
                className={styles["image"]}
                accept=".jpg, .jpeg, .png"
                onChange={(e) => handleUpload(e.target.files[0])}
              />
              <button
                className={styles["change"]}
                onClick={() => {
                  setUser({ ...user, image: "" });
                }}
              >
                Remove Image
              </button>
            </div>
          </div>
        </div>
        <p className={styles["personal-information"]}>Personal Information</p>
        <hr />
        <div className="d-flex justify-content-between">
          <div>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name="firstName"
              placeholder="e.g. John"
              value={user.firstName}
              onChange={handleInput}
            />
            {error.firstName ? (
              <p style={{ color: "red" }}>{error.firstName} </p>
            ) : null}
          </div>
          <div>
            <Form.Label className="ms-2">Last Name</Form.Label>
            <Form.Control
              name="lastName"
              className="ms-2"
              placeholder="e.g. Doe"
              value={user.lastName}
              onChange={handleInput}
            />
            {error.lastName ? (
              <p style={{ color: "red" }}>{error.lastName} </p>
            ) : null}
          </div>
        </div>
        <div className="mt-2">
          <Form.Label className="">Email</Form.Label>
          <Form.Control
            name="email"
            className=""
            placeholder="e.g. johndoe@gmail.com"
            value={user.email}
            onChange={handleInput}
          />
          {error.email ? <p style={{ color: "red" }}>{error.email} </p> : null}
        </div>
        <div>
          <Form.Label className="mt-2">Mobile</Form.Label>
          <Form.Control
            name="mobile"
            className=""
            placeholder="e.g. 9451354864"
            value={user.mobile}
            onChange={handleInput}
            maxLength={10}
          />
          {error.mobile ? (
            <p style={{ color: "red" }}>{error.mobile} </p>
          ) : null}
        </div>
        <p className={styles["personal-information"]}>Other Information</p>
        <hr />
        <div>
          <Form.Label className={styles["candidate-labels"]}>
            Country
          </Form.Label>
          <Select
            options={Country.getAllCountries()}
            getOptionLabel={(options) => {
              return options["name"];
            }}
            getOptionValue={(options) => {
              return options["name"];
            }}
            value={selectedCountry}
            onChange={(item) => {
              setSelectedCountry(item);
            }}
          />
        </div>
        <div className="mt-2">
          <Form.Label className={styles["candidate-labels"]}>State</Form.Label>
          <Select
            options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
            getOptionLabel={(options) => {
              return options["name"];
            }}
            getOptionValue={(options) => {
              return options["name"];
            }}
            value={selectedState}
            onChange={(item) => {
              setSelectedState(item);
            }}
          />
        </div>
        <div className="mt-2">
          <Form.Label className={styles["candidate-labels"]}>City</Form.Label>
          <Select
            options={City.getCitiesOfState(
              selectedState?.countryCode,
              selectedState?.isoCode
            )}
            getOptionLabel={(options) => {
              return options["name"];
            }}
            getOptionValue={(options) => {
              return options["name"];
            }}
            value={selectedCity}
            onChange={(item) => {
              setSelectedCity(item);
            }}
          />
        </div>
        <div className={styles["password-container"]}>
          <ImKey size={20} />
          <div>
            <p className={styles["password"]}>Password</p>
            <p className={styles["password-text"]}>
              Doubt with your current Password? Set your new password
            </p>
          </div>
          <ChangePassword />
        </div>
        <p className={styles["delete-text"]}>
          You will not be able to access all your data on the RareTalent app
          after you confirm this action.
        </p>
        <div>
          <DeleteUser />
        </div>
        <div className="mt-3">
          <button
            className={styles["cancel"]}
            onClick={() => navigate("/admin")}
          >
            Cancel
          </button>
          <button className={styles["update"]} onClick={submit}>
            Update
          </button>
        </div>
      </Card>
    </div>
  );
}

export default Profile;
