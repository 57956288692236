import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import styles from "../../styles/login.module.css";
import { AiFillEye } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loader";

function ResetPassword(props) {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({ newPassword: "", confirmPassword: "" });

  const validate = () => {
    let err = {};
    let status = false;
    if (props.newPassword.trim() === "") {
      status = true;
      err.newPassword = "Password can't be empty";
    } else if (
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(
        props.newPassword
      )
    ) {
      status = true;
      err.newPassword = "Password must be strong between 6 to 16 characters";
    }
    if (props.newPassword !== confirmPassword) {
      status = true;
      err.confirmPassword = "Password and Confirm password should match";
    }
    setError(err);
    return status;
  };
  return (
    <div>
      <h2 className={styles["login-heading"]}>Reset Passsword</h2>
      <p className={styles["login-text"]}>
        Your password must be different from your previous passwords
      </p>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className={styles["label"]}>
            Enter new password
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={props.newPassword}
            onChange={(e) => {
              props.setNewPassword(e.target.value);
            }}
          />
          {error.newPassword ? (
            <p style={{ color: "red" }}>{error.newPassword} </p>
          ) : null}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label className={styles["label"]}>Confirm Password </Form.Label>
          <div className="d-flex border rounded">
            <Form.Control
              type={props.passwordType}
              className={styles["password"]}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />

            <button
              className={styles["password-toogle"]}
              onClick={props.togglePassword}
            >
              <AiFillEye />
            </button>
          </div>
          {error.confirmPassword ? (
            <p style={{ color: "red" }}>{error.confirmPassword} </p>
          ) : null}
        </Form.Group>

        <button
          className={`${styles["login-btn"]} px-3`}
          onClick={(e) => {
            e.preventDefault();
            if (!validate()) props.resetPassword(e);
          }}
          disabled={props.disable}
        >
          Reset Password
        </button>
        <button
          className={`${styles["cancel-btn"]} px-3`}
          onClick={() => {
            window.location.reload(false);
          }}
        >
          Cancel
        </button>
        {props.showLoader && <Loader />}
      </Form>
    </div>
  );
}

export default ResetPassword;
