import axios from "axios";
import { toast } from "react-toastify";

const serverURL = process.env.REACT_APP_SERVER_URL;
const token = localStorage.getItem("SavedToken");

export const getJobCities = async () => {
  try {
    const res = await axios.get(`${serverURL}/getCities`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const postComment = async (jobId, content) => {
  try {
    const response = await fetch(`${serverURL}/commentJD`, {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: token },
      body: JSON.stringify({ job: jobId, content }),
    });

    return response.data;
  } catch (error) {
    console.log(error);
    toast.dismiss();
    toast.error("Error while adding comment!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const postCandidateComment = async (candidateId, content) => {
  try {
    console.log("#########", token);
    const response = await fetch(`${serverURL}/commentCandidate`, {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: token },
      body: JSON.stringify({ candidate: candidateId, content }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    toast.dismiss();
    toast.error("Error while adding comment!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};
