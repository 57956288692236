import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Card } from "react-bootstrap";
import { BiSearch, BiEditAlt } from "react-icons/bi";
import { FaShareSquare } from "react-icons/fa";
import Loader from "../components/loader";
import { Link, useNavigate } from "react-router-dom";
import styles from "../styles/manageEmployers.module.css";
import { BsDownload } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineDelete } from "react-icons/md";

function AvailableCandidates(props) {
  const { jid } = useParams();

  const [candidates, setCandidates] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const token = localStorage.getItem("SavedToken");
  const [selectedRows, setSelectedRows] = useState([]);
  const [job, setJob] = useState({});
  const [allSelected, setAllSelected] = useState(false);
  const recruiter = props.rid;
  const handleRowSelection = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setAllSelected(false);
      setSelectedRows([]);
    } else {
      setAllSelected(true);
      setSelectedRows(candidates.map((row) => row._id));
    }
  };

  const handleEdit = () => {
    navigate(`/editJob/${selectedRows[0]}`);
  };

  // const getRecruiter = async () => {
  //   try {
  //     const res = await axios({
  //       method: "get",
  //       url: `${process.env.REACT_APP_SERVER_URL}/getRecruiterByToken`,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         authorization: token,
  //       },
  //       credentials: "include",
  //     });
  //     console.log(res);
  //     setRecruiter(res.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleSearch = (e) => {
    const query = e.target.value;
    console.log(query);
    var updatedList = [...candidates];

    updatedList = updatedList.filter(
      (item) =>
        item.candidate?.name?.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        item.candidate?.desiredJobtitle
          ?.toLowerCase()
          .indexOf(query.toLowerCase()) !== -1 ||
        item.candidate?.city?.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        item.candidate?.candidateId?.toString().indexOf(query) !== -1 ||
        item.candidate?.email?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    setFilterList(updatedList);
    console.log(updatedList);
  };

  const getCandidates = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getAvailableCandiddates`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      if (res.status === 200) {
        setCandidates(res.data);
        setFilterList(res.data);
      }
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      console.log(err);
    }
  };

  const getJobDetails = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getJob/${jid}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      setJob(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleShare = () => {
    navigator.clipboard
      .writeText(
        `${process.env.REACT_APP_CLIENT_URL}/onBoardCandidate/${jid}/${recruiter._id}`
      )
      .then(() => {
        toast.success("Link Copied!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const selectCandidates = async () => {
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_SERVER_URL}/candidates/updatejob`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        data: {
          candidateIds: selectedRows,
          jobId: jid,
        },
        credentials: "include",
      });
      console.log(res);
      if (res.status === 201) {
        toast.success("User Added Successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setRefresh(!refresh);
      } else {
        toast.error("Error while Updating", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteEmployers = async (selected) => {
    try {
      console.log(selected);
      const res = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_SERVER_URL}/deleteJobs`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        data: {
          jobIds: selected,
        },
        credentials: "include",
      });
      console.log(res);
      toast.success("🦄 User Deleted Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSelectedRows([]);
      setRefresh(!refresh);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this user",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteEmployers(selectedRows);
          },
        },
        {
          label: "No",
          onClick: () => console.log("aborted"),
        },
      ],
    });
  };

  useEffect(() => {
    getCandidates();
    // getRecruiter();
    getJobDetails();
  }, [refresh]);

  return (
    <div>
      <h2 className={styles["manage-employer-head"]}>Available Candidates</h2>
      <p className={styles["manage-employer-text"]}>
        Select from given candidates or add new candidate{" "}
      </p>

      <div className="row me-0">
        <p className={`col-md-3 ${styles["manage-employer-text"]}`}>
          <strong>Job Role:</strong> {job.title}
        </p>
        <p className={`col-md-3 ${styles["manage-employer-text"]}`}>
          <strong>Company:</strong> {job.companyName}
        </p>
        <p className={`col-md-3 ${styles["manage-employer-text"]}`}>
          <strong>Location:</strong> {job.employer?.country}
        </p>
      </div>
      <div className={styles["large-screen"]}>
        <div className="d-flex justify-content-between mx-3 me-4">
          <div className={styles["search-box"]}>
            <BiSearch color="#8692A6" />
            <input
              className={styles["search-text"]}
              type="text"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
          {selectedRows.length === 0 && (
            <div className="d-flex">
              <Form className="d-flex flex-wrap me-2">
                <div className="">
                  <Form.Select
                    aria-label="All Cities"
                    className={styles["search-filters"]}
                    onChange={handleSearch}
                  >
                    <option value="">All cities</option>
                    {candidates?.map((candidate, index) => (
                      <option value={candidate.candidate?.city}>
                        {candidate.candidate?.city}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Form>
              <button className={styles["share-btn"]} onClick={handleShare}>
                <FaShareSquare />
              </button>
              <Link to={`/onBoardCandidate/${jid}/${recruiter._id}`}>
                <button className={styles["add-btn"]}>+</button>
              </Link>
            </div>
          )}
          {selectedRows.length >= 1 && (
            <div className="d-flex me-4 mt-3">
              <button
                className={styles["select-btn"]}
                onClick={selectCandidates}
              >
                Select
              </button>
            </div>
          )}
        </div>
        <div className="mx-3 mx-sm-4">
          <div className={`${styles["table-head"]} row`}>
            <div className="col-sm-1">
              <input
                type="checkbox"
                className="me-1"
                checked={allSelected}
                onChange={handleSelectAll}
              />
              ID
            </div>
            <div className="col-sm-2">Name</div>
            <div className="col-sm-1">Profile</div>
            <div className="col-sm-1">Experience</div>
            <div className="col-sm-2">City and Country</div>
            <div className="col-sm-1">ECTC</div>
            <div className="col-sm-2">Job Applied</div>
            <div className="col-sm-1">CV</div>
          </div>

          {showLoader ? (
            <Loader />
          ) : filterList.length === 0 ? (
            <h2 className="mt-5 text-center"> No Candidates Available</h2>
          ) : (
            <div className={styles["table-body"]}>
              {filterList?.map((candidate, index) => (
                <div
                  className={`${styles["table-row"]} row`}
                  key={index}
                  // onClick={() => navigate(`/jobCandidates/${candidate?.candidate?._id}`)}
                >
                  <div className="col-sm-1">
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={selectedRows.includes(candidate?.candidate?._id)}
                      onChange={() =>
                        handleRowSelection(candidate?.candidate?._id)
                      }
                    />
                    {candidate?.candidate?.candidateId}
                  </div>
                  <div className="col-sm-2">{candidate?.candidate?.name}</div>
                  <div className="col-sm-1">
                    {candidate?.candidate?.desiredJobtitle}
                  </div>

                  <div className="col-sm-1">
                    {candidate?.candidate?.experience}
                  </div>
                  <div className="col-sm-2">
                    <p className="d-inline">{candidate?.candidate?.city} </p>
                    <p className="d-inline">{candidate?.candidate?.country} </p>
                  </div>
                  <div className="col-sm-1">
                    {candidate?.candidate?.desiredJobexpectedCTC}
                  </div>
                  <div className="col-sm-2">
                    {candidate.job ? candidate.job.title : "None"}
                  </div>
                  <div className="col-sm-1">
                    <a href={candidate?.candidate?.resume}>
                      <BsDownload size={20} />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles["small-screen"]}>
        <div className="d-flex justify-content-between me-2">
          <input
            className={styles["search-text"]}
            type="text"
            placeholder="Search here"
            onChange={handleSearch}
          />
          {selectedRows.length === 0 && (
            <Link to={`/onBoardCandidate/${jid}/${recruiter._id}`}>
              <button className={styles["add-btn"]}>+</button>
            </Link>
          )}
          {selectedRows.length === 1 && (
            <div className="d-flex">
              <BiEditAlt size={30} color="#8692A6" onClick={handleEdit} />
              <MdOutlineDelete
                size={30}
                color="#8692A6"
                onClick={handleDelete}
              />
            </div>
          )}
          {selectedRows.length > 1 && (
            <div className="">
              <MdOutlineDelete size={30} color="#8692A6" className="me-2" />
            </div>
          )}
        </div>
        {selectedRows.length === 0 && (
          <div className="mt-2">
            <Form className="d-flex justify-content-between flex-wrap me-2">
              <Form.Select
                aria-label="All Cities"
                className={styles["search-filters"]}
                style={{ width: "75vw" }}
              >
                <option value="">All cities</option>
                {candidates?.map((candidate, index) => (
                  <option value={candidate.candidate?.city}>
                    {candidate.candidate?.city}
                  </option>
                ))}
              </Form.Select>
            </Form>
          </div>
        )}
        {showLoader ? (
          <Loader />
        ) : filterList.length === 0 ? (
          <h2 className="text-center mt-5"> No candidate found </h2>
        ) : (
          <div className="my-3">
            {filterList.map((candidate, index) => (
              <Card
                key={index}
                className="shadow p-3 my-2
            "
              >
                <div className="d-flex justify-content-between mb-1">
                  <div className={styles["job-id"]}>
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={selectedRows.includes(candidate?.candidate?._id)}
                      onChange={() =>
                        handleRowSelection(candidate?.candidate?._id)
                      }
                    />
                    {candidate?.candidate?.candidateId}
                  </div>
                  <a href={candidate?.candidate?.resume}>
                    <BsDownload size={20} />
                  </a>
                </div>
                <p className={styles["left"]}>{candidate.candidate.name}</p>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Profile</p>
                  <p className={styles["right"]}>
                    {candidate?.candidate?.desiredJobtitle}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Experience</p>
                  <p className={styles["right"]}>
                    {candidate?.candidate?.experience}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>City & Country</p>
                  <p className={styles["right"]}>
                    {candidate?.candidate?.city} {candidate?.candidate?.country}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Expected CTC</p>
                  <p className={styles["right"]}>
                    {candidate?.candidate?.desiredJobexpectedCTC}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Job Applied</p>
                  <p className={styles["right"]}>
                    {candidate?.job ? candidate?.job?.title : "None"}
                  </p>
                </div>
              </Card>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AvailableCandidates;
