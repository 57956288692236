import React from "react";
import { Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";

function PieChart(props) {
  const navigate = useNavigate();

  const handleOnClick = (event, i) => {
    const index = i[0]?.index;
    const label = event.chart.data.labels[index];
    navigate(`/candidates?status=${label}`);
  };
  
  const data = {
    labels: props.statuses,
    datasets: [
      {
        label: "Candidates",
        data: props.data,
        backgroundColor: [
          "#F2213A",
          "#2186F2",
          "#4E36E2",
          "#1BD084",
          "#FFE340",
          "#a42da9",
          "orange",
        ],
      },
    ],
  };
  return (
    <div className="p-2">
      <p className="px-2">Recruitment Analysis</p>
      <Pie
        data={data}
        options={{
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                fontColor: "#000",
                usePointStyle: true,
              },
            },
          },
          onClick: handleOnClick,
        }}
      />
    </div>
  );
}

export default PieChart;
