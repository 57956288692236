import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import styles from "../styles/createUser.module.css";
import { Form, Button } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import MultiSelectDropdown from "./multiselectDropdown";
import { AiOutlinePlus } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "./appContext";

function AddTemplate(props) {
  const myContext = useContext(AppContext);
  const token = localStorage.getItem("SavedToken");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [template, setTemplate] = useState({
    title: "",
    type: "",
    description: "",
    preference: "",
  });
  const [selected, setSelected] = useState([]);
  let name, value;

  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setTemplate({ ...template, [name]: value });
    console.log(template, value);
  };

  const postData = async () => {
    setButtonLoading(true);
    const { title, type, description, preference } = template;
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/createJobTemplate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          title,
          skills: selected,
          type,
          description,
          preference,
        }),
      }
    );
    const data = await res.json();
    if (res.status === 201) {
      console.log("Successfully Reggistration", data);
      console.log(props.refresh);
      props.setRefresh(!props.refresh);
      toast.success("Added Successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTemplate({
        title: "",
        type: "",
        description: "",
        preference: "",
      });
      setSelected([]);
    } else {
      toast.error("Error while Adding", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(data.message);
    }
  };
  return (
    <div>
      <Popup
        trigger={
          <button className={styles["add-button"]}>
            <AiOutlinePlus size={22} color="white" />
          </button>
        }
        modal
        nested
      >
        {(close) => (
          <div className={styles["modal"]}>
            <button
              className={styles["close"]}
              onClick={() => {
                close();
              }}
            >
              &times;
            </button>
            <div className={styles["header"]}> Add Job Template </div>
            <div className={styles["popup-content"]}>
              <Form className="row justify-content-center m-1">
                <Form.Label className={styles["form-label"]}>
                  Job Title
                </Form.Label>
                <Form.Control
                  name="title"
                  value={template.title}
                  onChange={handleInputs}
                  className={styles["form-input"]}
                  placeholder="e.g. Software Engineer Trainee"
                />

                <Form.Label className={styles["form-label"]}>
                  Skills required
                </Form.Label>
                <MultiSelectDropdown value={{ selected, setSelected }} />

                <Form.Label className={styles["form-label"]}>
                  Job Type
                </Form.Label>
                <div className="d-flex">
                  <Form.Check
                    inline
                    value="In-Office"
                    onChange={handleInputs}
                    type={"radio"}
                    id={`in-officet`}
                    label={`In-office`}
                    name={"type"}
                    className={styles["radio"]}
                  />
                  <Form.Check
                    inline
                    value="Remote"
                    onChange={handleInputs}
                    type={"radio"}
                    id={`remotet`}
                    label={`Remote`}
                    name={"type"}
                    className={styles["radio"]}
                  />
                  <Form.Check
                    inline
                    value="Hybrid"
                    onChange={handleInputs}
                    type={"radio"}
                    id={`hybridt`}
                    label={`Hybrid`}
                    name={"type"}
                    className={styles["radio"]}
                  />
                </div>
                <Form.Label className={styles["form-label"]}>
                  Job Description
                </Form.Label>
                <Form.Control
                  value={template.description}
                  onChange={handleInputs}
                  name="description"
                  className={styles["form-input"]}
                  placeholder="e.g. Key Responsibilities
                1.
                2.
                3."
                  as="textarea"
                  rows={4}
                />

                <Form.Label className={styles["form-label"]}>
                  Do you have any candidate preference?{" "}
                  <span style={{ color: "silver" }}>(Optional)</span>
                </Form.Label>
                <Form.Control
                  value={template.preference}
                  onChange={handleInputs}
                  name="preference"
                  className={styles["form-input"]}
                  placeholder="e.g. Only Computer Science graduate preferred"
                  rows={2}
                  as="textarea"
                />

                <div className="mb-0 d-flex justify-content-center">
                  {buttonLoading ? (
                    <Button className={styles["login-button"]} disabled>
                      Save
                    </Button>
                  ) : (
                    <Button
                      className={styles["login-button"]}
                      onClick={(e) => {
                        e.preventDefault();
                        postData();
                        setTimeout(() => {
                          close();
                          setButtonLoading(false);
                        }, 2000);
                      }}
                    >
                      Save
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default AddTemplate;
