import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import styles from "../styles/createUser.module.css";

import "react-toastify/dist/ReactToastify.css";

function CandidateRating(props) {
  return (
    <div>
      <Popup
        trigger={
          <button
            className={styles["score-btn"]}
            onClick={(e) => e.stopPropagation()}
          >
            {props.score}
          </button>
        }
        modal
      >
        {(close) => (
          <div className={styles["modal"]}>
            <div className={styles.box_container_new}>
              <h4>
                <div className={styles["header"]}> {props.name}'s Review</div>
              </h4>
              <button className={styles["close"]} onClick={close}>
                &times;
              </button>
            </div>
            <div className={styles["popup-content"]}>
              <p className={styles.review}>{props.review} </p>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default CandidateRating;
