import { AppContext } from "../components/appContext";
import { useContext } from "react";


const useCheckPermissions = (name) => {
  const myContext = useContext(AppContext);
  let res = false;
  console.log(myContext?.user?.role.allowed_permissions);
  if (
    myContext?.user?.role.allowed_permissions.find(
      (permission) => permission.name === name
    ) === undefined
  ) {
    return res;
  } else {
    res = true;
    return res;
  }
};

export default useCheckPermissions;
