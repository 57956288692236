import axios from "axios";
import { useContext, useState } from "react";
import { useEffect } from "react";
import useCheckPermissions from "../utils/helpers";
import { Form, Card } from "react-bootstrap";
import Loader from "../components/loader";
import { BiSearch, BiEditAlt } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import styles from "../styles/manageEmployers.module.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineDelete } from "react-icons/md";
import { getJobCities } from "../api/jobs";
import { MultiSelect } from "react-multi-select-component";
import Paginate from "../components/Pagination/pagination";
import Image from "../components/User/image";
import Switch from "react-switch";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import ReactSwitch from "react-switch";
import Table from "../components/Table/Table";
import Calendar from "react-calendar";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { CgChevronUpO } from "react-icons/cg";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Jobs(props) {
  // date picker
  const [value, onChange] = useState("");
  const [startDate, setStartDate] = useState();

  const [jobs, setJobs] = useState([]);
  const isEditor = useCheckPermissions("manage_jd");
  const [filterJob, setFilterJob] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const [filterCities, setFilterCities] = useState([]);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState({ sort: "_id", order: "desc" });
  const [search, setSearch] = useState("");
  const [availableCities, setAvailableCities] = useState([]);
  const [active, setActive] = useState("All");
  const [showLoader, setShowLoader] = useState(true);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const token = localStorage.getItem("SavedToken");
  const [selectedRows, setSelectedRows] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const ArrowRenderer = ({ expanded }) => (
    <>
      {expanded ? <FiChevronUp size={18.5} /> : <FiChevronDown size={18.5} />}
    </>
  );

  const handleRowSelection = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setAllSelected(false);
      setSelectedRows([]);
    } else {
      setAllSelected(true);
      setSelectedRows(jobs.map((row) => row._id));
    }
  };

  const handleEdit = () => {
    navigate(`/editJob/${selectedRows[0]}`);
  };

  const getAvailableCities = async () => {
    const res = await getJobCities();
    setAvailableCities(res);
  };

  const getJobs = async () => {
    try {
      const cities = [];
      filterCities.map((city) => cities.push(city.value));
      // setShowLoader(true);
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getJobs?page=${page}&sort=${
          sort.sort
        },${
          sort.order
        }&cities=${cities.toString()}&search=${search}&active=${active}&limit=${limit}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      // console.log(res);
      setJobs(res.data.jobs);
      setTotalPages(res.data.totalPages);
      setFilterJob(res.data.jobs);
      setTotalJobs(res.data.totalJobs);
      setShowLoader(false);
    } catch (err) {
      console.log(err);
      setShowLoader(false);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
  };

  const deleteEmployers = async (selected) => {
    try {
      console.log(selected);
      const res = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_SERVER_URL}/deleteJobs`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        data: {
          jobIds: selected,
        },
        credentials: "include",
      });
      console.log(res);
      toast.success("Jobs Deleted Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSelectedRows([]);
      setRefresh(!refresh);
    } catch (err) {
      console.log(err);
    }
  };
  const handleToogle = async (jid, index) => {
    try {
      const res = await axios({
        method: "patch",
        url: `${process.env.REACT_APP_SERVER_URL}/toogleJob/${jid}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      if (res.status === 201) {
        console.log("Updated successfully");
        // setRefresh(!refresh);
        getJobs();
        // setJobs()
        toast.dismiss();
        toast.success("Status Updated!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      // setRefresh(!refresh);
    } catch (err) {
      toast.error("You are not authorized!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(err);
      // setRefresh(!refresh);
    }
  };

  const handleDelete = async () => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this job",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteEmployers(selectedRows);
          },
        },
        {
          label: "No",
          onClick: () => console.log("aborted"),
        },
      ],
    });
  };

  useEffect(() => {
    getAvailableCities();
  }, []);

  useEffect(() => {
    getJobs();
  }, [refresh, search, filterCities, active, page, limit, sort]);

  return (
    <div className={styles.table_main_container}>
      {/* <Table /> */}
      {/* sahil */}
      <div className={styles.header_front}>
        <div className={styles.header_box}>
          <div className={styles.header_left}>
            <div className={styles.heading}>Manage Jobs</div>
            <div className={styles.subheading}>
              An Overview of all your jobs over the year.
            </div>
          </div>
          <div className={styles.header_right}>
            {selectedRows.length === 0 && (
              <div className={styles.header_right}>
                <Form className="d-flex flex-wrap">
                  {/* date picker */}
                  {/* <div className={styles.date_picker}>
                    <DatePicker
                      showIcon
                      color="black"
                      placeholderText="Date "
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div> */}
                  <div className={styles.drop_down_header}>
                    <MultiSelect
                      options={availableCities}
                      value={filterCities}
                      className={styles["job-multi"]}
                      ArrowRenderer={ArrowRenderer}
                      onChange={(value) => {
                        setFilterCities(value);
                      }}
                      valueRenderer={() => {
                        return <p className={styles["black"]}>Select Cities</p>;
                      }}
                    />
                  </div>
                  <div className={styles.header_status}>
                    <Form.Select
                      aria-label="All Status"
                      className={styles["search-filters"]}
                      value={active}
                      onChange={(e) => setActive(e.target.value)}
                    >
                      <option value="All">All Status</option>
                      <option value={1}>Active</option>
                      <option value={0}>Inactive</option>
                    </Form.Select>
                  </div>
                </Form>
              </div>
            )}
            <Link style={{ textDecoration: "none" }} to={"/createJob"}>
              <button className={styles.add_new_user}>
                <span style={{ fontSize: "30px", paddingRight: "10px" }}>
                  +
                </span>{" "}
                Add New Job
              </button>
            </Link>
          </div>
        </div>

        {/* search box */}
        <div className={styles["search-box"]}>
          <BiSearch
            className={styles.icon_search_modify}
            color="#8692A6"
            size="20px"
          />
          <input
            className={styles["search-text"]}
            type="text"
            placeholder="Search for Sites, Tools or Users"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {/* search box */}
      </div>
      {/* sahil */}

      <div className={styles["large-screen"]}>
        <div className="d-flex justify-content-end mx-3 me-4">
          {useCheckPermissions("manage_jd") && selectedRows.length === 1 && (
            <div className="d-flex me-4 mt-3">
              <BiEditAlt size={30} color="#8692A6" onClick={handleEdit} />
              <MdOutlineDelete
                size={30}
                color="#8692A6"
                onClick={handleDelete}
              />
            </div>
          )}
          {useCheckPermissions("manage_jd") && selectedRows.length > 1 && (
            <div className="me-4 mt-3">
              <MdOutlineDelete
                size={30}
                color="#8692A6"
                className="me-2"
                onClick={handleDelete}
              />
            </div>
          )}
        </div>
        <div className="">
          <div className={`${styles["table-head"]} rows`}>
            <div className="col-sm-1">
              {useCheckPermissions("manage_jd") && (
                <input
                  type="checkbox"
                  className="me-2"
                  checked={allSelected}
                  onChange={handleSelectAll}
                />
              )}
            </div>
            <div
              className="col-sm-1"
              onClick={() =>
                setSort({
                  sort: "_id",
                  order: sort.order === "asc" ? "desc" : "asc",
                })
              }
            >
              ID
            </div>
            <div
              className="col-sm-2"
              onClick={() =>
                setSort({
                  sort: "title",
                  order: sort.order === "asc" ? "desc" : "asc",
                })
              }
            >
              Job Title
            </div>
            <div className="col-sm-2">Account Manager</div>
            <div className="col-sm-2">Recruiter</div>
            <div className="col-sm-2">City & State</div>
            <div className="col-sm-1">Customer</div>
            <div
              className="col-sm-1"
              onClick={() =>
                setSort({
                  sort: "isActive",
                  order: sort.order === "asc" ? "desc" : "asc",
                })
              }
            >
              <div className={styles.header_status_center}>Status</div>
            </div>
          </div>

          {showLoader ? (
            <Loader />
          ) : jobs.length === 0 ? (
            <h2 className="text-center mt-5"> No Data Available</h2>
          ) : (
            <div className={styles["table-body"]}>
              {jobs.map((job, index) => (
                <div
                  className={`${styles["table-row"]} row`}
                  key={index}
                  onClick={(e) => {
                    navigate(`/jobCandidates/${job._id}`);
                  }}
                >
                  <div className="col-sm-1 d-flex justify-content-between align-items-center">
                    {isEditor && (
                      <input
                        type="checkbox"
                        className="me-2"
                        checked={selectedRows.includes(job._id)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => handleRowSelection(job._id)}
                      />
                    )}
                    <Image
                      image={job.employer.logo}
                      firstName={job.employer.companyName}
                      size={36}
                    />
                  </div>
                  <div className="col-sm-1" style={{ color: "black" }}>
                    {job.jobId}
                  </div>

                  <div className="col-sm-2">{job.title}</div>
                  <div className="col-sm-2">{job.employer?.name}</div>
                  <div className="col-sm-2">
                    <div>
                      {" "}
                      {job.recruiters.map((recruiter, index) => (
                        <div key={index}>{recruiter.companyName}</div>
                      ))}
                    </div>
                  </div>

                  <div className="col-sm-2">
                    {job.city} {job.state}
                  </div>
                  <div className="col-sm-1">{job.companyName}</div>

                  <Form
                    className="col-sm-1 text-start "
                    onClick={(e) => e.stopPropagation()}
                  >

                    <label htmlFor="material-switch">
                      {/* <span>Switch with style inspired by Material Design</span> */}
                      <Switch
                        checked={job.isActive}
                        onChange={(checked, e, id) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleToogle(job._id, index);
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onColor="#E4CFFF"
                        // onColor="#86d3ff"
                        onHandleColor="#2186F2"
                        handleDiameter={18}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={12.23}
                        width={30}
                        className="react-switch"
                        id="material-switch"
                      />
                    </label>
                  </Form>
                </div>
              ))}
            </div>
          )}
          <div>
            <Paginate
              totalPages={totalPages}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              total={totalJobs}
            />
          </div>
        </div>
      </div>
      <div className={styles["small-screen"]}>
        <div className={styles.job_container_modify}>
          <div className="d-flex justify-content-between me-2">
            <input
              className={styles["search-text"]}
              type="text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search"
            />
            {selectedRows.length === 0 && (
              <Link to={"/createJob"}>
                <button className={styles["add-btn"]}>+</button>
              </Link>
            )}
            {selectedRows.length === 1 && (
              <div className="d-flex">
                <BiEditAlt size={30} color="#8692A6" onClick={handleEdit} />
                <MdOutlineDelete
                  size={30}
                  color="#8692A6"
                  onClick={handleDelete}
                />
              </div>
            )}

            {selectedRows.length > 1 && (
              <div className=" mt-3">
                <MdOutlineDelete size={30} color="#8692A6" className="me-2" />
              </div>
            )}
          </div>

          <div className="mt-2">
            <Form className="d-flex justify-content-between flex-wrap me-2">
              <div className="">
                <MultiSelect
                  options={availableCities}
                  value={filterCities}
                  className={styles["job-multi"]}
                  onChange={(value) => {
                    setFilterCities(value);
                  }}
                  valueRenderer={(selected) => {
                    if (!selected.length)
                      return <p className={styles["black"]}>Select Cities</p>;
                  }}
                />
              </div>
              <div className="">
                <Form.Select
                  aria-label="All Status"
                  className={styles["search-filters"]}
                  value={active}
                  onChange={(e) => {
                    setActive(e.target.value);
                  }}
                >
                  <option value="All">All Status</option>
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </Form.Select>
              </div>
            </Form>
          </div>
        </div>

        {showLoader ? (
          <Loader />
        ) : filterJob.length === 0 ? (
          <h2 className="text-center mt-5"> No Job Found</h2>
        ) : (
          <div className="my-3">
            {filterJob.map((job, index) => (
              <Card
                style={{ border: "none", borderRadius: "0px" }}
                key={index}
                className="p-3 my-2"
                onClick={() => {
                  navigate(`/jobCandidates/${job._id}`);
                }}
              >
                <div className="d-flex justify-content-between">
                  <div className={styles["job-id"]}>
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={selectedRows.includes(job._id)}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={() => {
                        handleRowSelection(job._id);
                      }}
                    />
                    {job.jobId}
                  </div>
                  <Form className="col-sm-1 text-end">
                    {/* <Form.Check
                      type="switch"
                      id="custom-switch"
                      value={new Date(job.endDate) >= new Date()}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToogle(job._id);
                      }}
                      defaultChecked={new Date(job.endDate) >= new Date()}
                    /> */}

                    <label htmlFor="material-switch">
                      {/* <span>Switch with style inspired by Material Design</span> */}
                      <Switch
                        checked={job.isActive}
                        onChange={(checked, e, id) => {
                          handleToogle(job._id, index);
                        }}
                        onColor="#E4CFFF"
                        onHandleColor="#2186F2"
                        handleDiameter={18}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={12.23}
                        width={30}
                        className="react-switch"
                        id="material-switch"
                      />
                    </label>
                  </Form>
                </div>
                <p className={styles["left"]}>{job.title}</p>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Account Manager</p>
                  <p className={styles["right"]}>{job.employer?.name}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Recruiter</p>
                  <p className={styles["right"]}>
                    {job.recruiters.map((recruiter, index) => (
                      <span key={index}>{recruiter.companyName}</span>
                    ))}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>City & Country</p>
                  <p className={styles["right"]}>
                    {job.city}, {job.country}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className={styles["left"]}>Customer</p>
                  <p className={styles["right"]}>{job.employer?.companyName}</p>
                </div>
              </Card>
            ))}
          </div>
        )}
        <div>
          <Paginate
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            total={totalJobs}
          />
        </div>
      </div>
    </div>
  );
}

export default Jobs;
