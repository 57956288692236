import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import styles from "../styles/createUser.module.css";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineCloudDownload, AiOutlineEye } from "react-icons/ai";
import { BsFileEarmarkCheck, BsFillFileCheckFill } from "react-icons/bs";

function Application(props) {
  const forDate = (data) => {
    let datefornow = new Date(data).toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    console.log(datefornow);
    return datefornow;
  };

  return (
    <div className="d-inline">
      <Popup
        trigger={
          <button className={styles.eye_button}>
            <AiOutlineEye />
          </button>
        }
        modal
        nested
      >
        {(close) => (
          <div className={styles["modal"]}>
            {/* comment this line because not in the design */}
            {/* <div className={styles["header"]}> Job Application </div> */}
            <div className={styles["popup-content"]}>
              <div className={styles.popup_main_container}>
                <div className={styles.popup_heading}>
                  Candidate Description
                </div>
                <div className={styles.popup_box}>
                  <div className={styles.popup_first_container}>
                    <div className={styles.popup_name}>Full Name</div>
                    {/* <div className={styles.popup_text_answer}>Rahul Gupta</div> */}
                    <div className={styles.popup_text_answer}>
                      {props.candidates.name}
                    </div>
                  </div>
                  <div className={styles.popup_sec_container}>
                    <div className={styles.popup_name}>Email Address</div>
                    <div className={styles.popup_text_email}>
                      {props.candidates.email}
                    </div>
                  </div>
                  <div className={styles.popup_third_container}>
                    <div className={styles.popup_name}>Phone No.</div>
                    <div className={styles.popup_text_answer}>
                      {props.candidates.mobile}
                    </div>
                  </div>
                </div>
                <div className={styles.popup_box}>
                  <div className={styles.popup_first_container}>
                    <div className={styles.popup_name}>Country*</div>
                    <div className={styles.popup_text_answer}>
                      {props.candidates.country}
                    </div>
                  </div>
                  <div className={styles.popup_sec_container}>
                    <div className={styles.popup_name}>State*</div>
                    <div className={styles.popup_text_answer}>
                      {props.candidates.state}
                    </div>
                  </div>
                  <div className={styles.popup_third_container}>
                    <div className={styles.popup_name}>City*</div>
                    <div className={styles.popup_text_answer}>
                      {props.candidates.city}
                    </div>
                  </div>
                </div>
                <div className={styles.popup_box}>
                  <div className={styles.popup_first_container}>
                    <div className={styles.popup_name}>Experience</div>
                    <div className={styles.popup_text_answer}>
                      {props.candidates.experience}
                    </div>
                  </div>
                  <div className={styles.popup_sec_container}>
                    <div className={styles.popup_name}>Skills</div>
                    <div className={styles.popup_text_answer}>
                      {props.candidates.skills.map((skill) => (
                        <span
                          style={{
                            backgroundColor: "silver",
                            margin: "0 5px",
                            padding: "1px 5px",
                            borderRadius: "5px",
                          }}
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className={styles.popup_third_container}>
                    <div className={styles.popup_name}>Resume</div>
                    <div className={styles.popup_text_answer}>
                      <a href={props.candidates.resume}>
                        <BsFileEarmarkCheck />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.popup_main_container}>
                <div className={styles.popup_heading}>Looking for Position</div>
                <div className={styles.popup_box}>
                  <div className={styles.popup_first_container}>
                    <div className={styles.popup_name}>City</div>
                    <div className={styles.popup_text_answer}>
                      {props.candidates.desiredJobcity}
                    </div>
                  </div>
                  <div className={styles.popup_sec_container}>
                    <div className={styles.popup_name}>
                      Compensation Expected
                    </div>
                    <div className={styles.popup_text_answer}>
                      {props.candidates.desiredJobexpectedCTC}
                    </div>
                  </div>
                  <div className={styles.popup_third_container}>
                    <div className={styles.popup_name}>Job Type</div>
                    <div className={styles.popup_text_answer}>
                      {props.candidates.desiredJobtype}
                    </div>
                  </div>
                </div>
                <div className={styles.popup_box}>
                  <div className={styles.popup_first_container}>
                    <div className={styles.popup_name}>Job Title</div>
                    <div className={styles.popup_text_answer}>
                      {props.candidates.desiredJobtitle}
                    </div>
                  </div>
                  <div className={styles.popup_sec_container}>
                    <div className={styles.popup_name}>Sector</div>
                    <div className={styles.popup_text_answer}>
                      {props.candidates.desiredJobsector}
                    </div>
                  </div>
                </div>
              </div>
              {props.candidates.workExperience.length == 0 ? (
                <>
                  <div className={styles.popup_heading}>Work Experience</div>
                  <p
                    style={{
                      marginTop: "20px",
                      fontSize: "16px",
                      color: "red",
                    }}
                  >
                    No Experience Found
                  </p>
                </>
              ) : (
                <div className={styles.popup_main_container}>
                  <div className={styles.popup_heading}>Work Experience</div>
                  <div className={styles.popup_box}>
                    <div className={styles.popup_first_container}>
                      <div className={styles.popup_name}>Company</div>
                      <div className={styles.popup_text_answer}>
                        {props.job.companyName}
                      </div>
                    </div>
                    <div className={styles.popup_sec_container}>
                      <div className={styles.popup_name}>Start Date</div>
                      <div className={styles.popup_text_answer}>
                        {forDate(props.candidates?.workExperience[0].startDate)}
                      </div>
                    </div>
                    <div className={styles.popup_third_container}>
                      <div className={styles.popup_name}>End Date</div>
                      <div className={styles.popup_text_answer}>
                        {props.candidates?.workExperience[0].present
                          ? forDate(new Date())
                          : forDate(
                              props.candidates?.workExperience[0].startDate
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default Application;
